<template>
  <dashboard content>
    <v-row slot="content">
      <v-col cols="12">
        <h1>Configurations</h1>
        <configurations></configurations> </v-col
    ></v-row>
  </dashboard>
</template>

<script>
import configurations from "@/domains/user/configurations";
import dashboard from "@/layouts/dashboard";

export default {
  components: {
    dashboard,
    configurations,
  },
  data() {
    return {
      pageTitle: false,
      content: true,
    };
  },
};
</script>

<style></style>
