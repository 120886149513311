var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-speed-dial",
        {
          staticClass: "rightFixed radio20",
          attrs: {
            bottom: "bottom",
            left: "",
            direction: "bottom",
            transition: "slide-x-reverse-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "grey lighten-2",
                        dark: "",
                        fab: "",
                        elevation: "5",
                        "data-google": "userLoginBtn",
                        "aria-label": "userLoginBtn",
                      },
                      model: {
                        value: _vm.showBox,
                        callback: function ($$v) {
                          _vm.showBox = $$v
                        },
                        expression: "showBox",
                      },
                    },
                    [
                      _vm.showBox
                        ? _c(
                            "v-icon",
                            { attrs: { color: "deep-purple darken-4" } },
                            [_vm._v("mdi-close")]
                          )
                        : !_vm.showBox && !_vm.userLogged
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "deep-purple darken-4",
                                large: "",
                              },
                            },
                            [_vm._v("mdi-account-circle")]
                          )
                        : _vm._e(),
                      !_vm.showBox && _vm.userLogged
                        ? _c("img", {
                            staticClass: "user_img",
                            attrs: {
                              src: _vm.getUserInfo.photoUrl
                                ? _vm.getUserInfo.photoUrl
                                : "",
                              alt: "userImages",
                            },
                          })
                        : _vm._e(),
                      !_vm.showBox && _vm.userLogged
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "platformIcon",
                              attrs: { color: _vm.platform.color },
                            },
                            [_vm._v("mdi-" + _vm._s(_vm.platform.icon))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showBox,
            callback: function ($$v) {
              _vm.showBox = $$v
            },
            expression: "showBox",
          },
        },
        [
          !_vm.userLogged
            ? _c(
                "v-card",
                { staticClass: "login_box" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "px-7 mt-7 no_pm login_header" },
                    [
                      _c("h2", { staticClass: "h2" }, [
                        _vm._v("Welcome to Zunzun"),
                      ]),
                    ]
                  ),
                  _c("v-card-text", { staticClass: "login_subheader px-7" }, [
                    _c("h4", [
                      _vm._v(
                        " Use your preferred music platform to create your own play list. "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "grey lighten-2 py-5 pr-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize radio20 green py-6",
                                  attrs: {
                                    block: "",
                                    elevation: "5",
                                    large: "",
                                    "data-google": "loginSpotifyBtn",
                                    "aria-label": "sing in spotify",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCodeSpotify()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-3 hand white",
                                      attrs: { medium: "", color: "green" },
                                    },
                                    [_vm._v(" mdi-spotify")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "loginText white--text" },
                                    [_vm._v(" Sign in with Spotify")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize radio20 white py-6",
                                  attrs: {
                                    block: "",
                                    elevation: "5",
                                    large: "",
                                    "data-google": "loginYoutubeBtn",
                                    "aria-label": "sing in youtube",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCodeYoutube()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "hand",
                                    attrs: {
                                      src: "img/googleLogo.png",
                                      alt: "googleLogo",
                                      width: "32",
                                      height: "auto",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "ml-3 loginText" },
                                    [_vm._v(" Sign in with Google")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "p",
                              { staticClass: "caption" },
                              [
                                _vm._v(
                                  " You can read about our cookies and privacy police "
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/policy",
                                      "data-google": "privacyPolice",
                                    },
                                  },
                                  [_c("span", [_vm._v("here")])]
                                ),
                                _vm._v(". "),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticClass: "login_box_internal grey lighten-3 radio20",
                  attrs: { elevation: "5" },
                },
                [
                  _vm.getUserInfo.displayName
                    ? _c("v-card-text", { staticClass: "ma-0 pa-0" }, [
                        _c("img", {
                          staticClass: "user_img_internal radio20",
                          attrs: {
                            src: _vm.getUserInfo.photoUrl
                              ? _vm.getUserInfo.photoUrl
                              : "",
                            alt: "userImages",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c(
                    "v-card-text",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "headerColor ml-0 pl-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getUserInfo.displayName) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "loggedText ml-0 pl-0" }, [
                              _vm._v(" Logged as "),
                              _c("b", [_vm._v(_vm._s(_vm.platform.text))]),
                              _vm._v(" user "),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "p",
                              { staticClass: "caption" },
                              [
                                _vm._v(
                                  " You can know more about our cookies and privacy police "
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/policy" } },
                                  [_c("span", [_vm._v("here")])]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-0 my-4 pa-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "deep-purple darken-4 ml-0 radio20",
                          attrs: {
                            elevation: "5",
                            dark: "",
                            block: "",
                            "aria-label": "logOut",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logOut()
                            },
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "mx-1" }, [
                            _vm._v("mdi-logout-variant "),
                          ]),
                          _vm._v("logout"),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }