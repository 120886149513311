<template>
  <v-card class="bg">
    <v-card-title class="subtitle-1 font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        elevation="0"
        aria-label="close"
        @click="closeSuggestedWindow"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="bg mx-0 px-0">
      <v-window v-model="step" class="px-4">
        <v-window-item :value="1">
          <v-row>
            <v-col cols="12" md="6">
              <div>
                <img
                  src="@/assets/images/suggest.jpg"
                  alt="suggest"
                  class="suggest"
                />
              </div>
            </v-col>

            <v-col cols="12 d-flex justify-center align-center" md="6">
              <div>
                <span class="grey--text text--darken-1">
                  <h1 class="my-4">Welcome to Zunzun.io</h1>
                  <p>
                    <b>Thank you</b> for help us to create a better application,
                    we will use your input to generate betters play list.
                  </p>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item
          v-for="(hit, key) in getSuggestionsSongs"
          :key="hit.id"
          :value="key + 2"
        >
          <v-row class="songsItems">
            <songSuggested :hit="hit"></songSuggested
          ></v-row>
        </v-window-item>

        <v-window-item :value="songsAmountLength + 2">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <img
                  src="@/assets/images/suggest.jpg"
                  alt="suggest"
                  class="suggest"
              /></v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex justify-center align-center"
              >
                <span class="grey--text text--darken-1">
                  <h1 class="my-4">Thanks for help us!</h1>
                  <p>
                    <b>Note: </b> You alway can update you profile using this
                    form in "Suggestions" pages. Now go and enjoy our
                    application.
                  </p>
                </span>
              </v-col>
            </v-row></v-card-text
          >
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="px-4">
      <v-btn
        :disabled="step === 1"
        text
        rounded
        aria-label="Back"
        @click="step--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <span v-if="!loading">{{ step }}/ {{ songsAmountLength + 2 }}</span>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-spacer></v-spacer>
      <v-btn
        v-show="!(step === songsAmountLength + 2)"
        :disabled="step === songsAmountLength + 2 || loading"
        color="primary"
        depressed
        rounded
        aria-label="Next"
        @click="step++"
      >
        Next
      </v-btn>
      <v-btn
        v-show="step === songsAmountLength + 2"
        color="primary"
        depressed
        aria-label="Send information"
        @click="sendSuggestion"
      >
        finalize
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import songSuggested from "./songSuggested";

export default {
  components: {
    songSuggested,
  },
  props: {
    generatePull: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    itemsAmount: 10,
    loading: false,
  }),
  computed: {
    ...mapGetters([
      "getSongsItems",
      "getEmotionsRegister",
      "getSample",
      "getSuggestionsSongs",
    ]),
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Suggestions";
        case this.songsAmountLength + 2:
          return "Thanks you for help us!";
        default:
          return "How this song make you feel?";
      }
    },

    songsAmountLength() {
      return this.getSuggestionsSongs.length;
    },
  },
  watch: {
    generatePull(actual) {
      if (actual) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchSuggestedSongs",
      "resetEmotionsRegister",
      "sendSuggestionsGenerated",
      "fetchAllTags",
      "fetchSamples",
      "fetchSamplesByTag",
      "fetchSuggestedPlayList",
    ]),
    async init() {
      this.loading = true;
      this.fetchAllTags();
      await this.fetchSamples(this.itemsAmount);
      await this.fetchSuggestedPlayList(this.getSample);
      this.loading = false;
    },

    closeSuggestedWindow() {
      this.step = 1;
      this.resetEmotionsRegister();
      this.$emit("close-suggested-window");
    },
    sendSuggestion() {
      this.sendSuggestionsGenerated(this.getEmotionsRegister);
      this.closeSuggestedWindow();
    },
  },
};
</script>
<style scoped>
.songsItems {
  padding: 15px;
  margin: 15px;
  box-shadow: 8px 8px 16px #dadada, -8px -8px 16px #cececeba;
  background: #f5f5f5;
  border-color: rgba(223, 225, 229, 0);
  border-radius: 30px;
  display: grid;
}
.bg {
  background: #eaeaea;
}
.suggest {
  border-radius: 20px;
  width: 100%;
  max-width: 350px;
  height: auto;
}
</style>
