<template>
  <div class="App">
    <router-view />
  </div>
</template>

<script>
import { LocalBehaviors } from "@/helpers";

export default {
  name: "App",
  components: {},
  mounted() {
    LocalBehaviors.createStore();
  },
  beforeDestroy() {},
};
</script>
