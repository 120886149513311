import localStorageHandler from "./localStorageHandler";
import flashApi from "./flashApi";

class LocalBehaviors {
  static createStore() {
    const initialValue = {
      artist: [],
      titles: [],
      genres: [],
      producer: [],
      importYoutube: [],
      importSpotify: [],
    };
    const localMemory = localStorageHandler.getJsonLocalStore("localMemory");
    if (localMemory && Object.keys(localMemory).length > 0) {
      const initial = { ...initialValue, ...localMemory };
      localStorageHandler.setJsonLocalStore("localMemory", initial);
    }
    localStorageHandler.createJsonLocalStore("localMemory", initialValue);
  }

  static CreateLocalMemory(response) {
    if (response.data) {
      const localMemory = localStorageHandler.getJsonLocalStore("localMemory");
      const { items } = response.data;
      /*  items.map((ele) => {
        this.insertProducer(localMemory, ele);
        this.insertTitles(localMemory, ele);
        this.insertArtist(localMemory, ele);
        return ele;
      }); */
      localStorageHandler.setJsonLocalStore("localMemory", localMemory);
      this.importElementsFlashAPI(items);
      this.mappingItems(response);
    }
    return response;
  }

  static mappingItems(response) {
    if (response.data.items.length > 0) {
      const items = [...response.data.items];
      const config = this.defaultSearchConfig(
        JSON.parse(response.config.data).search
      );
      const newResponse = [];
      items.map((ele) => {
        const item = {
          globalInfo: {},
          sourceInfo: {},
          artistImage: [],
          targetHitsRelated: [],
          target: config.target,
        };
        item.globalInfo = {
          duration: ele.duration,
          id: ele.id,
          title: ele.title,
          artist: ele.artist,
        };
        if (ele.artistImage) item.artistImage = [...ele.artistImage];
        item.sourceInfo = this.formatSource(config, ele);
        item.targetHitsRelated = this.formatTargets(ele);
        newResponse.push(item);
        return item;
      });
      response.data.items = [...newResponse];
    }

    return response;
  }

  static defaultSearchConfig(config) {
    if (!config) {
      return {
        target: "spotify",
        origin: "billboard",
      };
    }
    if (!config.target) {
      config.target = "spotify";
    }
    if (!config.origin) {
      config.origin = "billboard";
    }
    return config;
  }

  static formatSource(config, item) {
    const sourceItem = item.origin[config.origin];
    return {
      debutDate: sourceItem.debutDate || null,
      label: sourceItem.label || null,
      producer: sourceItem.labelPublishNumber || null,
      position: sourceItem.position || null,
    };
  }

  static formatTargets(item) {
    const targets = {};
    Object.entries(item.target).forEach((value) => {
      const platform = value[0];
      const platformHit = value[1][0];
      if (platform === "spotify") {
        const albumImgs = {};
        platformHit.albumImages.map((image) => {
          if (image.height >= 640) {
            albumImgs.high = image;
          } else if (image.height >= 300 && image.height < 640) {
            albumImgs.medium = image;
          } else {
            albumImgs.default = image;
          }
          return image;
        });
        const targetFormatted = {
          albumInfo: {
            albumApiLink: platformHit.albumApiLink || null,
            albumId: platformHit.albumId || null,
            albumName: platformHit.albumName || null,
            albumUriId: platformHit.albumUriId || null,
            albumWebLink: platformHit.albumWebLink || null,
            apiLink: platformHit.apiLink || null,
          },
          channelInfo: null,
          hitInfo: {
            duration: platformHit.duration || null,
            explicit: platformHit.explicit || null,
            publishTime: platformHit.publishTime || null,
            publishedAt: platformHit.publishedAt || null,
            spotifyUriId: platformHit.uriId || null,
            name: platformHit.name || null,
            previewUrl: platformHit.previewUrl || null,
            embedVideo: null,
          },
          thumbnails: albumImgs || null,
        };
        targets[platform] = { ...targetFormatted };
      }
      if (platform === "youtube") {
        const targetFormatted = {
          thumbnails: platformHit.thumbnails || null,
          albumInfo: null,
          channelInfo: {
            channelId: platformHit.channelId || null,
            channelTitle: platformHit.channelTitle || null,
            description: platformHit.description || null,
            kind: platformHit.kind || null,
            liveBroadcastContent: platformHit.liveBroadcastContent || null,
          },
          hitInfo: {
            publishTime: platformHit.publishTime || null,
            publishedAt: platformHit.publishedAt || null,
            duration: platformHit.duration || null,
            explicit: platformHit.explicit || null,
            name: platformHit.title || null,
            youtubeUriId: platformHit.videoId || null,
            previewUrl: platformHit.previewUrl || null,
            videoPreviewUrl:
              `https://www.youtube.com/watch?v=${platformHit.videoId}&feature=share` ||
              null,
            embedVideo:
              `https://www.youtube.com/embed/${platformHit.videoId}` || null,
          },
        };
        targets[platform] = { ...targetFormatted };
      }
    });
    return targets;
  }

  static insertArtist(localMemory, ele) {
    const resp = localMemory.artist.find((artist) => artist === ele.artist);
    if (!resp) {
      const item = {
        name: ele.artist,
        imageUrl: ele.artistImage[0].url || null,
      };
      localMemory.artist.push(item);
    }
    return localMemory;
  }

  static insertTitles(localMemory, ele) {
    const resp = localMemory.titles.find((title) => title === ele.title);
    if (!resp) {
      const item = {
        name: ele.title,
        image: ele.artistImage[0].url || null,
        artist: ele.artist,
      };
      localMemory.titles.push(item);
    }
    return localMemory;
  }

  static insertProducer(localMemory, ele) {
    const resp = localMemory.producer.find(
      (producer) => producer === ele.origin.billboard.label
    );
    if (!resp) {
      localMemory.producer.push(ele.origin.billboard.label);
    }
    return localMemory;
  }

  static insertGenres(localMemory, element) {
    const resp = localMemory.genres.find((genres) => genres === element.genres);
    if (!resp) {
      resp.map((ele) => localMemory.genres.push(ele));
    }
    return localMemory;
  }

  static async importElementsFlashAPI(items) {
    if (items.length > 0) {
      const islogued = localStorageHandler.getLocalStore("userLogged");
      items.forEach((ele) => {
        if (islogued && islogued !== "false") {
          flashApi.afterLogin();
          flashApi.createPlatformObjects(ele);
          flashApi.cleanLocalStore();
        } else {
          flashApi.createPlatformObjectsNoLogin(ele);
        }
      });
    }
  }
}

export default LocalBehaviors;
