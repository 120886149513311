import { render, staticRenderFns } from "./songSuggested.vue?vue&type=template&id=0cf4392e&scoped=true&"
import script from "./songSuggested.vue?vue&type=script&lang=js&"
export * from "./songSuggested.vue?vue&type=script&lang=js&"
import style0 from "./songSuggested.vue?vue&type=style&index=0&id=0cf4392e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf4392e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\Zunzun.io\\zunzunV2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cf4392e')) {
      api.createRecord('0cf4392e', component.options)
    } else {
      api.reload('0cf4392e', component.options)
    }
    module.hot.accept("./songSuggested.vue?vue&type=template&id=0cf4392e&scoped=true&", function () {
      api.rerender('0cf4392e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/suggestions/components/songSuggested.vue"
export default component.exports