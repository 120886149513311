var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.getRefreshLayout, staticClass: "pt-1" },
    [
      _vm.version === 1
        ? _c(
            "v-list",
            { key: _vm.getRefreshLayout, attrs: { rounded: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.selectors,
                    callback: function ($$v) {
                      _vm.selectors = $$v
                    },
                    expression: "selectors",
                  },
                },
                _vm._l(_vm.getSearchTopics, function (item, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _c("b", [_vm._v(_vm._s(item.name) + ":")]),
                            _vm._v(" " + _vm._s(item.value) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "close deep-purple darken-4",
                              attrs: { color: "red", "x-small": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.getSearchTopics.length > 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "showBubbles px-0 mx-0",
                          attrs: { cols: "12" },
                        },
                        _vm._l(_vm.getSearchTopics, function (item, key) {
                          return _c(
                            "v-chip",
                            {
                              key: key,
                              staticClass: "ma-1 white--text overFlowTopic",
                              attrs: {
                                elevation: "10",
                                close: "",
                                color: "#311b92",
                              },
                              on: {
                                "click:close": function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mx-2" }, [
                                _vm._v(_vm._s(item.icons)),
                              ]),
                              _c("i", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(item.name) + ":"),
                              ]),
                              !item.valuesArray || item.valuesArray.length <= 1
                                ? _c("span", { staticClass: "pr-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.type === "String"
                                            ? item.value.slice(0, 50)
                                            : item.value
                                        )
                                    ),
                                  ])
                                : _c(
                                    "span",
                                    { staticClass: "pr-3 my-1" },
                                    _vm._l(
                                      item.valuesArray,
                                      function (subItem, subKey) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: subKey,
                                            staticClass: "ma-1 white--text",
                                            attrs: {
                                              elevation: "10",
                                              close: "",
                                              color: "#563dc5bd",
                                            },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.deleteSubItems(
                                                  item.apiTarget,
                                                  subItem
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mr-3" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { size: "13" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-chevron-right-circle"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      subItem.slice(0, 50)
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "showExpander pa-0 ma-0",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              staticClass: "whiteText pa-0 ma-0",
                              attrs: { flat: "" },
                            },
                            _vm._l(1, function (item, i) {
                              return _c(
                                "v-expansion-panel",
                                {
                                  key: i,
                                  staticClass: "transparent ma-0 pa-0",
                                },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass: "ma-0 pa-0",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "actions",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        "deep-purple darken-4",
                                                    },
                                                  },
                                                  [_vm._v(" $expand ")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "expanderTitles" },
                                        [
                                          _c("b", [_vm._v("Search topics :")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getSearchTopics.length
                                              ) +
                                              " actives"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    { staticClass: "expand ma-0 pa-0" },
                                    _vm._l(
                                      _vm.getSearchTopics,
                                      function (topic, key) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: key,
                                            staticClass:
                                              "ma-1 white--text mobil-margin-left overFlowTopic",
                                            attrs: {
                                              elevation: "10",
                                              close: "",
                                              color: "#311b92",
                                            },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.deleteItem(topic)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mx-2" },
                                              [_vm._v(_vm._s(topic.icons))]
                                            ),
                                            _c("i", { staticClass: "mr-1" }, [
                                              _vm._v(_vm._s(topic.name) + ":"),
                                            ]),
                                            !topic.valuesArray ||
                                            topic.valuesArray.length <= 1
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pr-3" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          topic.type ===
                                                            "String"
                                                            ? topic.value.slice(
                                                                0,
                                                                50
                                                              )
                                                            : topic.value
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "pr-3 my-1" },
                                                  _vm._l(
                                                    topic.valuesArray,
                                                    function (subItem, subKey) {
                                                      return _c(
                                                        "v-chip",
                                                        {
                                                          key: subKey,
                                                          staticClass:
                                                            "ma-1 white--text",
                                                          attrs: {
                                                            elevation: "10",
                                                            close: "",
                                                            color: "#563dc5bd",
                                                          },
                                                          on: {
                                                            "click:close":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSubItems(
                                                                  topic.apiTarget,
                                                                  subItem
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mr-3",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    size: "13",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-chevron-right-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    subItem.slice(
                                                                      0,
                                                                      50
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }