var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "text-h5" }, [
        _vm._v(" User Profile "),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            { attrs: { vertical: "" } },
            [
              _c(
                "v-tab",
                { staticClass: "d-flex justify-left" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-account "),
                  ]),
                  _vm._v(" User Information "),
                ],
                1
              ),
              _c(
                "v-tab",
                { staticClass: "justify-left" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-account "),
                  ]),
                  _vm._v(" Play List "),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("v-card-text", [_c("p", [_vm._v("Some Info")])])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [_c("v-card", { attrs: { flat: "" } }, [_c("v-card-text")], 1)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "green darken-1",
                text: "",
                "aria-label": "Disagree",
              },
              on: {
                click: function ($event) {
                  _vm.dialog = false
                },
              },
            },
            [_vm._v(" Disagree ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "green darken-1",
                text: "",
                "aria-label": "Agree",
              },
              on: {
                click: function ($event) {
                  _vm.dialog = false
                },
              },
            },
            [_vm._v(" Agree ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }