var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.blockStyle }, [
    _c(
      "div",
      [
        _c(
          "v-chip",
          {
            staticClass: "ma-2",
            attrs: {
              close: "",
              "close-icon":
                _vm.select && _vm.editEmotionSelected
                  ? "mdi-comment-processing"
                  : "mdi-comment-edit",
              color: _vm.select ? _vm.activeColor : _vm.baseColor,
              "text-color": _vm.select
                ? _vm.activeColorTextColor
                : _vm.baseColorTextColor,
            },
            on: {
              click: _vm.editEmotion,
              "click:close": function ($event) {
                _vm.editEmotionSelected = true
              },
            },
          },
          [
            _c(
              "v-avatar",
              { attrs: { left: "" } },
              [
                _c("v-icon", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.select ? _vm.activeColorIcon : _vm.baseColorIcon
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "capitalize",
                class: _vm.select && !_vm.editEmotionSelected ? "mr-7" : "mr-0",
              },
              [
                _vm._v(" " + _vm._s(_vm.name) + " "),
                _vm.select && !_vm.editEmotionSelected
                  ? _c("v-badge", {
                      staticClass: "ml-1 mt-3",
                      attrs: {
                        bordered: "",
                        color: _vm.select ? _vm.activeColor : _vm.baseColor,
                        content: _vm.sliderValue,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editEmotionSelected,
            expression: "editEmotionSelected",
          },
        ],
        staticClass: "slider-container",
      },
      [
        _c(
          "div",
          { staticClass: "emotionName" },
          [
            _c(
              "v-icon",
              {
                staticClass: "close-btn",
                attrs: { color: _vm.activeColor },
                on: {
                  click: function ($event) {
                    _vm.editEmotionSelected = false
                  },
                },
              },
              [_vm._v("mdi-close-circle")]
            ),
            _c("span", { staticClass: "hidden-md-and-down capitalize" }, [
              _vm._v(_vm._s(_vm.name) + " :"),
            ]),
          ],
          1
        ),
        _c("v-slider", {
          attrs: {
            max: "10",
            min: "0",
            color: _vm.activeColor,
            "track-color": _vm.baseColor,
            "thumb-color": _vm.colors,
            "thumb-label": "always",
            "thumb-size": "24",
          },
          on: { change: _vm.updateValue },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function () {
                return [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: _vm.baseColor },
                      on: { click: _vm.decreaseValue },
                    },
                    [_vm._v(" mdi-minus ")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { color: _vm.baseColor },
                      on: { click: _vm.increaseValue },
                    },
                    [_vm._v(" mdi-plus ")]
                  ),
                  _c("v-spacer"),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.sliderValue,
            callback: function ($$v) {
              _vm.sliderValue = $$v
            },
            expression: "sliderValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }