var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "bg-nav crystalBackground px-3",
          attrs: {
            id: "navy",
            app: "",
            left: "",
            fixed: "",
            width: "300",
            temporary: _vm.temporary,
            permanent: _vm.permanent,
            "overlay-opacity": "0",
          },
          model: {
            value: _vm.drawerLeft,
            callback: function ($$v) {
              _vm.drawerLeft = $$v
            },
            expression: "drawerLeft",
          },
        },
        [
          _c("general-menu", {
            attrs: { "panel-status": _vm.permanent },
            on: { "update:permanent": _vm.updatePosition },
          }),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "play_list bg-nav crystalBackground",
          attrs: {
            app: "",
            fixed: "",
            right: "",
            temporary: !_vm.showPermanent,
            permanent: _vm.showPermanent,
            "overlay-opacity": "0",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2 pt-4 pb-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-0 pl-0 d-flex justify-center",
                  attrs: { cols: "2" },
                },
                [
                  _c("v-icon", { attrs: { "x-large": "", color: "primary" } }, [
                    _vm._v("mdi-checkbox-marked-circle-plus-outline"),
                  ]),
                ],
                1
              ),
              _c("v-col", { staticClass: "ml-0 pl-0", attrs: { cols: "7" } }, [
                _c("span", { staticClass: "title deepPurple" }, [
                  _vm._v(" Selected music"),
                ]),
                _c("br"),
                _c("span", { staticClass: "caption deepPurple" }, [
                  _vm._v("Export playlist to your preferred service"),
                ]),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "d-flex pt-5 justify-end",
                  attrs: { cols: "3" },
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "btn-purple fixIcon mx-1 hidden",
                                      attrs: {
                                        icon: "",
                                        tile: "",
                                        elevation: "2",
                                        "aria-label":
                                          "Change playlist visible status",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "white", e: "22" },
                                      on: {
                                        click: function ($event) {
                                          ;(_vm.showPermanent =
                                            !_vm.showPermanent),
                                            _vm.updateMenuStore()
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-view-dashboard-outline ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.playListFixedStatus))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-1 pb-4" },
            [
              _c(
                "v-col",
                { staticClass: "px-8 mb-2", attrs: { cols: "12" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [_c("playListCreator")],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.playlistHaveItems
                              ? "Create Playlist from selected music"
                              : "At least one item is needed"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 pt-0" },
            [
              _c(
                "v-col",
                { staticClass: "ma-1 pa-1", attrs: { cols: "12" } },
                [_vm._t("play_list")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "content" },
        [
          _c(
            "v-row",
            {
              staticClass: "topBarLocal crystalBackground",
              class: _vm.showPermanent ? "barPermanent" : "",
            },
            [
              !_vm.permanent
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "hidden-xs-only d-flex pl-5 bg-transparent-sm-only",
                      attrs: { cols: "12", sm: "2", xs: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            icon: "",
                            text: "",
                            "aria-label": "Toggle left drawer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updatePosition()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "deep-purple darken-4" } },
                            [_vm._v("mdi-menu")]
                          ),
                        ],
                        1
                      ),
                      _c("h1", { staticClass: "logo-text-color ml-4" }, [
                        _c("a", { attrs: { href: "/" } }, [_vm._v("Zunzun")]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "d-5",
                  attrs: {
                    cols: "12",
                    md: _vm.permanent ? "10" : "8",
                    xs: "12",
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "mobile-centered-container" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-flex justify-space-between ma-0 pa-0",
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "top-bar-centered-items",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("billboardSearch", {
                                staticStyle: { width: "inherit" },
                                attrs: {
                                  layout: "searchLayout",
                                  "re-fetch-hits": _vm.reFetchHits,
                                },
                                on: {
                                  "restore-pagination": function ($event) {
                                    _vm.page = 1
                                  },
                                  "show-advancer-search":
                                    _vm.showAdvancerSearch,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "top-bar-centered-right-items px-5 hidden-xs-only",
                  attrs: { cols: "3", sm: "2", xs: "0" },
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mx-1",
                                      attrs: {
                                        "data-google": "showPlayListBarBtn",
                                        icon: "",
                                        text: "",
                                        "aria-label": "Show playlist bar",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showPlayListDrawer()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.getPlayList.length > 0,
                                          expression: "getPlayList.length > 0",
                                        },
                                      ],
                                      class:
                                        _vm.getPlayList.length > 0 ? "" : "",
                                      attrs: {
                                        bordered: "",
                                        color: _vm.getColor(_vm.getPlayList),
                                        overlap: "",
                                        left: "",
                                        bottom: "",
                                        content:
                                          _vm.getPlayList.length > 99
                                            ? "+ 99"
                                            : _vm.getPlayList.length,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mx-2",
                                          attrs: {
                                            color: "deep-purple darken-4",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "mdi-format-list-bulleted-square"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.getPlayList.length <= 0,
                                          expression: "getPlayList.length <= 0",
                                        },
                                      ],
                                      staticClass: "mx-2",
                                      attrs: {
                                        color: "deep-purple darken-4",
                                        "aria-label":
                                          "Hits in Playlist counter",
                                      },
                                    },
                                    [_vm._v("mdi-format-list-bulleted-square")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Show PlayList")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mx-1 hidden",
                                      attrs: {
                                        icon: "",
                                        text: "",
                                        "aria-label": "Settings",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goTo("Configurations")
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "deep-purple darken-4" },
                                    },
                                    [_vm._v("mdi-cog-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Settings & Configurations")])]
                  ),
                  _c("login"),
                ],
                1
              ),
            ],
            1
          ),
          _vm.search || _vm.content
            ? _c(
                "v-row",
                {
                  staticClass: "topBarLocalContent",
                  class: _vm.showPermanent ? "barPermanent" : "",
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-center- d-5 mb-15 small-padding",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm.search
                        ? _c(
                            "v-container",
                            {
                              staticClass: "my-5 mobile-centered-container",
                              attrs: { "small-padding": "" },
                            },
                            [
                              _vm.browser
                                ? _c(
                                    "v-row",
                                    { staticClass: "filtersLayout" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0",
                                          attrs: { cols: "12" },
                                        },
                                        [_vm._t("browser")],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchLength && !_vm.getLoading
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-8",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-sheet",
                                            {
                                              staticClass: "round",
                                              attrs: { "min-height": "70vh" },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "grey lighten-5 px-6",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "mr-2 onlyDesktop",
                                                          attrs: {
                                                            size: 32,
                                                            color:
                                                              "deep-purple darken-4 ",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-view-list "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "deepPurple searchResult",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Search Result "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "hidden",
                                                                            attrs:
                                                                              {
                                                                                icon: "",
                                                                                "data-google":
                                                                                  "changeHitsLayoutBtn",
                                                                                "aria-label":
                                                                                  "change hits layout",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setLayoutDisplay(
                                                                                    !_vm.getLayoutDisplay
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: "24",
                                                                                color:
                                                                                  "deep-purple darken-4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.getLayoutDisplay
                                                                                  ? "mdi-collage"
                                                                                  : "mdi-id-card"
                                                                              ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            3024373787
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLayoutDisplay
                                                                  ? "Show as cards"
                                                                  : "Show as bubbles"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "pa-5",
                                                                            attrs:
                                                                              {
                                                                                icon: "",
                                                                                elevation:
                                                                                  "0",
                                                                                "data-google":
                                                                                  "addMultiplesHitToPlayListBtn",
                                                                                "aria-label":
                                                                                  "Add/Remove multiples hits to playlist",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.updateState()
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: "24",
                                                                                color:
                                                                                  "deep-purple darken-4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.hasBeenIncludedInPlaylist
                                                                                  ? "mdi-playlist-remove "
                                                                                  : "mdi-playlist-plus"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1743872357
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.hasBeenIncludedInPlaylist
                                                                  ? "Delete all to the playlist"
                                                                  : "Add all hits to playlist"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                          _c("b", [
                                                            _vm._v(
                                                              " - Page #" +
                                                                _vm._s(
                                                                  _vm.getActualPage
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "smallDV mr-3",
                                                                            attrs:
                                                                              {
                                                                                "aria-label":
                                                                                  "Playlist counter hits",
                                                                                icon: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showPlayListDrawer()
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-badge",
                                                                          {
                                                                            class:
                                                                              _vm
                                                                                .getPlayList
                                                                                .length >
                                                                              0
                                                                                ? ""
                                                                                : "",
                                                                            attrs:
                                                                              {
                                                                                bordered:
                                                                                  "",
                                                                                color:
                                                                                  _vm.getColor(
                                                                                    _vm.getPlayList
                                                                                  ),
                                                                                content:
                                                                                  _vm
                                                                                    .getPlayList
                                                                                    .length >
                                                                                  99
                                                                                    ? "+ 99"
                                                                                    : _vm
                                                                                        .getPlayList
                                                                                        .length,
                                                                                overlap:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                attrs:
                                                                                  {
                                                                                    size: "24",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-format-list-bulleted-square"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            4233086170
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Show PlayList"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "py-8 px-6",
                                                    },
                                                    [_vm._t("main")],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.searchLength && !_vm.getLoading
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-8",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-sheet",
                                            {
                                              staticClass: "round",
                                              attrs: { "min-height": "70vh" },
                                            },
                                            [
                                              _vm.searchStatus
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass:
                                                        "ma-4 roundAlert",
                                                      attrs: {
                                                        type: "info",
                                                        color:
                                                          "deep-purple darken-4",
                                                        dismissible: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Data is not found! Unfortunately at the moment we don't have that information. But, in the meantime you can take a look at our most popular searches. "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "px-6 grey lighten-5",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-4",
                                                          attrs: {
                                                            size: 32,
                                                            color: "#311b92",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-view-list "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "deepPurple",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Popular Search"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-spacer"),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "py-8 px-6",
                                                    },
                                                    [_vm._t("noData")],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.getLoading
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-8",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-sheet",
                                            {
                                              staticClass: "round",
                                              attrs: { "min-height": "70vh" },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "grey lighten-5 py-5 px-6",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "mr-2 onlyDesktop",
                                                          attrs: {
                                                            size: 32,
                                                            color:
                                                              "deep-purple darken-4 ",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-view-list "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "deepPurple searchResult",
                                                        },
                                                        [_vm._v("Searching...")]
                                                      ),
                                                      _c("v-spacer"),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._l(
                                                    _vm.getItemsPerPage,
                                                    function (items, key) {
                                                      return _c(
                                                        "v-card-text",
                                                        {
                                                          key: key,
                                                          staticClass:
                                                            "py-8 px-6",
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "grey lighten-5 ma-4 pa-3 round",
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-skeleton-loader",
                                                                    {
                                                                      attrs: {
                                                                        type: "list-item-avatar-three-line",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-skeleton-loader",
                                                                    {
                                                                      attrs: {
                                                                        type: "list-item-avatar-three-line",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-skeleton-loader",
                                                                    {
                                                                      attrs: {
                                                                        type: "list-item-avatar-three-line",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-skeleton-loader",
                                                                    {
                                                                      attrs: {
                                                                        type: "actions",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-row", [_vm._t("extras")], 2),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.content
                        ? _c(
                            "v-container",
                            { staticClass: "my-5 mobile-centered-container" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-8", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass: "round",
                                      attrs: { "min-height": "70vh" },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "grey lighten-5 px-6",
                                            },
                                            [_vm._t("content-title")],
                                            2
                                          ),
                                          _c(
                                            "v-card-text",
                                            [_vm._t("content")],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.home ? _c("section", [_vm._t("home")], 2) : _vm._e(),
        ],
        1
      ),
      _c("suggestions"),
      _c("floating-login", { staticClass: "hidden-sm-and-up" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1250", persistent: "" },
          model: {
            value: _vm.showSearch,
            callback: function ($$v) {
              _vm.showSearch = $$v
            },
            expression: "showSearch",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex py-3 mr-0 pr-0" },
                [
                  _c(
                    "span",
                    { staticClass: "deepPurple" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "primary" } },
                        [_vm._v("mdi-filter-variant-plus")]
                      ),
                      _vm._v(" Advanced Search"),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-3 mr-3",
                      attrs: {
                        color: "deep-purple darken-4",
                        rounded: "",
                        elevation: "0",
                        icon: "",
                        plain: "",
                        raised: "",
                        small: "",
                        "aria-label": "Show advanced Search",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showSearch = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "my-0 py-0" },
                [
                  _vm.showSearch
                    ? _c("filters", {
                        on: {
                          "fetch-hits": _vm.hideAdvancerSearch,
                          close: _vm.hideAdvancerSearch,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }