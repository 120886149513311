var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("v-subheader", { staticClass: "black--text" }, [
        _c(
          "span",
          { staticClass: "primaryText subtitle-1" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-1", attrs: { color: "primary", medium: "" } },
              [_vm._v("mdi-arrow-decision-auto")]
            ),
            _vm._v(" Suggestions:"),
          ],
          1
        ),
      ]),
      _c("v-divider", {
        staticClass: "mb-2 primary",
        staticStyle: { opacity: "0.22" },
      }),
      _c("div", { staticClass: "suggestionsElements" }, [
        !_vm.searchTopic
          ? _c(
              "div",
              { staticClass: "pa-4" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "primary" } },
                  [_vm._v("mdi-information-outline ")]
                ),
                _c("span", [
                  _vm._v(
                    "You should write any search topic before we can help you !"
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        !_vm.hasAnyResult
          ? _c("div", { staticClass: "pb-4" }, [
              _vm.showArtists
                ? _c(
                    "div",
                    [
                      _c(
                        "v-subheader",
                        { staticClass: "subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "primary", small: "" },
                            },
                            [_vm._v("mdi-account-music")]
                          ),
                          _vm._v("Artists: "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mx-8" },
                        _vm._l(_vm.artists, function (artist, key) {
                          return _c(
                            "v-chip",
                            {
                              key: key,
                              staticClass: "ma-1 white--text suggestionsTopic",
                              attrs: { elevation: "10", color: "#311b92" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateTopics(
                                    "artist",
                                    artist.name,
                                    artist.imageUrl
                                  )
                                },
                              },
                            },
                            [
                              _c("v-avatar", { staticClass: "mx-2" }, [
                                _c("img", {
                                  attrs: {
                                    src: artist.imageUrl,
                                    alt: artist.name,
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(artist.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showTitles
                ? _c(
                    "div",
                    [
                      _c(
                        "v-subheader",
                        { staticClass: "subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "primary", small: "" },
                            },
                            [_vm._v("mdi-format-title")]
                          ),
                          _vm._v("Song Titles"),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mx-8" },
                        _vm._l(_vm.titles, function (title, key) {
                          return _c(
                            "v-chip",
                            {
                              key: key,
                              staticClass: "ma-1 white--text suggestionsTopic",
                              attrs: { elevation: "10", color: "#311b92" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateTopics(
                                    "title",
                                    title.title,
                                    title.imageUrl
                                  )
                                },
                              },
                            },
                            [
                              _c("v-avatar", { staticClass: "mx-2" }, [
                                _c("img", {
                                  attrs: {
                                    src: title.imageUrl,
                                    alt: title.title,
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(title.title)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.hasAnyResult
          ? _c(
              "div",
              { staticClass: "pa-4" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "primary" } },
                  [_vm._v("mdi-information-outline ")]
                ),
                _c("span", [
                  _vm._v(
                    " We can't find any match for this topic. Please, use the regular or advanced search to find what you are looking for. "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }