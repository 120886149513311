var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      staticClass: "radio20 primary ma-0 pa-0",
      attrs: {
        "close-on-content-click": false,
        "nudge-width": 200,
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mx-1 grey lighten-3",
                      attrs: {
                        icon: "",
                        fab: "",
                        small: "",
                        "data-google": "userLoginBtn",
                        "aria-label": "Login",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm.showBox
                    ? _c(
                        "v-icon",
                        { attrs: { color: "deep-purple darken-4", small: "" } },
                        [_vm._v("mdi-close")]
                      )
                    : !_vm.showBox && !_vm.userLogged
                    ? _c(
                        "v-icon",
                        { attrs: { color: "deep-purple darken-4", large: "" } },
                        [_vm._v("mdi-account-circle")]
                      )
                    : _vm._e(),
                  !_vm.showBox && _vm.userLogged
                    ? _c("v-img", {
                        staticClass: "user_img",
                        attrs: {
                          src: _vm.getUserInfo.photoUrl
                            ? _vm.getUserInfo.photoUrl
                            : "@/assets/images/micro.webp",
                          "lazy-src": require("@/assets/images/micro.webp"),
                          alt: "userImages",
                        },
                      })
                    : _vm._e(),
                  !_vm.showBox && _vm.userLogged
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "platformIcon",
                          attrs: { color: _vm.platform.color, small: "" },
                        },
                        [_vm._v("mdi-" + _vm._s(_vm.platform.icon))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showBox,
        callback: function ($$v) {
          _vm.showBox = $$v
        },
        expression: "showBox",
      },
    },
    [
      !_vm.userLogged
        ? _c(
            "v-card",
            {
              staticClass: "px-0",
              on: {
                mouseleave: function ($event) {
                  _vm.showBox = false
                },
              },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "px-7 pt-4 no_pm login_header" },
                [_c("h3", { staticClass: "h2" }, [_vm._v("Welcome to Zunzun")])]
              ),
              _c("v-card-text", { staticClass: "login_subheader px-7" }, [
                _c("h4", [
                  _vm._v(
                    "Use your preferred music platform to create your own play list."
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "grey lighten-4 pa-7 login-btn-container" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize radio20 green py-6 my-2",
                      attrs: {
                        block: "",
                        large: "",
                        "data-google": "loginSpotifyBtn",
                        "aria-label": "Sing in with Spotify",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getCodeSpotify()
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-3 hand white",
                          attrs: { medium: "", color: "green" },
                        },
                        [_vm._v(" mdi-spotify")]
                      ),
                      _c("span", { staticClass: "loginText white--text" }, [
                        _vm._v(" Sign in with Spotify"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize radio20 white py-6 my-2",
                      attrs: {
                        block: "",
                        large: "",
                        "data-google": "loginYoutubeBtn",
                        "aria-label": "Sing in with Youtube",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getCodeYoutube()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "hand",
                        attrs: {
                          src: require("@/assets/images/googleLogo.png"),
                          width: "32",
                          height: "auto",
                          alt: "googleLogo",
                        },
                      }),
                      _c("span", { staticClass: "ml-3 loginText" }, [
                        _vm._v(" Sign in with Google"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize radio20 white my-2",
                      attrs: {
                        block: "",
                        large: "",
                        disabled: "",
                        "data-google": "loginAppleBtn",
                        "aria-label": "Sing in with Apple",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-3 loginText white--text",
                          attrs: { medium: "", color: "red" },
                        },
                        [_vm._v(" mdi-music")]
                      ),
                      _vm._v(" Sign in with Apple "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "px-7" },
                [
                  _c(
                    "v-row",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "caption" },
                          [
                            _vm._v(
                              " You can read about our cookies and privacy police "
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/policy",
                                  "data-google": "privacyPolice",
                                },
                              },
                              [_c("span", [_vm._v("here")])]
                            ),
                            _vm._v(". "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "login_box_internal grey lighten-3",
              on: {
                mouseleave: function ($event) {
                  _vm.showBox = false
                },
              },
            },
            [
              _vm.getUserInfo.displayName
                ? _c(
                    "v-card-text",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c("v-img", {
                        staticClass: "user_img_internal radio20",
                        attrs: {
                          src: _vm.getUserInfo.photoUrl
                            ? _vm.getUserInfo.photoUrl
                            : "@/assets/images/micro.webp",
                          "lazy-src": require("@/assets/images/micro.webp"),
                          alt: "userImages",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "headerColor ml-0 pl-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.getUserInfo.displayName) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "loggedText ml-0 pl-0" }, [
                          _vm._v(" Logged as "),
                          _c("b", [_vm._v(_vm._s(_vm.platform.text))]),
                          _vm._v(" user "),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "caption" },
                          [
                            _vm._v(
                              " You can know more about our cookies and privacy police "
                            ),
                            _c("router-link", { attrs: { to: "/policy" } }, [
                              _c("span", [_vm._v("here")]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mx-0 my-4 pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "deep-purple darken-4 ml-0 radio20",
                      attrs: {
                        elevation: "5",
                        dark: "",
                        block: "",
                        "aria-label": "Logout",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.logOut()
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "mx-1" }, [
                        _vm._v("mdi-logout-variant "),
                      ]),
                      _vm._v("logout"),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }