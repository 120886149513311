<template>
  <div class="content">
    <h2>404</h2>
    <h3>Opps! Page not found.</h3>
    <p>
      The pages you were loocking for doesn't exit. <br />
      You may have mistyped the address or the page may have moved.
    </p>
    <a> back to <b>Home</b></a>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
