<template>
  <div :key="getRefreshLayout" class="pt-1">
    <v-list v-if="version === 1" :key="getRefreshLayout" rounded>
      <v-list-item-group v-model="selectors" color="primary">
        <v-list-item v-for="(item, i) in getSearchTopics" :key="i">
          <v-list-item-content>
            <v-list-item-title
              ><b>{{ item.name }}:</b> {{ item.value }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              class="close deep-purple darken-4"
              color="red"
              x-small
              @click="deleteItem(item)"
            >
              mdi-close
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else>
      <v-row v-if="getSearchTopics.length > 0">
        <v-col class="showBubbles px-0 mx-0" cols="12">
          <v-chip
            v-for="(item, key) in getSearchTopics"
            :key="key"
            elevation="10"
            close
            color="#311b92"
            class="ma-1 white--text overFlowTopic"
            @click:close="deleteItem(item)"
          >
            <v-icon class="mx-2">{{ item.icons }}</v-icon>
            <i class="mr-1">{{ item.name }}:</i>
            <span
              v-if="!item.valuesArray || item.valuesArray.length <= 1"
              class="pr-3"
            >
              {{
                item.type === "String" ? item.value.slice(0, 50) : item.value
              }}</span
            >
            <span v-else class="pr-3 my-1">
              <v-chip
                v-for="(subItem, subKey) in item.valuesArray"
                :key="subKey"
                elevation="10"
                close
                color="#563dc5bd"
                class="ma-1 white--text"
                @click:close="deleteSubItems(item.apiTarget, subItem)"
              >
                <span class="mr-3">
                  <v-icon class="mr-1" size="13"
                    >mdi-chevron-right-circle</v-icon
                  >
                  {{ subItem.slice(0, 50) }}
                </span>
              </v-chip>
            </span>
          </v-chip>
        </v-col>
        <v-col cols="12" class="showExpander pa-0 ma-0">
          <v-expansion-panels flat class="whiteText pa-0 ma-0">
            <v-expansion-panel
              v-for="(item, i) in 1"
              :key="i"
              class="transparent ma-0 pa-0"
            >
              <v-expansion-panel-header class="ma-0 pa-0">
                <span class="expanderTitles"
                  ><b>Search topics :</b>
                  {{ getSearchTopics.length }} actives</span
                >
                <template #actions>
                  <v-icon color="deep-purple darken-4"> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="expand ma-0 pa-0">
                <v-chip
                  v-for="(topic, key) in getSearchTopics"
                  :key="key"
                  elevation="10"
                  close
                  color="#311b92"
                  class="ma-1 white--text mobil-margin-left overFlowTopic"
                  @click:close="deleteItem(topic)"
                >
                  <v-icon class="mx-2">{{ topic.icons }}</v-icon>
                  <i class="mr-1">{{ topic.name }}:</i>
                  <span
                    v-if="!topic.valuesArray || topic.valuesArray.length <= 1"
                    class="pr-3"
                  >
                    {{
                      topic.type === "String"
                        ? topic.value.slice(0, 50)
                        : topic.value
                    }}</span
                  >
                  <span v-else class="pr-3 my-1">
                    <v-chip
                      v-for="(subItem, subKey) in topic.valuesArray"
                      :key="subKey"
                      elevation="10"
                      close
                      color="#563dc5bd"
                      class="ma-1 white--text"
                      @click:close="deleteSubItems(topic.apiTarget, subItem)"
                    >
                      <span class="mr-3">
                        <v-icon class="mr-1" size="13"
                          >mdi-chevron-right-circle</v-icon
                        >
                        {{ subItem.slice(0, 50) }}
                      </span>
                    </v-chip>
                  </span>
                </v-chip>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "BillboardSearchTopics",
  props: {
    version: { type: String, default: "2" },
  },
  data() {
    return {
      selectors: null,
    };
  },
  computed: {
    ...mapGetters(["getSearchTopics", "getRefreshLayout", "getBillboardItems"]),
  },
  methods: {
    ...mapActions(["postBillboardForItems"]),
    ...mapMutations(["setSearchTopics", "setRefreshLayout"]),
    deleteItem(element) {
      const newTopicList = [];
      this.getSearchTopics.forEach((ele) => {
        this.changeUrl(ele.apiTarget.replaceAll(/\s/g, "_"));
        if (ele.apiTarget !== element.apiTarget) {
          newTopicList.push(ele);
        }
      });
      this.setSearchTopics(newTopicList);
      this.setRefreshLayout();
      this.$emit("re-search");
    },
    deleteSubItems(apiTarget, key) {
      this.getSearchTopics = this.getSearchTopics.map((ele) => {
        if (ele.apiTarget === apiTarget) {
          const newValuesArray = ele.valuesArray.filter(
            (eleKey) => eleKey !== key
          );
          /* ele.valuesArray = newValuesArray;
          ele.value = newValuesArray.join(","); */
          return {
            ...ele,
            valuesArray: newValuesArray,
            value: newValuesArray.join(","),
          };
        }
        return ele;
      });
      this.setSearchTopics(this.getSearchTopics);
      this.setRefreshLayout();
      this.$emit("re-search");
    },
    changeUrl(searchItem) {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      params.delete(searchItem);
      url.search = params.toString();
      const newURL = url.toString();
      window.history.pushState({ path: newURL }, "", url);
    },
  },
};
</script>

<style lang="scss" scoped>
.upEvery {
  z-index: 300;
}
.overFlowTopic {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.v-toolbar__extension {
  display: none !important;
}
.search_order {
  display: flex;
  .search_icon {
    position: absolute;
    margin: 7px;
    font-size: 25px;
    cursor: pointer;
  }
}
.search_parameters {
  display: block;
  position: fixed;
  text-align: left;
  background: #fff;
  margin: 0px 25px 0 25px;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
  width: 100%;
  z-index: 1000;
  p {
    padding: 20px 25px;
    margin: 0;
  }
  p:first-child {
    margin-top: 15px;
  }
  p:hover {
    background: #f3f3f3;
    border-radius: 0;
  }
  p:last-child {
    border-radius: 0 0 12px 12px;
  }

  @media only screen and (min-width: 650px) {
    max-width: 550px;
  }

  @media only screen and (max-width: 640px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 540px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 360px) {
    max-width: 202px;
  }
  @media only screen and (max-width: 360px) {
    max-width: 150px;
  }
}
.searchInput {
  border-radius: 30px;
  padding: 0px 40px;
  width: 100%;
  color: #212121;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  background: #fff;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  margin: 0 auto;
}
.radius {
  border-radius: 30px;
}
.searchInput:hover {
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
}
.types,
.topic {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 12px;
  width: 40ch;
  div {
    padding: 10px 0px 10px 20px;
    background: #efefef54;
    border-radius: 30px;
    height: 15px;
    width: fit-content;
    display: inline;
    margin: 15px 2px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }
  .types_text {
    margin-right: 15px;
    font-size: 12px;
  }
  .close {
    background: #1976d2;
    color: #fff;
    padding: 1px;
    border-radius: 100%;
    text-align: right;
    height: auto;
    cursor: pointer;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }
  @media only screen and (max-width: 540px) {
    div {
      display: inherit;
    }
  }
}
.topic {
  div {
    display: table;
  }
}
.close {
  background: #1976d2;
  color: #fff;
  padding: 1px;
  border-radius: 100%;
  text-align: right;
  height: auto;
  cursor: pointer;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
}
.expanderTitles {
  color: #311b92;
}
.expand > div.v-expansion-panel-content__wrap {
  padding: 0 0px 16px !important;
}
.mobil-margin-left {
  margin-left: -25px !important;
  display: inline-block;
  margin-right: 30px !important;
  position: relative;
}
@media (min-width: 1025px) {
  .showExpander {
    display: none;
  }
  .showBubbles {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}
@media (max-width: 1025px) {
  .showExpander {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .showBubbles {
    display: none;
  }
}
@media (max-width: 768px) {
  .overFlowTopic {
    display: inline-block;
    overflow: auto;
    min-height: 45px;
  }
}
</style>
