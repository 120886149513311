<template>
  <div>
    <div v-if="tracks.previewUrl || tracks.preview_url">
      <div class="pointer" @click="play">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              data-google="audioPreviewBtn"
              icon
              tile
              :class="size > 30 ? 'buttonsApp' : 'iconsApp'"
              aria-label="Play"
              v-on="on"
            >
              <v-icon
                v-show="!audio.paused"
                :size="parseInt(size)"
                :color="color"
                >mdi-pause-circle</v-icon
              >
              <v-icon
                v-show="audio.paused"
                :size="parseInt(size)"
                :color="color"
                >mdi-play-circle
              </v-icon>
            </v-btn>
          </template>
          <div :key="getReload">
            <b>{{ audio.paused ? "Play preview" : "Stop preview" }}</b>
          </div>
          <div>
            <b>Song name:</b> <i>"{{ tracks.name }}"</i>
          </div>
        </v-tooltip>
      </div>
    </div>
    <div v-else>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            tile
            icon
            :class="size > 30 ? 'buttonsApp' : 'iconsApp'"
            aria-label="No Preview"
            v-on="on"
          >
            <v-icon :size="parseInt(size)" :color="color">mdi-cancel </v-icon>
          </v-btn>
        </template>
        <span>Sorry, we don't have a preview</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AudioPlayer",
  props: {
    tracks: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: "24",
    },
    color: {
      type: String,
      default: "#311b92",
    },
  },
  data() {
    return {
      audio: null,
      circleLeft: null,
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,
      currentTrack: null,
      currentTrackIndex: 0,
      transitionName: null,
    };
  },
  computed: {
    ...mapGetters(["getAudioObject", "getReload"]),
  },
  created() {
    const vm = this;
    this.currentTrack = this.tracks;
    this.audio = new Audio();
    const previewUrl =
      this.currentTrack.previewUrl || this.currentTrack.preview_url;
    if (previewUrl !== undefined) {
      this.audio.src = previewUrl;
      this.audio.ontimeupdate = () => {
        vm.generateTime();
      };
      this.audio.onloadedmetadata = () => {
        vm.generateTime();
      };
      this.audio.onended = () => {
        vm.nextTrack();
        this.isTimerPlaying = true;
      };
    }
  },
  beforeDestroy() {
    this.audio.pause();
  },
  methods: {
    ...mapActions(["changeAudioObject", "changeReload"]),
    play() {
      try {
        this.getAudioObject.pause();
        this.getAudioObject.load();
      } catch (e) {
        return e;
      } finally {
        if (this.audio.paused && this.audio !== this.getAudioObject) {
          this.changeAudioObject(this.audio);
          this.audio.play();
          this.isTimerPlaying = true;
        } else {
          this.changeAudioObject({});
          this.audio.pause();
          this.isTimerPlaying = false;
        }
        this.changeReload();
      }
      return true;
    },
    generateTime() {
      const width = (100 / this.audio.duration) * this.audio.currentTime;
      this.barWidth = `${width}%`;
      this.circleLeft = `${width}%`;
      let durmin = Math.floor(this.audio.duration / 60);
      let dursec = Math.floor(this.audio.duration - durmin * 60);
      let curmin = Math.floor(this.audio.currentTime / 60);
      let cursec = Math.floor(this.audio.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = `0${durmin}`;
      }
      if (dursec < 10) {
        dursec = `0${dursec}`;
      }
      if (curmin < 10) {
        curmin = `0${curmin}`;
      }
      if (cursec < 10) {
        cursec = `0${cursec}`;
      }
      this.duration = `${durmin}:${dursec}`;
      this.currentTime = `${curmin}:${cursec}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
