import { axiosConnection, localStorageHandler } from "@/helpers";

const spotify = {
  state: {
    user: {},
    me: "/user-mgm/me/",
    album: "https://api.spotify.com/v1/albums",
    albumList: [],
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setAlbumList(state, data) {
      state.albumList = [...state.albumList, ...data];
    },
  },
  actions: {
    async getUserData({ state, commit }) {
      const platform = localStorageHandler.getLocalStore("platform");
      const result = axiosConnection.getAll(state.me + platform);
      await result.then((resp) => {
        commit("setUser", resp.data);
      });
      return result;
    },
    async getAlbum({ state, commit }, id) {
      const result = await axiosConnection.spotifyGetById(state.album, id);
      commit("setAlbumList", [result.data]);
      return result;
    },
  },
  getters: {
    getUserInfo(state) {
      return state.user;
    },
    getAlbumList(state) {
      return state.albumList;
    },
  },
};
export default spotify;
