<template>
  <v-app>
    <v-navigation-drawer
      id="navy"
      v-model="drawerLeft"
      app
      left
      fixed
      class="bg-nav crystalBackground px-3"
      width="300"
      :temporary="temporary"
      :permanent="permanent"
      overlay-opacity="0"
    >
      <general-menu
        :panel-status="permanent"
        @update:permanent="updatePosition"
      ></general-menu>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      right
      class="play_list bg-nav crystalBackground"
      :temporary="!showPermanent"
      :permanent="showPermanent"
      overlay-opacity="0"
    >
      <v-row class="px-2 pt-4 pb-0">
        <v-col cols="2" class="mr-0 pl-0 d-flex justify-center">
          <v-icon x-large color="primary"
            >mdi-checkbox-marked-circle-plus-outline</v-icon
          ></v-col
        >
        <v-col cols="7" class="ml-0 pl-0">
          <span class="title deepPurple"> Selected music</span><br />
          <span class="caption deepPurple"
            >Export playlist to your preferred service</span
          >
        </v-col>

        <v-col cols="3" class="d-flex pt-5 justify-end">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                tile
                class="btn-purple fixIcon mx-1 hidden"
                v-bind="attrs"
                elevation="2"
                aria-label="Change playlist visible status"
                v-on="on"
              >
                <v-icon
                  color="white"
                  e="22"
                  @click="(showPermanent = !showPermanent), updateMenuStore()"
                  >mdi-view-dashboard-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ playListFixedStatus }}</span>
          </v-tooltip>
          <!--  <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="!showPermanent"
                icon
                tile
                class="btn-purple mx-1"
                v-bind="attrs"
                elevation="2"
                aria-label="Show permanent playlist"
                v-on="on"
              >
                <v-icon color="white" size="22" @click="drawer = !drawer"
                  >mdi-arrow-split-vertical
                </v-icon>
              </v-btn>
            </template>
            <span>{{ playListStatus }}</span>
          </v-tooltip> -->
        </v-col>
      </v-row>
      <v-row class="px-1 pb-4">
        <v-col cols="12" class="px-8 mb-2">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                ><playListCreator></playListCreator>
              </span>
            </template>
            {{
              playlistHaveItems
                ? "Create Playlist from selected music"
                : "At least one item is needed"
            }}
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col cols="12" class="ma-1 pa-1">
          <slot name="play_list" class="mt-0 pt-0"></slot>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-main class="content">
      <v-row
        class="topBarLocal crystalBackground"
        :class="showPermanent ? 'barPermanent' : ''"
      >
        <v-col
          v-if="!permanent"
          cols="12"
          sm="2"
          xs="12"
          class="hidden-xs-only d-flex pl-5 bg-transparent-sm-only"
        >
          <v-btn
            class="mx-1"
            icon
            text
            aria-label="Toggle left drawer"
            @click="updatePosition()"
          >
            <v-icon color="deep-purple darken-4">mdi-menu</v-icon></v-btn
          >
          <h1 class="logo-text-color ml-4"><a href="/">Zunzun</a></h1>
        </v-col>
        <v-col cols="12" :md="permanent ? '10' : '8'" xs="12" class="d-5">
          <v-container class="mobile-centered-container">
            <v-row class="d-flex justify-space-between ma-0 pa-0">
              <v-col cols="12" class="top-bar-centered-items">
                <billboardSearch
                  :layout="'searchLayout'"
                  :re-fetch-hits="reFetchHits"
                  style="width: inherit"
                  @restore-pagination="page = 1"
                  @show-advancer-search="showAdvancerSearch"
                ></billboardSearch>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          cols="3"
          sm="2"
          xs="0"
          class="top-bar-centered-right-items px-5 hidden-xs-only"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                data-google="showPlayListBarBtn"
                class="mx-1"
                icon
                text
                aria-label="Show playlist bar"
                v-on="on"
                @click="showPlayListDrawer()"
              >
                <v-badge
                  v-show="getPlayList.length > 0"
                  :class="getPlayList.length > 0 ? '' : ''"
                  bordered
                  :color="getColor(getPlayList)"
                  overlap
                  left
                  bottom
                  :content="
                    getPlayList.length > 99 ? '+ 99' : getPlayList.length
                  "
                >
                  <v-icon class="mx-2" color="deep-purple darken-4"
                    >mdi-format-list-bulleted-square</v-icon
                  >
                </v-badge>
                <v-icon
                  v-show="getPlayList.length <= 0"
                  class="mx-2"
                  color="deep-purple darken-4"
                  aria-label="Hits in Playlist counter"
                  >mdi-format-list-bulleted-square</v-icon
                >
              </v-btn>
            </template>
            <span>Show PlayList</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                class="mx-1 hidden"
                icon
                text
                v-bind="attrs"
                aria-label="Settings"
                @click="goTo('Configurations')"
                v-on="on"
              >
                <v-icon color="deep-purple darken-4">mdi-cog-outline</v-icon>
              </v-btn>
            </template>
            <span>Settings & Configurations</span>
          </v-tooltip>

          <login />
        </v-col>
      </v-row>
      <v-row
        v-if="search || content"
        class="topBarLocalContent"
        :class="showPermanent ? 'barPermanent' : ''"
      >
        <v-col cols="12" class="d-center- d-5 mb-15 small-padding">
          <v-container
            v-if="search"
            class="my-5 mobile-centered-container"
            small-padding
          >
            <v-row v-if="browser" class="filtersLayout">
              <v-col cols="12" class="mb-0 pb-0">
                <slot name="browser"></slot>
              </v-col>
            </v-row>
            <v-row v-if="searchLength && !getLoading">
              <v-col cols="12" class="mb-8">
                <v-sheet min-height="70vh" class="round">
                  <v-card flat>
                    <v-card-title class="grey lighten-5 px-6">
                      <v-icon
                        :size="32"
                        color="deep-purple darken-4 "
                        class="mr-2 onlyDesktop"
                        >mdi-view-list
                      </v-icon>
                      <span class="deepPurple searchResult">
                        Search Result
                      </span>
                      <v-spacer></v-spacer>

                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="hidden"
                            v-bind="attrs"
                            data-google="changeHitsLayoutBtn"
                            aria-label="change hits layout"
                            v-on="on"
                            @click="setLayoutDisplay(!getLayoutDisplay)"
                          >
                            <v-icon size="24" color="deep-purple darken-4"
                              >{{
                                getLayoutDisplay ? "mdi-collage" : "mdi-id-card"
                              }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          getLayoutDisplay ? "Show as cards" : "Show as bubbles"
                        }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            elevation="0"
                            v-bind="attrs"
                            class="pa-5"
                            data-google="addMultiplesHitToPlayListBtn"
                            aria-label="Add/Remove multiples hits to playlist"
                            v-on="on"
                            @click="updateState()"
                          >
                            <v-icon size="24" color="deep-purple darken-4">{{
                              hasBeenIncludedInPlaylist
                                ? "mdi-playlist-remove  "
                                : "mdi-playlist-plus"
                            }}</v-icon>
                          </v-btn>
                        </template>
                        <span
                          >{{
                            hasBeenIncludedInPlaylist
                              ? "Delete all to the playlist"
                              : "Add all hits to playlist"
                          }} </span
                        ><b> - Page #{{ getActualPage }}</b>
                      </v-tooltip>

                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            aria-label="Playlist counter hits"
                            icon
                            v-bind="attrs"
                            class="smallDV mr-3"
                            v-on="on"
                            @click="showPlayListDrawer()"
                          >
                            <v-badge
                              :class="getPlayList.length > 0 ? '' : ''"
                              bordered
                              :color="getColor(getPlayList)"
                              :content="
                                getPlayList.length > 99
                                  ? '+ 99'
                                  : getPlayList.length
                              "
                              overlap
                            >
                              <v-icon class="mx-2" size="24"
                                >mdi-format-list-bulleted-square</v-icon
                              >
                            </v-badge>
                          </v-btn>
                        </template>
                        <span>Show PlayList</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="py-8 px-6">
                      <slot name="main"></slot>
                    </v-card-text>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-if="!searchLength && !getLoading">
              <v-col cols="12" class="mb-8">
                <v-sheet min-height="70vh" class="round">
                  <v-alert
                    v-if="searchStatus"
                    type="info"
                    class="ma-4 roundAlert"
                    color="deep-purple darken-4"
                    dismissible
                  >
                    Data is not found! Unfortunately at the moment we don't have
                    that information. But, in the meantime you can take a look
                    at our most popular searches.
                  </v-alert>

                  <v-card flat>
                    <v-card-title class="px-6 grey lighten-5">
                      <v-icon :size="32" color="#311b92" class="mr-4"
                        >mdi-view-list </v-icon
                      ><span class="deepPurple">Popular Search</span>
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text class="py-8 px-6">
                      <slot name="noData"></slot>
                    </v-card-text>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-if="getLoading">
              <v-col cols="12" class="mb-8">
                <v-sheet min-height="70vh" class="round">
                  <v-card flat>
                    <v-card-title class="grey lighten-5 py-5 px-6">
                      <v-icon
                        :size="32"
                        color="deep-purple darken-4 "
                        class="mr-2 onlyDesktop"
                        >mdi-view-list </v-icon
                      ><span class="deepPurple searchResult">Searching...</span>
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text
                      v-for="(items, key) in getItemsPerPage"
                      :key="key"
                      class="py-8 px-6"
                    >
                      <v-row class="grey lighten-5 ma-4 pa-3 round">
                        <v-col cols="12" sm="12" md="3">
                          <v-skeleton-loader
                            type="list-item-avatar-three-line"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <v-skeleton-loader
                            type="list-item-avatar-three-line"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <v-skeleton-loader
                            type="list-item-avatar-three-line"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <v-skeleton-loader type="actions"></v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card> </v-sheet
              ></v-col>
            </v-row>
            <v-row>
              <slot name="extras"></slot>
            </v-row>
          </v-container>
          <v-container v-if="content" class="my-5 mobile-centered-container">
            <v-col cols="12" class="mb-8">
              <v-sheet min-height="70vh" class="round">
                <v-card flat>
                  <v-card-title class="grey lighten-5 px-6">
                    <slot name="content-title"></slot
                  ></v-card-title>
                  <v-card-text
                    ><slot name="content"></slot
                  ></v-card-text> </v-card></v-sheet
            ></v-col>
          </v-container>
        </v-col>
      </v-row>
      <section v-if="home">
        <slot name="home"></slot>
      </section>
    </v-main>
    <suggestions></suggestions>
    <floating-login class="hidden-sm-and-up"></floating-login>
    <v-dialog v-model="showSearch" max-width="1250" persistent>
      <v-card class="pa-5">
        <v-card-title class="d-flex py-3 mr-0 pr-0">
          <span class="deepPurple">
            <v-icon class="mr-2" color="primary"
              >mdi-filter-variant-plus</v-icon
            >
            Advanced Search</span
          >
          <v-spacer></v-spacer>
          <v-btn
            class="grey lighten-3 mr-3"
            color="deep-purple darken-4"
            rounded
            elevation="0"
            icon
            plain
            raised
            small
            aria-label="Show advanced Search"
            @click="showSearch = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="my-0 py-0">
          <filters
            v-if="showSearch"
            @fetch-hits="hideAdvancerSearch"
            @close="hideAdvancerSearch"
          ></filters> </v-card-text
      ></v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import suggestions from "@/domains/suggestions/views/suggestions";
import generalMenu from "@/domains/menu/general.menu";
import billboardSearch from "@/components/search/searches/search";
import filters from "@/components/search/filters/filters";
import playListBehavior from "@/mixins/playListBehavior";
import playListCreator from "@/domains/playLists/playListCreator";
import login from "@/domains/Login/topBarLogin";
import floatingLogin from "@/domains/Login/floatingLogin";
import { localStorageHandler } from "@/helpers";

export default {
  components: {
    suggestions,
    generalMenu,
    billboardSearch,
    filters,
    playListCreator,
    login,
    floatingLogin,
  },
  mixins: [playListBehavior],
  props: {
    content: { type: Boolean, default: false },
    home: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
  },
  data: () => ({
    reFetchHits: false,
    page: 1,
    drawerLeft: true,
    showSearch: false,
    drawer_play: null,
    drawer: null,
    showPermanent: false,
    browser: false,
    permanent: true,
    temporary: false,
    mini: false,
    pagesInPlayList: [],
  }),
  computed: {
    ...mapGetters([
      "getBillboardItems",
      "getRefreshLayout",
      "getSearchTopics",
      "getPlayList",
      "getLayoutDisplay",
      "getPlaylistCreator",
      "getErrors",
      "getLoading",
      "getItemsPerPage",
    ]),
    searchLength() {
      const items = Object.keys(this.getBillboardItems).length > 0;
      return items;
    },
    searchStatus() {
      return (
        this.getErrors.type === "error" || this.getErrors.type === "warning"
      );
    },
    playListStatus() {
      if (this.drawer) {
        return "Hide playlist bar";
      }
      return "Show playlist bar";
    },
    playListFixedStatus() {
      if (!this.showPermanent) {
        return "Fix playList bar";
      }
      return "Unfix playList bar";
    },
    playlistHaveItems() {
      return this.getPlayList.length > 0;
    },
    hasBeenIncludedInPlaylist() {
      return this.pagesInPlayList.includes(this.getActualPage);
    },
  },
  watch: {
    drawerLeft() {
      this.updateMenuStore();
    },
  },
  mounted() {
    const siteConfiguration =
      localStorageHandler.getJsonLocalStore("site-configuration");
    if (siteConfiguration) {
      const { permanent, drawerLeft, drawer, showPermanent, temporary } =
        siteConfiguration;
      this.permanent = permanent;
      this.temporary = temporary;
      this.drawerLeft = drawerLeft;
      this.drawer = drawer;
      this.showPermanent = showPermanent;
    }
  },
  methods: {
    ...mapMutations([
      "setPlayList",
      "setLayoutDisplay",
      "deleteFromPlayList",
      "setRefreshLayout",
      "setBillboardItems",
      "setPlayListUpdate",
    ]),
    showPlayListDrawer() {
      this.drawer = !this.drawer;
      this.setRefreshLayout();
    },
    openWindowsPlay() {
      this.drawer_play = !this.drawer_play;
      this.setRefreshLayout();
    },
    topicLength() {
      return Object.keys(this.getSearchTopics).length > 3;
    },
    getColor(data) {
      if (data.length <= 0) {
        return "transparent";
      }
      if (data.length < 99) {
        return "deep-purple darken-4";
      }
      return "red darken-4";
    },
    showAdvancerSearch() {
      this.showSearch = true;
      this.reFetchHits = false;
    },
    hideAdvancerSearch() {
      this.showSearch = false;
      this.reFetchHits = true;
    },
    goTo(name) {
      this.$router.push({ name, params: { name } });
    },
    toggleMenu() {
      this.drawerLeft = !this.drawerLeft;
      this.permanent = false;
      this.temporary = true;
      this.updateMenuStore();
    },
    updatePosition() {
      this.permanent = !this.permanent;
      this.temporary = !this.temporary;
      this.drawerLeft = !this.drawerLeft;
      this.updateMenuStore();
    },
    updateMenuStore() {
      const config = {
        permanent: this.permanent,
        drawerLeft: this.drawerLeft,
        drawer: this.drawer,
        temporary: this.temporary,
        showPermanent: this.showPermanent,
      };
      localStorageHandler.setJsonLocalStore("site-configuration", config);
    },
    updateState() {
      if (this.hasBeenIncludedInPlaylist) {
        this.pagesInPlayList = this.pagesInPlayList.filter(
          (page) => page !== this.getActualPage
        );
      } else {
        this.pagesInPlayList.push(this.getActualPage);
      }
      this.addMultipleHits(this.hasBeenIncludedInPlaylist);
    },
  },
};
</script>

<style lang="scss" scoped>
$bg_: #ffffff;
.bg-nav {
  background: $bg_;
  color: $bg_;
}

.top-bar-centered-right-items {
  display: inline-flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
.top-bar-centered-items {
  display: inline-flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
}

.noBorder {
  border: 0px transparent solid !important;
}

.play_list {
  min-width: 500px;
  background: $bg_;
}
.barPermanent {
  padding: 0px 256px 0px 0px;
}
.b_text {
  color: #212121;
}
.roundAlert {
  border-radius: 25px;
}
.logo-text-color {
  display: inline;
  letter-spacing: normal !important;
  font-size: 2rem;
  font-weight: 900;
  color: #311b92;
}
.topBarLocal {
  background-color: $bg_;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
  .bg-transparent-sm-only > h1 {
    display: inline;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;

    .bg-transparent-sm-only {
      background-color: #eeeeee;
      justify-content: space-between;
      h1 {
        display: inline;
      }
    }
    .logo-text-color {
      display: inline;
    }
  }
}
.topBarLocalContent {
  background-color: #eeeeee;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 !important;
  padding: 0 15px;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
}
.playlist {
  font-size: 1.2rem !important;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "SFProDisplay-Regular", "Open Sans", "sans-serif" !important;
  color: #311b92;
}

.filtersLayout {
  padding: 10px 20px;
  border-radius: 20px;
  background: $bg_;
  margin: 2px;
  margin-bottom: 0 !important;
}
.smallText {
  font-size: 13px;
  margin-top: -5px;
}
.textWhite {
  color: white !important;
  font-size: 1.01rem;
  line-height: 1.5rem;
}
.center {
  text-align: center;
}
.logo {
  width: 65px;
  height: 55px;
}
.v-toolbar--prominent,
.v-toolbar__content {
  align-items: flex-start;
  height: auto !important;
}
.app_bar_local {
  font-size: inherit !important;
  height: 95px !important;
}
.headPlayList {
  height: 65px !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  background-color: $bg_; // #311b92;
}

.container {
  max-width: 1250px;
  padding-right: 20px;
  padding-left: 20px;
  overflow: hidden;
}

.main {
  background-image: url("~@/assets/images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  background: #eee !important;
}

.bg {
  overflow: hidden;
  box-shadow: 7px 7px 10px #ebebeb, -7px -7px 10px $bg_;
  border-color: rgba(223, 225, 229, 0);
}
.round {
  overflow: hidden;
  border-radius: 30px;
}
.play_list {
  min-width: 500px;
  background: #f5f5f5;
}
.flex_start {
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  justify-self: center;
}
.smallDV {
  display: none;
}
.lgDV {
  display: inline-flex;
}
.btn-purple {
  border-radius: 50px;
  background: linear-gradient(145deg, #341d9c, #2c1883);
  box-shadow: 16px 16px 32px #140b3a, -16px -16px 32px #4e2bea;
}
.small-padding {
  padding: inherit;
}
@media (max-width: 1970px) {
  .fixIcon {
    display: none;
  }
}
@media (max-width: 1024px) {
  .mobile-centered-container {
    max-width: 100% !important;
  }
}
@media (max-width: 768px) {
  .onlyDesktop {
    display: none;
  }
  .smallDV {
    display: inline-flex;
  }
  .lgDV {
    display: none;
  }
  .app_bar_local {
    height: auto !important;
    width: 100%;
    padding: 0;
  }
  .container {
    padding: 0 !important;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
  .mobile_padding {
    padding: 5px 10px !important;
  }
  .mobile_margin {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .play_list {
    min-width: 100%;
  }
  .searchResult {
    font-size: 1.2rem;
  }
  .mobile-centered-container {
    padding: 20px !important;
  }
}
@media (max-width: 600px) {
  .small-padding {
    padding: 5px 5px 25px 5px !important;
  }
}
</style>
