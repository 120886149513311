<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="primary radio20 pl-btn"
          large
          :disabled="!playlistHaveItems"
          data-google="CreatePlNow"
          aria-label="Create playlist"
          v-on="on"
        >
          <v-icon left color="white" size="32" class="mx-4"
            >mdi-account-music</v-icon
          >
          Create it now!!!
        </v-btn>
      </template>
      <v-card class="pa-5">
        <v-card-title class="d-flex py-3 mr-0 pr-0">
          <span class="deepPurple">
            <v-icon class="mr-2" color="primary" medium>
              mdi-account-music
            </v-icon>
            Create your own Playlist</span
          >
          <v-spacer></v-spacer>
          <v-btn
            class="grey lighten-3 mr-0"
            color="deep-purple darken-4"
            rounded
            elevation="0"
            icon
            plain
            raised
            small
            data-google="closeYoutubePlayListWindowBtn"
            aria-label="Close Playlist creator"
            @click="cancel()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-stepper v-model="e6" vertical flat>
            <v-stepper-step
              :complete="e6 > 1"
              step="1"
              :color="isLogged ? 'primary' : 'red'"
            >
              Select music service.
              <small class="mt-1">Chose your favorite music services. </small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card
                class="grey lighten-5 radio20"
                height="auto"
                elevation="0"
              >
                <v-card-text class="grey lighten-5 radio20">
                  <v-list
                    class="pb-4 ma-0 grey lighten-5"
                    rounded
                    elevation="0"
                    two-line
                    dense
                  >
                    <v-list-item-group
                      v-model="model"
                      color="primary"
                      active-class="deep-purple--text"
                      mandatory
                    >
                      <template v-for="(item, index) in items">
                        <v-list-item :key="index" class="centered py-3">
                          <template #default="{ active }">
                            <v-list-item-icon
                              class="pr-0 mr-2 centered-actions"
                              :class="loggedIcon(item) ? 'pt-4' : 'pt-7'"
                            >
                              <v-icon
                                :color="item.color"
                                size="35"
                                v-text="item.icon"
                              ></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="centered-actions">
                              <v-list-item-title
                                v-text="item.text"
                              ></v-list-item-title>

                              <v-list-item-subtitle
                                v-text="playListSongsCounter(item.id)"
                              ></v-list-item-subtitle>

                              <v-list-item-subtitle
                                v-text="item.subtitle"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="centered-actions">
                              <span v-if="loggedIcon(item)">
                                <v-icon
                                  v-if="loggedIcon(item)"
                                  color="grey lighten-1"
                                  class="mx-2"
                                  >mdi-lock-open-variant</v-icon
                                >
                                <v-icon v-else class="mx-2" color="primary"
                                  >mdi-lock-open-alert</v-icon
                                >
                                <v-icon
                                  v-if="!active"
                                  class="mx-2"
                                  color="grey lighten-1"
                                  >mdi-check-outline</v-icon
                                >
                                <v-icon v-else class="mx-2" color="primary"
                                  >mdi-check-bold</v-icon
                                >
                              </span>
                              <span v-else>
                                <oneClickLogin
                                  :icons="item.login.icons"
                                  :icon-size="item.login.iconSize"
                                  :color="item.login.color"
                                  :color-icon="item.login.colorIcon"
                                  :btn-text="item.login.btnText"
                                  :service="item.target"
                                  :message="item.login.message"
                                  :size="item.login.iconSize"
                                  :img="item.login.img"
                                  :btn-text-color="item.login.btnTextColor"
                                ></oneClickLogin>
                              </span>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="radio20 px-6"
                    color="primary"
                    :disabled="!isLogged"
                    aria-label="Next"
                    @click="e6 = 2"
                  >
                    Continue
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">
              Playlist preview.
              <small class="mt-1">Take a look of you future playlist </small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card
                elevation="0"
                class="grey lighten-5 radio20"
                height="auto"
              >
                <v-card-text class="grey lighten-5 radio20">
                  <previewPlTable :hits="playlist"></previewPlTable
                ></v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    class="radio20 mr-4 px-6"
                    aria-label="Previous"
                    @click="e6 = 1"
                  >
                    Go back
                  </v-btn>
                  <v-spacer />
                  <v-btn color="primary" class="px-6 radio20" @click="e6 = 3">
                    Continue
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" step="3">
              Information
              <small class="mt-1"
                >Required information to create the playlist
              </small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card
                class="grey lighten-5 radio20"
                height="auto"
                elevation="0"
              >
                <v-card-text>
                  <v-text-field
                    v-model="name"
                    label="Name"
                    hint="Playlist name (required)"
                    outlined
                    persistent-hint
                    class="mt-4"
                    clearable
                    rounded
                    dense
                    color="deep-purple darken-4"
                    item-color="deep-purple darken-1"
                  >
                    <template #append-outer>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon left color="deep-purple darken-4" v-on="on">
                            mdi-information-variant
                          </v-icon>
                        </template>
                        The playlist should have a name!
                      </v-tooltip></template
                    >
                  </v-text-field>
                  <v-textarea
                    v-model="desc"
                    class="mt-4"
                    outlined
                    rounded
                    label="Description"
                    hint="Playlist description"
                    persistent-hint
                    clearable
                    color="deep-purple darken-4"
                    item-color="deep-purple darken-1"
                    allow-overflow
                  ></v-textarea
                ></v-card-text>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    class="radio20 mr-4 px-6"
                    aria-label="Previous"
                    @click="e6 = 2"
                  >
                    Go back
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    :disabled="name === ''"
                    color="primary"
                    class="radio20 px-6"
                    aria-label="Next"
                    @click="e6 = 4"
                  >
                    Continue
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card
                v-show="!showOptions && !saveOptions"
                class="grey lighten-3 radio20 my-5"
                height="auto"
                elevation="0"
              >
                <v-card-text class="grey lighten-5 pa-5radio20">
                  <h2>After save playlist, what should we do?</h2>
                  <small
                    ><v-icon small color="red">mdi-information-outline</v-icon>
                    Note: If you wanna change this action back, go to user
                    configuration!</small
                  >
                  <v-divider></v-divider>
                  <v-switch
                    v-model="keepAdding"
                    color="deep-purple darken-4"
                    label="Create playlist and keep adding music"
                    class="pb-0 mb-0"
                    @click="cleanPlayList = !keepAdding"
                  ></v-switch>
                  <v-switch
                    v-model="cleanPlayList"
                    color="deep-purple darken-4"
                    label="Clean playlist, and create a new one"
                    class="py-0 my-0"
                    @click="keepAdding = !cleanPlayList"
                  ></v-switch>
                  <v-checkbox
                    v-model="saveOptions"
                    class="py-0 my-0"
                    color="deep-purple darken-4"
                    label="Save selection for futures actions"
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-step step="4">
              Playlist Resume
              <small class="mt-1">Playlist Overview before create it </small>
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-card
                class="grey lighten-5 radio20"
                height="auto"
                elevation="0"
              >
                <v-card-title>
                  <span class="subtitle-2 deepPurple">
                    <v-icon class="mr-2" color="primary">mdi-information</v-icon
                    >Playlist information related.
                  </span>
                </v-card-title>
                <v-card-text class="mr-2">
                  <v-divider class="mb-4"></v-divider>
                  <ul class="ulStyled">
                    <li class="subtitle-2">
                      <b class="mr-2">Service:</b
                      ><v-icon :color="activeService.color" class="mr-2">{{
                        activeService.icon
                      }}</v-icon
                      >{{ activeService.text }}
                    </li>
                    <li class="subtitle-2">
                      <b class="mr-2">Songs:</b>{{ playlist.length }}
                    </li>
                    <li class="subtitle-2">
                      <b class="mr-2">Name:</b> {{ name }}
                    </li>
                    <li class="subtitle-2">
                      <b class="mr-2">Description:</b>{{ desc }}
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    class="radio20 px-6"
                    aria-label="Previous"
                    @click="e6 = 3"
                  >
                    Go back
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="radio20 px-6"
            text
            aria-label="Cancel"
            @click="cancel()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            v-show="e6 === 4"
            color="primary"
            class="radio20 px-6"
            aria-label="Create Playlist"
            @click="createPlist()"
          >
            Finalize
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogs
      :show-alert="showAlert"
      :header="alert.header"
      :body="alert.body"
      :icon="alert.icon"
      @close-dialog="close"
    ></dialogs>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import previewPlTable from "@/domains/playLists/previewPlTable";
import dialogs from "@/components/helper/dialogs";
import oneClickLogin from "@/domains/Login/oneClickLogin";

export default {
  components: {
    previewPlTable,
    dialogs,
    oneClickLogin,
  },
  data() {
    return {
      e6: 1,
      dialog: false,
      items: [
        {
          icon: "mdi-spotify",
          text: "Spotify",
          color: "green",
          id: "spotifyUriId",
          target: "spotify",
          login: {
            icons: "spotify",
            iconSize: 24,
            color: "green",
            colorIcon: "#fff",
            message: "Sing in with  Spotify",
            btnText: "Sing in",
            img: false,
            btnTextColor: "white--text",
          },
        },
        {
          icon: "mdi-youtube",
          text: "Youtube music",
          color: "red",
          id: "youtubeUriId",
          target: "google",
          login: {
            icons: "youtube",
            iconSize: 24,
            color: "white",
            colorIcon: "#fff",
            message: "Sing in with Google",
            btnText: "Sing in",
            btnTextColor: "black--text",
            img: true,
          },
        },
        /* {
          icon: "mdi-music",
          text: "Apple music",
          color: "#fc3c44",
          id: "appleUriId",
          target: "apple",
          login: {
            icons: "music",
            iconSize: 24,
            color: "#fc3c44",
            colorIcon: "#fff",
            message: "Sing in with Apple ID",
            btnText: "Sing in",
            img: false,
            btnTextColor: "white--text",
          },
        }, */
      ],
      model: 0, // spotify obj key
      cleanPlayList: localStorage.getItem("cleanPlayList") || true,
      keepAdding: localStorage.getItem("keepAdding") || false,
      saveOptions: localStorage.getItem("saveOptions") || false,
      showWarnings: false,
      showAlert: false,
      alert: {},
      isInProcess: false,
      name: "",
      desc: "",
      message: "To create your own playlist, login <b>Spotify</b>.",
    };
  },
  computed: {
    ...mapGetters(["getPlayList", "getPlaylistCreator"]),
    playlist() {
      const { id } = this.items[this.model];
      const list = this.getPlayList.filter(
        (ele) => ele[id] && ele[id] !== false
      );
      return list;
    },
    activeService() {
      return this.items[this.model];
    },

    showOptions() {
      return localStorage.getItem("saveOptions") === "true";
    },
    isName() {
      return this.name !== "";
    },
    isLogged() {
      if (
        localStorage.getItem("userLogged") === "true" &&
        localStorage.getItem("platform") === this.activeService.target
      ) {
        return true;
      }
      return false;
    },
    isAbleToSave() {
      return this.isName;
    },
    playListByTarget() {
      const playListMap = this.items.map((ele) => {
        let ids = [];
        if (ele.target === "google") {
          ids = this.getPlayList.map((element) => element.youtubeUriId);
        } else if (ele.target === "spotify") {
          ids = this.getPlayList.map((element) => element.spotifyUriId);
        } else {
          ids = this.getPlayList.map((element) => element.appleUriId);
        }
        return { ...ele, ids };
      });
      return playListMap;
    },
    playlistHaveItems() {
      return this.getPlayList.length > 0;
    },
  },
  methods: {
    ...mapActions(["postCreatePlayList"]),
    ...mapMutations(["setPlayListUpdate", "setPlaylistCreator"]),
    cancel() {
      this.dialog = false;
      this.e6 = 1;
      this.clean();
    },
    playListSongsCounter(serviceId) {
      const list = this.getPlayList.filter(
        (ele) => ele[serviceId] && ele[serviceId] !== false
      );
      return `Playlist with ${list.length} song(s)`;
    },
    async createPlist() {
      this.isInProcess = true;
      const { target } = this.activeService;
      const { ids } = this.playListByTarget.find(
        (ele) => target === ele.target
      );
      const list = {
        name: this.name,
        description: this.desc,
        tracks: ids,
      };
      const rest = this.postCreatePlayList({ list, target });
      rest
        .then((resp) => {
          if (resp) {
            this.saveOptionsToLocalStore();
            this.setPlaylistCreator(true);
            if (this.cleanPlayList === "true") {
              this.setPlayListUpdate([]);
            }
          } else {
            this.alert = {
              header: "An error has been found in the save process",
              body: "Unfortunately we found an error tring to save your Playlist in your favorite platform",
              icon: "mdi-information",
            };
            this.setPlaylistCreator(false);
            this.showAlert = true;
          }
        })
        .catch(() => {
          this.alert = {
            header: "An error has been found in the save process",
            body: "Unfortunately we found an error tring to save your Playlist in your favorite platform",
            icon: "mdi-information",
          };
          this.setPlaylistCreator(false);
          this.showAlert = true;
        })
        .finally(() => {
          this.clean();
          this.cancel();
          if (this.getPlaylistCreator) {
            this.alert = {
              header: "Playlist has been created successfully",
              body: "Playlist is already saved in your favorite platform (spotify)",
              icon: "mdi-information",
            };
          } else {
            this.alert = {
              header: "An error has been found in the save process",
              body: "Unfortunately we found an error tring to save your Playlist in your favorite platform",
              icon: "mdi-information",
            };
          }
          this.showAlert = true;
          this.isInProcess = false;
        });
    },
    saveOptionsToLocalStore() {
      localStorage.setItem("saveOptions", this.saveOptions);
      localStorage.setItem("keepAdding", this.keepAdding);
      localStorage.setItem("cleanPlayList", this.cleanPlayList);
    },
    clean() {
      this.dialog = false;
      this.name = "";
      this.desc = " ";
    },
    close() {
      this.showAlert = false;
    },
    logged(item) {
      if (
        localStorage.getItem("userLogged") === "true" &&
        localStorage.getItem("platform") === item.target
      ) {
        return "Section active";
      }
      return "Section inactive ";
    },
    loggedIcon(item) {
      if (
        localStorage.getItem("userLogged") === "true" &&
        localStorage.getItem("platform") === item.target
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .centered {
    align-items: center;
    display: grid;
    position: relative;
    justify-content: center;
    text-align: center;
    place-items: center;
    padding: 20px 0;
    margin: 20px 0;
    background: rgba(255, 255, 255, 0.829);
  }
  .centered::after {
    display: none;
  }
  .centered-actions {
    margin: 5px 0 !important;
    padding: 5px 0 !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
.pl-btn {
  width: 100%;
}
</style>
