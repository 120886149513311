import Vue from "vue";
import Vuex from "vuex";

import billboard from "./modules/billboard";
import general from "./modules/general";
import spotify from "./modules/spotify";
import youtube from "./modules/youtube";
import suggestions from "./modules/suggestions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    billboard,
    general,
    spotify,
    youtube,
    suggestions
  },
});
