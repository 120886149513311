var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isValidPositionRange,
                          expression: "!isValidPositionRange",
                        },
                      ],
                      staticClass: "my-3",
                      attrs: { type: "warning" },
                    },
                    [
                      _c("span", { staticClass: "alertTest" }, [
                        _vm._v("Position range is wrong!"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isValidRange,
                          expression: "!isValidRange",
                        },
                      ],
                      staticClass: "my-3",
                      attrs: { type: "warning" },
                    },
                    [
                      _c("span", { staticClass: "alertTest" }, [
                        _vm._v("Date range is wrong"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  !_vm.showTitleFilter
                    ? _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          "cache-items": "",
                          "allow-overflow": "",
                          clearable: "",
                          color: "deep-purple darken-4",
                          "item-color": "deep-purple darken-1",
                          chips: "",
                          label: "Title",
                          hint: "Song Title",
                          "persistent-hint": "",
                          "append-icon": "mdi-format-title",
                          "item-text": "title",
                          "item-value": "title",
                          rounded: "",
                          dense: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.createTopicObject()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color:
                                                          "deep-purple darken-4",
                                                        "data-google":
                                                          "titleAutoFilterBtn",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTitleFilter =
                                                            !_vm.showTitleFilter
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-filter-plus ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2510037722
                                      ),
                                    },
                                    [_vm._v(" Change to autocomplete filter. ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          779298426
                        ),
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      })
                    : _c("v-autocomplete", {
                        attrs: {
                          items: _vm.titleItems,
                          label: "Title",
                          hint: "Song Title - autocomplete",
                          "persistent-hint": "",
                          "append-icon": "mdi-format-title",
                          rounded: "",
                          dense: "",
                          loading: _vm.isTitleLoading,
                          "search-input": _vm.autoSearchTitle,
                          "cache-items": "",
                          "allow-overflow": "",
                          clearable: "",
                          color: "deep-purple darken-4",
                          "item-color": "deep-purple darken-1",
                          chips: "",
                          outlined: "",
                          "item-text": "title",
                          "item-value": "title",
                          "no-filter": "",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.autoSearchTitle = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.autoSearchTitle = $event
                          },
                          change: function ($event) {
                            return _vm.createTopicObject()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-outer",
                            fn: function () {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color:
                                                      "deep-purple darken-4",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTitleFilter =
                                                        !_vm.showTitleFilter
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [_vm._v(" mdi-filter-minus ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [_vm._v(" Write you own search parameter. ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mt-2 white--text",
                                    attrs: { color: "deep-purple darken-4" },
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { left: "" } },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: data.item.imageUrl,
                                            "on-error": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formattedData(data.item.title, 10)
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                typeof data.item !== "object"
                                  ? [
                                      _c("v-list-item-content", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.formattedData(data.item, 10)
                                          ),
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("v-list-item-avatar", [
                                        _c("img", {
                                          attrs: { src: data.item.imageUrl },
                                        }),
                                      ]),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.formattedData(
                                                  data.item.title,
                                                  15
                                                )
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-combobox", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showArtistFilter,
                        expression: "!showArtistFilter",
                      },
                    ],
                    attrs: {
                      outlined: "",
                      rounded: "",
                      dense: "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      "append-icon": "mdi-account-music",
                      chips: "",
                      "item-text": "name",
                      "item-value": "name",
                      hint: "Artist",
                      label: "Artist",
                      multiple: "",
                      "persistent-hint": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.createTopicObject()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                left: "",
                                                color: "deep-purple darken-4",
                                                "data-google":
                                                  "artistAutoFilterBtn",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showArtistFilter =
                                                    !_vm.showArtistFilter
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v(" mdi-filter-plus ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_vm._v(" Change to autocomplete filter. ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "v-chip",
                              {
                                key: JSON.stringify(data.item),
                                staticClass: "white--text ma-1",
                                attrs: {
                                  color: "deep-purple darken-4",
                                  "item-color": "deep-purple darken-1",
                                  "append-icon": "mdi-account-music",
                                },
                              },
                              [_vm._v(" " + _vm._s(data.item) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.artist,
                      callback: function ($$v) {
                        _vm.artist = $$v
                      },
                      expression: "artist",
                    },
                  }),
                  _c("v-autocomplete", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showArtistFilter,
                        expression: "showArtistFilter",
                      },
                    ],
                    attrs: {
                      label: "Artist",
                      outlined: "",
                      items: _vm.artistItems,
                      loading: _vm.isArtistLoading,
                      "search-input": _vm.autoSearchArtist,
                      "cache-items": "",
                      rounded: "",
                      dense: "",
                      multiple: "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      "append-icon": "mdi-account-music",
                      chips: "",
                      "item-text": "name",
                      "item-value": "name",
                      hint: "Artist - autocomplete",
                      "persistent-hint": "",
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.autoSearchArtist = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.autoSearchArtist = $event
                      },
                      change: function ($event) {
                        return _vm.createTopicObject()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                left: "",
                                                color: "deep-purple darken-4",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showArtistFilter =
                                                    !_vm.showArtistFilter
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v(" mdi-filter-minus ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_vm._v(" Write you own search parameter. ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "mt-2 white--text",
                                attrs: { color: "deep-purple darken-4" },
                              },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { left: "" } },
                                  [
                                    _c("v-img", {
                                      attrs: { src: data.item.imageUrl },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formattedData(data.item.name, 10)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            typeof data.item !== "object"
                              ? [
                                  _c("v-list-item-content", {
                                    domProps: {
                                      textContent: _vm._s(data.item),
                                    },
                                  }),
                                ]
                              : [
                                  _c("v-list-item-avatar", [
                                    _c("img", {
                                      attrs: { src: data.item.imageUrl },
                                    }),
                                  ]),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.formattedData(
                                              data.item.name,
                                              15
                                            )
                                          ),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.artist),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.artist,
                      callback: function ($$v) {
                        _vm.artist = $$v
                      },
                      expression: "artist",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _vm.localMemory.producer.length > 0 && _vm.showLabelFilter
                    ? _c("v-autocomplete", {
                        attrs: {
                          label: "Producer",
                          outlined: "",
                          items: _vm.localMemory.producer,
                          rounded: "",
                          dense: "",
                          clearable: "",
                          color: "deep-purple darken-4",
                          "item-color": "deep-purple darken-1",
                          "append-icon": "mdi-home-variant-outline",
                          chips: "",
                          hint: "Song Producer - autocomplete",
                          "persistent-hint": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.createTopicObject()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color:
                                                          "deep-purple darken-4",
                                                        "data-google":
                                                          "producerAutoFilterBtn",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showLabelFilter =
                                                            !_vm.showLabelFilter
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-filter-minus ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4230221124
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " Write you own search parameter. "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "selection",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "mt-2 white--text",
                                      attrs: { color: "deep-purple darken-4" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", color: "white" } },
                                        [_vm._v("mdi-home-variant-outline")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formattedData(data.item, 10)
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  typeof data.item !== "object"
                                    ? [
                                        _c(
                                          "v-list-item-avatar",
                                          { staticClass: "mx-0 px-0" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "deep-purple darken-4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-home-variant-outline "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-content", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.formattedData(data.item, 15)
                                            ),
                                          },
                                        }),
                                      ]
                                    : [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "white",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-home-variant-outline "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(data.item),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4014984108
                        ),
                        model: {
                          value: _vm.label,
                          callback: function ($$v) {
                            _vm.label = $$v
                          },
                          expression: "label",
                        },
                      })
                    : _c("v-text-field", {
                        attrs: {
                          label: "Producer",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          clearable: "",
                          color: "deep-purple darken-4",
                          "item-color": "deep-purple darken-1",
                          "append-icon": "mdi-home-variant-outline",
                          chips: "",
                          hint: "Song Producer",
                          "persistent-hint": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.createTopicObject()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-outer",
                            fn: function () {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color:
                                                      "deep-purple darken-4",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showLabelFilter =
                                                        !_vm.showLabelFilter
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [_vm._v(" mdi-filter-plus ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [_vm._v(" Change to autocomplete filter ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.label,
                          callback: function ($$v) {
                            _vm.label = $$v
                          },
                          expression: "label",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _vm.localMemory.genres.length > 0
                    ? _c("v-autocomplete", {
                        attrs: {
                          items: _vm.localMemory.genres,
                          label: "Genres",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          chips: "",
                          clearable: "",
                          color: "deep-purple darken-4",
                          "item-color": "deep-purple darken-1",
                          "append-icon": "mdi-music",
                          hint: "Genres",
                          "persistent-hint": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.createTopicObject()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "mt-2 white--text",
                                      attrs: { color: "deep-purple darken-4" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", color: "white" } },
                                        [_vm._v(" mdi-music ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formattedData(data.item, 10)
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  typeof data.item !== "object"
                                    ? [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "deep-purple darken-4",
                                                },
                                              },
                                              [_vm._v(" mdi-music ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-content", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.formattedData(data.item, 15)
                                            ),
                                          },
                                        }),
                                      ]
                                    : [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "white",
                                                },
                                              },
                                              [_vm._v(" mdi-music ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(data.item),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1378012497
                        ),
                        model: {
                          value: _vm.genres,
                          callback: function ($$v) {
                            _vm.genres = $$v
                          },
                          expression: "genres",
                        },
                      })
                    : _c("v-text-field", {
                        attrs: {
                          label: "Genres",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          chips: "",
                          clearable: "",
                          color: "deep-purple darken-4",
                          "item-color": "deep-purple darken-1",
                          "append-icon": "mdi-music",
                          hint: "Genres",
                          "persistent-hint": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.createTopicObject()
                          },
                        },
                        model: {
                          value: _vm.genres,
                          callback: function ($$v) {
                            _vm.genres = $$v
                          },
                          expression: "genres",
                        },
                      }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.sources,
                      label: "Awards",
                      "append-icon": "mdi-semantic-web",
                      rounded: "",
                      dense: "",
                      disabled: "",
                      "allow-overflow": "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      outlined: "",
                      chips: "",
                      "item-text": "name",
                      hint: "Awards or Streamer Service",
                      "item-value": "name",
                      "persistent-hint": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.createTopicObject()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "mt-2 white--text",
                                attrs: { color: "deep-purple darken-4" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { left: "", color: "white" } },
                                  [_vm._v(" " + _vm._s(data.item.icon) + " ")]
                                ),
                                _vm._v(" " + _vm._s(data.item.name) + " "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            typeof data.item !== "object"
                              ? [
                                  _c("v-list-item-content", {
                                    domProps: {
                                      textContent: _vm._s(data.item),
                                    },
                                  }),
                                ]
                              : [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            left: "",
                                            color: data.item.color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.icon) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.name),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.artist),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.origin,
                      callback: function ($$v) {
                        _vm.origin = $$v
                      },
                      expression: "origin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.targets,
                      label: "Targets",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      hint: "Stream platform",
                      "append-icon": "mdi-bullseye-arrow",
                      chips: "",
                      "item-text": "name",
                      "item-value": "api",
                      "persistent-hint": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.createTopicObject()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "mt-2 white--text",
                                attrs: { color: "deep-purple darken-4" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { left: "", color: "white" } },
                                  [_vm._v(" " + _vm._s(data.item.icon) + " ")]
                                ),
                                _vm._v(" " + _vm._s(data.item.name) + " "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            typeof data.item !== "object"
                              ? [
                                  _c("v-list-item-content", {
                                    domProps: {
                                      textContent: _vm._s(data.item),
                                    },
                                  }),
                                ]
                              : [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            left: "",
                                            color: data.item.color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.icon) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.name),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.target,
                      callback: function ($$v) {
                        _vm.target = $$v
                      },
                      expression: "target",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "From",
                      hint: "Lowest songs position",
                      "persistent-hint": "",
                      "append-icon": "mdi-numeric-1-circle",
                      rounded: "",
                      dense: "",
                      chips: "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      outlined: "",
                      rules: [_vm.rules.number, _vm.rules.max, _vm.rules.min],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.createTopicObject()
                      },
                    },
                    model: {
                      value: _vm.lowestPosition,
                      callback: function ($$v) {
                        _vm.lowestPosition = $$v
                      },
                      expression: "lowestPosition",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "To",
                      hint: "Highest song Position",
                      "persistent-hint": "",
                      rounded: "",
                      dense: "",
                      chips: "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      "append-icon": "mdi-numeric-9-circle",
                      outlined: "",
                      rules: [_vm.rules.number, _vm.rules.max, _vm.rules.min],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.createTopicObject()
                      },
                    },
                    model: {
                      value: _vm.highestPosition,
                      callback: function ($$v) {
                        _vm.highestPosition = $$v
                      },
                      expression: "highestPosition",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-combobox", {
                    attrs: {
                      label: "Items per page",
                      items: [5, 10, 15, 20, 30],
                      rules: [_vm.rules.maxItemPerPage],
                      outlined: "",
                      rounded: "",
                      dense: "",
                      clearable: "",
                      color: "deep-purple darken-4",
                      "item-color": "deep-purple darken-1",
                      hint: "Amount of items per page",
                      "persistent-hint": "",
                      "append-icon": "mdi-bullseye-arrow ",
                      chips: "",
                    },
                    on: {
                      change: function ($event) {
                        _vm.itemsPerPage
                          ? _vm.setItemsPerPage(_vm.itemsPerPage)
                          : _vm.setItemsPerPage(20)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "mt-2 white--text",
                                attrs: { color: "deep-purple darken-4" },
                              },
                              [_vm._v(" " + _vm._s(data.item) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            typeof data.item !== "object"
                              ? [
                                  _c("v-list-item-content", {
                                    domProps: {
                                      textContent: _vm._s(data.item),
                                    },
                                  }),
                                ]
                              : [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.itemsPerPage,
                      callback: function ($$v) {
                        _vm.itemsPerPage = $$v
                      },
                      expression: "itemsPerPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu1",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-overflow": "",
                        rounded: "",
                        dense: "",
                        chips: "",
                        outline: "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mt-0 pt-0",
                                      attrs: {
                                        label: "From:",
                                        outlined: "",
                                        rounded: "",
                                        dense: "",
                                        chips: "",
                                        clearable: "",
                                        color: "deep-purple darken-4",
                                        "item-color": "deep-purple darken-1",
                                        hint: "Date range start",
                                        "persistent-hint": "",
                                        "append-icon": "mdi-calendar",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          _vm.startDate = _vm.parseDate(
                                            _vm.dateFormatted
                                          )
                                        },
                                        change: function ($event) {
                                          return _vm.createTopicObject()
                                        },
                                      },
                                      model: {
                                        value: _vm.dateFormatted,
                                        callback: function ($$v) {
                                          _vm.dateFormatted = $$v
                                        },
                                        expression: "dateFormatted",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu1,
                        callback: function ($$v) {
                          _vm.menu1 = $$v
                        },
                        expression: "menu1",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { "no-title": "", outline: "", "show-week": "" },
                        on: {
                          input: function ($event) {
                            _vm.menu1 = false
                          },
                        },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-overflow": "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mt-0 pt-0",
                                      attrs: {
                                        label: "To:",
                                        hint: "Date Range End",
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        chips: "",
                                        rounded: "",
                                        color: "deep-purple darken-4",
                                        "item-color": "deep-purple darken-1",
                                        "append-icon": "mdi-calendar",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          _vm.endDate = _vm.parseDate(
                                            _vm.computedDateFormatted
                                          )
                                        },
                                        change: function ($event) {
                                          return _vm.createTopicObject()
                                        },
                                      },
                                      model: {
                                        value: _vm.computedDateFormatted,
                                        callback: function ($$v) {
                                          _vm.computedDateFormatted = $$v
                                        },
                                        expression: "computedDateFormatted",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu2,
                        callback: function ($$v) {
                          _vm.menu2 = $$v
                        },
                        expression: "menu2",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { "no-title": "" },
                        on: {
                          input: function ($event) {
                            _vm.menu2 = false
                          },
                        },
                        model: {
                          value: _vm.endDate,
                          callback: function ($$v) {
                            _vm.endDate = $$v
                          },
                          expression: "endDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12", md: "4" } }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "mt-4 mb-2",
                  staticStyle: { "text-align": "center" },
                  attrs: { cols: "12" },
                },
                [
                  _c("v-divider", { staticClass: "mb-5" }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "deep-purple darken-4 mx-2",
                      attrs: {
                        rounded: "",
                        elevation: "15",
                        "data-google": "applyFilterBtn",
                        "aria-label": "Apply filter",
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "white" } },
                        [_vm._v("mdi-filter-variant-plus")]
                      ),
                      _c(
                        "span",
                        { staticClass: "white--text text-capitalize" },
                        [_vm._v(" Apply Filters")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }