<template>
  <div class="menu-global">
    <v-list class="v-space">
      <v-list-item class="px-2 pt-1">
        <v-list-item-icon>
          <v-btn
            class="mx-1"
            icon
            text
            aria-label="show/hide sidePanel"
            @click="$emit('update:permanent')"
          >
            <v-icon color="deep-purple darken-4">{{
              panelStatus ? "mdi-backburger " : "mdi-forwardburger "
            }}</v-icon></v-btn
          >
        </v-list-item-icon>
        <v-list-item-content
          ><a href="/"><h1 class="logo-text-color">Zunzun</h1></a>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list class="v-space" rounded>
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="(item, i) in generalActive"
          :key="i"
          :value="item.url"
          class="pl-10"
          @click="goTo(item.url)"
        >
          <v-list-item-icon>
            <v-icon color="primary" v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-list class="v-space button" rounded>
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="(item, i) in settingsActive"
          :key="i"
          class="pl-10"
          :value="item.url"
          @click="goTo(item.url)"
        >
          <v-list-item-icon>
            <v-icon color="primary" v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
export default {
  props: {
    panelStatus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    generalSelectedItem: 0,
    selectedItem: "",
    general: [
      {
        text: "Home",
        icon: "mdi-home",
        url: "Home",
        menu: "general",
        active: true,
      },
      {
        text: "Search",
        icon: "mdi-magnify",
        url: "Search",
        menu: "general",
        active: true,
      },
      {
        text: "Suggestions",
        icon: "mdi-arrow-decision-auto",
        url: "Suggestions",
        menu: "general",
        active: true,
      },
      {
        text: "Top Ten",
        icon: "mdi-timer-10 ",
        url: "TopTen",
        menu: "general",
        active: false,
      },
      {
        text: "Recommended",
        icon: "mdi-account-heart-outline ",
        url: "Recommended",
        menu: "general",
        active: false,
      },
      {
        text: "Privacy Policy",
        icon: "mdi-shield-account-outline",
        url: "Policy",
        menu: "general",
        active: true,
      },
      {
        text: "FAQ",
        icon: "mdi-account-question ",
        url: "Help",
        menu: "general",
        active: false,
      },
      {
        text: "About us",
        icon: "mdi-account-question ",
        url: "About",
        menu: "general",
        active: true,
      },
      {
        text: "Contact",
        icon: "mdi-account-box-outline",
        url: "Contact",
        menu: "general",
        active: true,
      },
    ],
    settings: [
      {
        text: "Configuration",
        icon: "mdi-cog-outline",
        url: "Configurations",
        menu: "settings",
        active: false,
      },
      {
        text: "Logout",
        icon: "mdi-logout-variant ",
        url: "Logout",
        menu: "settings",
        active: false,
      },
    ],
  }),
  computed: {
    generalActive() {
      return this.general.filter((item) => item.active);
    },
    settingsActive() {
      return this.settings.filter((item) => item.active);
    },
  },
  mounted() {
    const { name } = this.$route;
    this.selectedItem = name;
  },
  methods: {
    goTo(name, menu = "") {
      this.$router.push({ name, params: { name, menu } });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: inherit;
}

.v-list-item-group .v-list-item--active {
  color: inherit;
  border-radius: 0 0 100% 100¤;
  background: linear-gradient(90deg, #311b9205 0%, rgb(245 245 245 / 22%) 100%);
}
.logo-text-color {
  letter-spacing: normal !important;
  font-size: 2rem;
  font-weight: 900;
  color: #311b92;
}
.menu-global {
  min-width: 100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  .v-space {
    height: auto;
    &.button {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: flex-end;
      justify-content: flex-end;
      align-items: stretch;
    }
  }
}
</style>
