var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-global" },
    [
      _c(
        "v-list",
        { staticClass: "v-space" },
        [
          _c(
            "v-list-item",
            { staticClass: "px-2 pt-1" },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        icon: "",
                        text: "",
                        "aria-label": "show/hide sidePanel",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:permanent")
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "deep-purple darken-4" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.panelStatus
                                ? "mdi-backburger "
                                : "mdi-forwardburger "
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-list-item-content", [
                _c("a", { attrs: { href: "/" } }, [
                  _c("h1", { staticClass: "logo-text-color" }, [
                    _vm._v("Zunzun"),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "v-space", attrs: { rounded: "" } },
        [
          _c(
            "v-list-item-group",
            {
              model: {
                value: _vm.selectedItem,
                callback: function ($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem",
              },
            },
            _vm._l(_vm.generalActive, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  staticClass: "pl-10",
                  attrs: { value: item.url },
                  on: {
                    click: function ($event) {
                      return _vm.goTo(item.url)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        attrs: { color: "primary" },
                        domProps: { textContent: _vm._s(item.icon) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(item.text) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "v-space button", attrs: { rounded: "" } },
        [
          _c(
            "v-list-item-group",
            {
              model: {
                value: _vm.selectedItem,
                callback: function ($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem",
              },
            },
            _vm._l(_vm.settingsActive, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  staticClass: "pl-10",
                  attrs: { value: item.url },
                  on: {
                    click: function ($event) {
                      return _vm.goTo(item.url)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        attrs: { color: "primary" },
                        domProps: { textContent: _vm._s(item.icon) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(item.text) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }