var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "infoContainer deepPurpleBackground px-4" },
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex justify-center",
          attrs: { cols: "12", xl: "1", lg: "1", md: "1", sm: "12" },
        },
        [
          _c(
            "v-icon",
            { staticClass: "ma-6", attrs: { size: "64", color: "primary" } },
            [_vm._v("mdi-information-outline ")]
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", xl: "11", lg: "11", md: "11", sm: "12" } },
        [
          _c("ul", { staticClass: "helpList" }, [
            _c(
              "li",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: { medium: "", color: "primary" },
                  },
                  [_vm._v("mdi-magnify")]
                ),
                _c("b", [_vm._v("Search By: ")]),
                _vm._v(
                  "You can search by types(title, Artist...), using any combination of topics "
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: { medium: "", color: "primary" },
                  },
                  [_vm._v("mdi-arrow-decision-auto")]
                ),
                _c("b", [_vm._v("Suggestions: ")]),
                _vm._v(
                  "we ty to autocomplete you search topics looking in our database... "
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: { medium: "", color: "primary" },
                  },
                  [_vm._v("mdi-filter-variant-plus")]
                ),
                _c("b", [_vm._v("Advancer Search:")]),
                _vm._v(
                  " the advanced search is available for more specific or complex search. "
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }