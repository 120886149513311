import Vue from "vue";
import VueRouter from "vue-router";

import {
  Home,
  Login,
  About,
  Search,
  Help,
  Contact,
  Policy,
  Configurations,
  NotFound,
  Recommended,
  TopTen,
  Suggestions,
} from "./components.lazyLoaded";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/callback",
    name: "Callback",
    component: Login,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/top-ten",
    name: "TopTen",
    component: TopTen,
  },
  {
    path: "/recommended",
    name: "Recommended",
    component: Recommended,
  },
  {
    path: "/configurations",
    name: "Configurations",
    component: Configurations,
  },
  {
    path: "/suggestions-system",
    name: "Suggestions",
    component: Suggestions,
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
