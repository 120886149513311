/* eslint-disable vue/no-unused-vars */
<template>
  <div :key="getRefreshLayout" ref="searchRef">
    <v-row justify="space-around">
      <v-menu
        v-model="showMenu"
        offset-y
        transition="slide-y-transition"
        bottom
        origin="top center"
        elevation="0"
        allow-overflow
        nudge-top="26"
        rounded="b-xl"
        class="primary"
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="searchTopic"
            placeholder="Introduce a search topic"
            data-cypress="search-input"
            v-bind="attrs"
            solo
            :elevation="showMenu ? 0 : 5"
            class="searchInput"
            clearable
            autofocus
            :flat="!showMenu"
            :background-color="!showMenu ? 'grey lighten-3' : 'white'"
            v-on="on"
          >
            <template #prepend-inner>
              <v-btn
                text
                icon
                aria-label="Advancer Search"
                class="mr-3"
                @click="showSearchAdvancer()"
              >
                <v-icon
                  v-if="layout !== 'home'"
                  color="primary"
                  data-google="searchAdvancerBtn"
                  small
                  >mdi-filter-variant-plus</v-icon
                >
              </v-btn>
            </template>
            <template #append>
              <v-fade-transition v-show="searchTopic" hide-on-leave>
                <v-progress-circular
                  v-if="getLoading"
                  size="24"
                  color="info"
                  aria-progressbar="search loader"
                  indeterminate
                ></v-progress-circular>
                <v-btn
                  v-else-if="!searchTopic && hasTopicsActive"
                  icon
                  class="deep-purple darken-4 px-2"
                  elevation="0"
                  data-google="searchBtn"
                  aria-label="search"
                  @click="getInfo()"
                >
                  <v-icon medium color="white">mdi-magnify</v-icon>
                </v-btn>
              </v-fade-transition>
            </template>
          </v-text-field>
        </template>
        <template #default>
          <div class="contentContainer" :style="{ width: dynamicalWidth }">
            <template v-if="showInfoAlert">
              <suggestion-menu-help />
            </template>
            <v-row class="searchContainer">
              <v-col cols="12" class="searchBy">
                <div class="deepPurpleBackground">
                  <v-subheader class="black--text"
                    ><span class="primaryText subtitle-1">
                      <v-icon medium class="mr-1" color="primary"
                        >mdi-magnify</v-icon
                      >
                      Search by:</span
                    >
                  </v-subheader>
                  <v-divider class="mb-2 primary" style="opacity: 0.22" />
                  <div
                    v-for="(ele, key) in searchVisibleSelector"
                    :key="key"
                    class="px-4 py-1"
                  >
                    <v-chip
                      v-show="validateType(searchTopic, ele)"
                      elevation="10"
                      color="#311b92"
                      class="ma-1 white--text suggestionsTopic"
                      @click="changeSelector(ele)"
                    >
                      <span class="pr-2"
                        ><v-icon class="ma-1">{{ ele.icons }}</v-icon
                        >{{ ele.name }} :</span
                      >
                      <i>{{ searchTopic }}</i>
                    </v-chip>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="suggestions">
                <search-suggestions
                  class="deepPurpleBackground"
                  :search-topic="searchTopic"
                  @update-topics="updateSelectedTopics"
                />
              </v-col>
              <v-col cols="12" class="advancerSearchContainer">
                <v-btn
                  class="deep-purple darken-4"
                  rounded
                  outlined
                  aria-label="advancer search"
                  @click="showSearchAdvancer()"
                  ><b class="white--text subtitle-2"
                    ><v-icon class="mr-2" size="24"
                      >mdi-filter-variant-plus</v-icon
                    >Use Advancer Search
                  </b></v-btn
                >
                <v-spacer></v-spacer>
                <v-btn v-show="!showInfoAlert" class="primary mx-1" icon small
                  ><v-icon small color="white" @click="showInfoAlert = true"
                    >mdi-help</v-icon
                  ></v-btn
                >
                <v-btn class="primary ms-1" icon small
                  ><v-icon small color="white" @click="showMenu = false"
                    >mdi-close</v-icon
                  ></v-btn
                >
              </v-col>
            </v-row>
          </div>
        </template>
      </v-menu>
    </v-row>
    <v-row v-if="showTopics && hasTopicsActive" style="margin-top: -40px">
      <v-col cols="12" class="d-flex mt-3 mb-0 pb-0">
        <topics v-show="showTopicBar" @re-search="getInfo" />
        <span v-show="!showTopicBar" class="deepPurple"
          ><b>Search topics :</b> {{ topicsActiveAmount }} actives</span
        >
        <v-spacer />
        <v-btn
          icon
          text
          small
          class="only-desktop grey lighten-4"
          elevation="0"
          @click="showTopicBar = !showTopicBar"
        >
          <v-icon small color="primary">{{
            showTopicBar ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import topics from "@/components/search/searches/topics";
import { localStorageHandler } from "@/helpers";
import searchSuggestions from "@/components/search/suggestions/searchSuggestion";
import suggestionMenuHelp from "@/components/search/suggestions/suggestionMenuHelp";

export default {
  name: "BillboardSearch",
  components: { topics, searchSuggestions, suggestionMenuHelp },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: "home",
    },
    showTopics: {
      type: Boolean,
      default: true,
    },
    changeLayout: {
      type: Boolean,
      default: false,
    },
    reFetchHits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromUrl: "",
      showMenu: false,
      searchTopic: null,
      searchSelector: [
        {
          id: 0,
          name: "Title",
          apiTarget: "title",
          type: "String",
          visible: true,
          icons: "mdi-format-title",
        },
        {
          id: 1,
          name: "Artist",
          apiTarget: "artist",
          type: "String",
          visible: true,
          valuesArray: [],
          icons: "mdi-account-music",
        },
        {
          id: 2,
          name: "Genres",
          apiTarget: "genres",
          type: "String",
          visible: true,
          icons: "mdi-music",
        },
        {
          id: 3,
          name: "Producer by",
          apiTarget: "label",
          type: "String",
          visible: true,
          icons: "mdi-home-variant-outline",
        },

        {
          id: 4,
          name: "From Position",
          apiTarget: "lowestPosition",
          type: "Integer",
          visible: false,
          icons: "mdi-numeric-1-circle",
        },
        {
          id: 5,
          name: "Highest Position",
          apiTarget: "highestPosition",
          type: "Integer",
          visible: true,
          icons: "mdi-numeric-9-circle",
        },
        {
          id: 6,
          name: "End Date",
          apiTarget: "endDate",
          type: "String",
          visible: false,
          icons: "mdi-calendar",
        },
        {
          id: 7,
          name: "Start Date",
          apiTarget: "startDate",
          type: "String",
          visible: false,
          icons: "mdi-calendar",
        },
        {
          id: 8,
          name: "Target",
          apiTarget: "target",
          type: "String",
          visible: false,
          icons: "mdi-bullseye-arrow",
        },
        {
          id: 9,
          name: "Source Origin",
          apiTarget: "origin",
          type: "String",
          visible: false,
          icons: "mdi-semantic-web",
        },
        {
          id: 10,
          name: "Days from update",
          apiTarget: "daysSinceUpdated",
          type: "Integer",
          visible: false,
          icons: "mdi-semantic-web",
        },
      ],
      selected: [],
      selectors: [],
      showAlert: false,
      menu: false,
      menuFilter: false,
      showAdvancerSearch: false,
      showInfoAlert: false,
      dynamicalWidth: "100%",
      showTopicBar: true,
    };
  },
  computed: {
    ...mapGetters([
      "getSearchTopics",
      "getRefreshLayout",
      "getBillboardItems",
      "getLoading",
      "getAudioObject",
      "getReload",
      "getActualPage",
      "getItemsPerPage",
    ]),
    hasTopicsActive() {
      return Object.keys(this.getSearchTopics).length > 0;
    },
    topicsActiveAmount() {
      return Object.keys(this.getSearchTopics).length;
    },
    searchVisibleSelector() {
      return this.searchSelector.filter((selector) => selector.visible);
    },
  },
  watch: {
    getSearchTopics(newTopics) {
      this.selected = newTopics.map(({ id }) => id);
      this.changeUrl();
    },
    searchTopic(newValue) {
      if (newValue) {
        this.showMenu = true;
      }
    },
    reFetchHits(fetchHits) {
      if (fetchHits) {
        this.changeUrl();
        this.getInfo();
      }
    },
    getItemsPerPage() {
      this.changeUrl();
    },
    getActualPage(actualPage) {
      this.changeUrl();
      const pageItems = this.getBillboardItems.find(
        (ele) => ele.page === actualPage
      );
      if (!pageItems) {
        this.setLoading(true);
        if (this.hasTopicsActive) {
          this.resetSearchTotalItems();
          const init = {
            actualPage: +this.getActualPage,
            itemsPerPage: +this.getItemsPerPage,
          };
          this.postBillboard(init);
          if (this.$router.currentRoute.name !== "Search") {
            this.$router.push("search");
          }
        } else {
          this.setLoading(false);
        }
      }
    },
  },
  beforeDestroy() {
    if (this.$router.currentRoute.name !== "Search") {
      this.setSearchTopics([]);
      this.resetBillboardItems();
    }
  },
  mounted() {
    this.updateDynamicalWidth();
    this.showInfoAlert =
      localStorageHandler.getLocalStore("showInfoAlert") !== "false";
    if (this.$router.currentRoute.name === "Search") {
      this.autoSearch();
    }
  },
  updated() {
    this.updateDynamicalWidth();
    window.onscroll = () => {
      this.showMenu = false;
    };
  },
  methods: {
    ...mapActions(["resetSearchTotalItems", "postBillboard"]),
    ...mapMutations([
      "setSearchTopics",
      "setRefreshLayout",
      "setLoading",
      "resetBillboardItems",
      "setItemsPerPage",
      "setActualPage",
      "setPlayListUpdate",
    ]),
    changeSelector(item) {
      if (!this.searchTopic) return;
      const value =
        item.apiTarget === "position"
          ? parseInt(this.searchTopic, 10)
          : this.searchTopic;
      this.setSearchTopics([
        ...new Set([...this.getSearchTopics, { ...item, value }]),
      ]);
      this.afterSearch();
    },
    updateSelectedTopics({ type, value, imageUrl }) {
      this.showMenu = false;
      let topicTarget = this.searchSelector.filter(
        ({ apiTarget }) => apiTarget === type
      );
      const topicList = this.getSearchTopics.filter(
        ({ apiTarget }) => apiTarget !== type
      );
      topicTarget = { ...topicTarget[0], value, imageUrl };
      this.setSearchTopics([...topicList, topicTarget]);
      this.afterSearch();
    },
    afterSearch() {
      this.searchTopic = null;
      this.showMenu = false;
      this.setRefreshLayout();
      this.changeUrl();
      if (this.$router.currentRoute.name === "Search") this.getInfo();
      if (this.showInfoAlert) this.hideInfoAlert();
    },
    changeUrl() {
      if (this.$router.currentRoute.name !== "Search") {
        return false;
      }
      const topicList = [...this.getSearchTopics];
      /* */
      this.searchSelector.forEach((element) => {
        const target = element.apiTarget.trim().replaceAll(/\s/g, "_");
        this.cleanUrl(target);
      });
      this.cleanUrl("itemsPerPage");
      this.cleanUrl("actualPage");
      /*  */
      const url = new URL(window.location.href);
      const params = url.searchParams;
      topicList.map((ele) => {
        let targetValue = ele.value;
        if (ele.type === "String") {
          targetValue = ele.value.trim().replaceAll(/\s/g, "_");
        }
        const target = ele.apiTarget.trim().replaceAll(/\s/g, "_");
        if (params.get(target)) {
          params.set(target, targetValue);
        } else {
          params.append(target, targetValue);
        }
        return ele;
      });
      params.append("itemsPerPage", this.getItemsPerPage);
      params.append("actualPage", this.getActualPage);
      url.search = params.toString();
      const newURL = url.toString();
      window.history.pushState({ path: encodeURI(newURL) }, "", url);
      return url.search;
    },
    cleanUrl(searchItem) {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      if (params.get(searchItem)) {
        params.delete(searchItem);
        url.search = params.toString();
        const newURL = url.toString();
        window.history.pushState({ path: newURL }, "", url);
      }
    },
    autoSearch() {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      const searchParameters = [];
      if (params.get("itemsPerPage")) {
        const itemsPerPage = params.get("itemsPerPage").toString();
        const validateItemsPerPage = +itemsPerPage > 50 ? 20 : +itemsPerPage;
        this.setItemsPerPage(validateItemsPerPage);
      } else {
        params.append("itemsPerPage", this.getItemsPerPage);
      }

      if (params.get("actualPage")) {
        const actualPage = params.get("actualPage").toString();
        this.setActualPage(+actualPage);
      } else {
        params.append("actualPage", this.getActualPage);
      }

      this.searchSelector.forEach((ele) => {
        const type = ele.apiTarget.trim().replaceAll(/\s/g, "_");
        const value = params.get(type)
          ? params.get(type).trim().replaceAll("_", " ")
          : null;
        if (value !== null) {
          const newElement = { ...ele };
          newElement.value = decodeURI(value);
          if (newElement.valuesArray) {
            newElement.valuesArray = value.split(",");
          }
          searchParameters.push(newElement);
        }
      });
      if (searchParameters.length >= 1) {
        this.setSearchTopics(searchParameters.reverse());
        this.getItems();
      }
    },
    getInfo() {
      this.getItems();
      this.setActualPage(1);
      this.$emit("restore-pagination");
      return true;
    },
    getItems() {
      this.resetBillboardItems();
      this.setLoading(true);
      if (this.hasTopicsActive) {
        this.resetSearchTotalItems();
        const init = {
          actualPage: +this.getActualPage,
          itemsPerPage: +this.getItemsPerPage,
        };
        this.postBillboard(init);
        if (this.$router.currentRoute.name !== "Search") {
          const query = {};
          Object.values(this.getSearchTopics).forEach((value) => {
            query[value.apiTarget] = value.value;
          });
          this.$router.push({
            path: "search",
            query,
          });
        }
      } else {
        this.setLoading(false);
      }
      return true;
    },
    resetTopics() {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      this.getSearchTopics.forEach((ele) => {
        params.delete(ele.apiTarget.trim().replaceAll(/\s/g, "_"));
      });
      url.search = params.toString();
      const newURL = url.toString();
      window.history.pushState({ path: newURL }, "", url);
      this.setSearchTopics([]);
      this.setRefreshLayout();
    },
    showSearchAdvancer() {
      this.$emit("show-advancer-search", true);
      if (this.showInfoAlert) {
        this.hideInfoAlert();
      }
    },
    validateType(searchTopic, item) {
      if (searchTopic === null) {
        return true;
      }
      // eslint-disable-next-line eqeqeq
      if (item.type === "Integer" && Number(searchTopic) == searchTopic) {
        return true;
      }
      const type = typeof searchTopic;
      if (item.type === "String" && type === "string") {
        return true;
      }
      return false;
    },
    hideInfoAlert() {
      this.showInfoAlert = false;
      localStorageHandler.setLocalStore("showInfoAlert", false);
    },
    updateDynamicalWidth() {
      this.$nextTick(() => {
        this.$refs.searchRef.querySelectorAll(".searchInput").forEach((ele) => {
          this.dynamicalWidth = `${ele.offsetWidth}px`;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contentContainer {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: saturate(180%) blur(0.8rem) !important;
  backdrop-filter: saturate(180%) blur(0.8rem) !important;
}
.v-menu__content {
  border-radius: 20px !important;
}

.adSearch {
  margin: 0;
  display: block;
  margin-top: -23px;
  margin-left: 6px;
  color: #311b92;
  background: #f5f5f5;
  font-size: 13px;
  cursor: pointer;
  text-transform: unset;
}
.adSearch:hover {
  color: #311b92;
  background: #f5f5f5;
}
.title_flex {
  display: flex;
  justify-content: space-between;
}
.search_order {
  display: flex;
  .search_icon {
    margin: 8px 0 0 16px;
    font-size: 25px;
    cursor: pointer;
  }
}
.extra {
  background: linear-gradient(180deg, #161414, #f2f2f2);
  box-shadow: 7px 7px 10px #ebebeb, -7px -7px 10px #ffffff;
  border-radius: 30px;
}
.search_parameters {
  display: block;
  text-align: left;
  background: #fff;
  margin: -30px 0px 0 20px;
  border-radius: 4px 12px 12px 12px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
  width: 100%;
  z-index: 1000;
  color: #1b1b1b;
  p {
    padding: 20px 25px;
    margin: 0;
  }
  p:first-child {
    margin-top: 15px;
  }
  p:hover {
    background: #f3f3f3;
    border-radius: 0;
  }
  p:last-child {
    border-radius: 0 0 12px 12px;
  }

  @media only screen and (min-width: 650px) {
    max-width: 550px;
  }

  @media only screen and (max-width: 640px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 540px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 360px) {
    max-width: 202px;
  }
  @media only screen and (max-width: 360px) {
    max-width: 150px;
  }
}
.search_layout {
  display: block;
  text-align: left;
  background: #fff;
  margin: -30px 0px 0 20px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0 1px 6px rgb(32 33 36 / 18%);
  border-color: rgba(223, 225, 229, 0);
  width: 100%;
  z-index: 1000;
  color: #1b1b1b;
  border-top: 0;
  p {
    padding: 20px 25px;
    margin: 0;
  }
  p:first-child {
    margin-top: 15px;
  }
  p:hover {
    background: #f3f3f3;
    border-radius: 0;
  }
  p:last-child {
    border-radius: 0 0 12px 12px;
  }

  @media only screen and (min-width: 650px) {
    max-width: 550px;
  }

  @media only screen and (max-width: 640px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 540px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 360px) {
    max-width: 202px;
  }
  @media only screen and (max-width: 360px) {
    max-width: 150px;
  }
}
.searchInput {
  border-radius: 30px;
  width: 100%;
  color: #212121;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  transition: 0.3s;
  margin-top: -3px;
}
.searchInputActive {
  border-radius: 5px 5px 0px 0px !important;
  transition: 0.3s;
}
.menuSearchInputActive {
  border-radius: 0px 0px 50px 50px;
  transition: 0.3s;
}
#inspire > div.v-menu__content.theme--light {
  border-radius: 0px !important;
  background: transparent !important;
}
.v-menu__content {
  border-radius: 0;
  background: transparent;
}
.radius {
  border-radius: 30px;
}
.types,
.topic {
  text-align: left;
  margin-top: -17px;
  margin-bottom: 0px;
  .topic_names {
    max-width: 128px;
  }
  div.bubbles {
    padding: 10px 0px 10px 20px;
    background: #efefef54;
    border-radius: 30px;
    width: fit-content;
    display: inline-block;
    margin: 15px 2px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }
  .types_text {
    margin-right: 15px;
    font-size: 12px;
  }
  .close {
    background: #1976d2;
    color: #fff;
    padding: 9px 15px;
    border-radius: 100%;
    text-align: right;
    height: auto;
    cursor: pointer;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }
  @media only screen and (max-width: 540px) {
    div {
      display: inherit;
    }
  }
}
.topic {
  div {
    display: table;
  }
}

.close {
  background: #1976d2;
  color: #fff;
  padding: 9px 15px;
  border-radius: 100%;
  text-align: right;
  height: auto;
  cursor: pointer;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
}
.searchContainer {
  margin: 0 !important;
  .searchBy {
    > div {
      border-radius: 20px;
    }
  }
  .suggestions {
    > div {
      border-radius: 20px;
    }
  }
}
.advancerSearchContainer {
  display: flex;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}
.suggestionsElements {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
}
.infoAlert {
  color: #212121;
  border: 1px solid #efefef;
  background-color: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: saturate(180%) blur(0.8rem) !important;
  backdrop-filter: saturate(180%) blur(0.8rem) !important;
}

@media (max-width: 1025px) {
  .only-desktop {
    display: none;
  }
}
</style>
