<template>
  <v-row class="infoContainer deepPurpleBackground px-4">
    <v-col cols="12" xl="1" lg="1" md="1" sm="12" class="d-flex justify-center">
      <v-icon class="ma-6" size="64" color="primary"
        >mdi-information-outline
      </v-icon>
    </v-col>
    <v-col cols="12" xl="11" lg="11" md="11" sm="12">
      <ul class="helpList">
        <li>
          <v-icon medium color="primary" class="mr-2">mdi-magnify</v-icon>
          <b>Search By: </b>You can search by types(title, Artist...), using any
          combination of topics
        </li>
        <li>
          <v-icon medium color="primary" class="mr-2"
            >mdi-arrow-decision-auto</v-icon
          >
          <b>Suggestions: </b>we ty to autocomplete you search topics looking in
          our database...
        </li>
        <li>
          <v-icon medium color="primary" class="mr-2"
            >mdi-filter-variant-plus</v-icon
          >
          <b>Advancer Search:</b>
          the advanced search is available for more specific or complex search.
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.infoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.helpList {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    padding: 2px 0;
  }
}
</style>
