var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.tracks.previewUrl || _vm.tracks.preview_url
      ? _c("div", [
          _c(
            "div",
            { staticClass: "pointer", on: { click: _vm.play } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    class:
                                      _vm.size > 30 ? "buttonsApp" : "iconsApp",
                                    attrs: {
                                      "data-google": "audioPreviewBtn",
                                      icon: "",
                                      tile: "",
                                      "aria-label": "Play",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.audio.paused,
                                        expression: "!audio.paused",
                                      },
                                    ],
                                    attrs: {
                                      size: parseInt(_vm.size),
                                      color: _vm.color,
                                    },
                                  },
                                  [_vm._v("mdi-pause-circle")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.audio.paused,
                                        expression: "audio.paused",
                                      },
                                    ],
                                    attrs: {
                                      size: parseInt(_vm.size),
                                      color: _vm.color,
                                    },
                                  },
                                  [_vm._v("mdi-play-circle ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2000769027
                  ),
                },
                [
                  _c("div", { key: _vm.getReload }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.audio.paused ? "Play preview" : "Stop preview"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("b", [_vm._v("Song name:")]),
                    _vm._v(" "),
                    _c("i", [_vm._v('"' + _vm._s(_vm.tracks.name) + '"')]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ])
      : _c(
          "div",
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                class:
                                  _vm.size > 30 ? "buttonsApp" : "iconsApp",
                                attrs: {
                                  tile: "",
                                  icon: "",
                                  "aria-label": "No Preview",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  size: parseInt(_vm.size),
                                  color: _vm.color,
                                },
                              },
                              [_vm._v("mdi-cancel ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_c("span", [_vm._v("Sorry, we don't have a preview")])]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }