import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";
import Tracker from "@openreplay/tracker";
import App from "./App";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./assets/scss/style.css";
import { globalEnv } from "./helpers";
import "./registerServiceWorker";

const tracker = new Tracker({
  projectKey: "SQVgjRp3yq7myLu4sjSV",
});
tracker.start();

Vue.use(VueYouTubeEmbed);
Vue.config.productionTip = true;
Vue.config.devtools = true;
Vue.config.performance = true;

Vue.prototype.$globalEnv = globalEnv;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
