var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "primary radio20 pl-btn",
                          attrs: {
                            large: "",
                            disabled: !_vm.playlistHaveItems,
                            "data-google": "CreatePlNow",
                            "aria-label": "Create playlist",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-4",
                          attrs: { left: "", color: "white", size: "32" },
                        },
                        [_vm._v("mdi-account-music")]
                      ),
                      _vm._v(" Create it now!!! "),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex py-3 mr-0 pr-0" },
                [
                  _c(
                    "span",
                    { staticClass: "deepPurple" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "primary", medium: "" },
                        },
                        [_vm._v(" mdi-account-music ")]
                      ),
                      _vm._v(" Create your own Playlist"),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-3 mr-0",
                      attrs: {
                        color: "deep-purple darken-4",
                        rounded: "",
                        elevation: "0",
                        icon: "",
                        plain: "",
                        raised: "",
                        small: "",
                        "data-google": "closeYoutubePlayListWindowBtn",
                        "aria-label": "Close Playlist creator",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { vertical: "", flat: "" },
                      model: {
                        value: _vm.e6,
                        callback: function ($$v) {
                          _vm.e6 = $$v
                        },
                        expression: "e6",
                      },
                    },
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.e6 > 1,
                            step: "1",
                            color: _vm.isLogged ? "primary" : "red",
                          },
                        },
                        [
                          _vm._v(" Select music service. "),
                          _c("small", { staticClass: "mt-1" }, [
                            _vm._v("Chose your favorite music services. "),
                          ]),
                        ]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-5 radio20",
                              attrs: { height: "auto", elevation: "0" },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "grey lighten-5 radio20" },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "pb-4 ma-0 grey lighten-5",
                                      attrs: {
                                        rounded: "",
                                        elevation: "0",
                                        "two-line": "",
                                        dense: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-group",
                                        {
                                          attrs: {
                                            color: "primary",
                                            "active-class": "deep-purple--text",
                                            mandatory: "",
                                          },
                                          model: {
                                            value: _vm.model,
                                            callback: function ($$v) {
                                              _vm.model = $$v
                                            },
                                            expression: "model",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.items,
                                            function (item, index) {
                                              return [
                                                _c("v-list-item", {
                                                  key: index,
                                                  staticClass: "centered py-3",
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var active =
                                                            ref.active
                                                          return [
                                                            _c(
                                                              "v-list-item-icon",
                                                              {
                                                                staticClass:
                                                                  "pr-0 mr-2 centered-actions",
                                                                class:
                                                                  _vm.loggedIcon(
                                                                    item
                                                                  )
                                                                    ? "pt-4"
                                                                    : "pt-7",
                                                              },
                                                              [
                                                                _c("v-icon", {
                                                                  attrs: {
                                                                    color:
                                                                      item.color,
                                                                    size: "35",
                                                                  },
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        item.icon
                                                                      ),
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "centered-actions",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          item.text
                                                                        ),
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  {
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.playListSongsCounter(
                                                                            item.id
                                                                          )
                                                                        ),
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  {
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          item.subtitle
                                                                        ),
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                staticClass:
                                                                  "centered-actions",
                                                              },
                                                              [
                                                                _vm.loggedIcon(
                                                                  item
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      [
                                                                        _vm.loggedIcon(
                                                                          item
                                                                        )
                                                                          ? _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "grey lighten-1",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-lock-open-variant"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-lock-open-alert"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                        !active
                                                                          ? _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "grey lighten-1",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-check-outline"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-check-bold"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      [
                                                                        _c(
                                                                          "oneClickLogin",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icons:
                                                                                  item
                                                                                    .login
                                                                                    .icons,
                                                                                "icon-size":
                                                                                  item
                                                                                    .login
                                                                                    .iconSize,
                                                                                color:
                                                                                  item
                                                                                    .login
                                                                                    .color,
                                                                                "color-icon":
                                                                                  item
                                                                                    .login
                                                                                    .colorIcon,
                                                                                "btn-text":
                                                                                  item
                                                                                    .login
                                                                                    .btnText,
                                                                                service:
                                                                                  item.target,
                                                                                message:
                                                                                  item
                                                                                    .login
                                                                                    .message,
                                                                                size: item
                                                                                  .login
                                                                                  .iconSize,
                                                                                img: item
                                                                                  .login
                                                                                  .img,
                                                                                "btn-text-color":
                                                                                  item
                                                                                    .login
                                                                                    .btnTextColor,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "radio20 px-6",
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.isLogged,
                                        "aria-label": "Next",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 2
                                        },
                                      },
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e6 > 2, step: "2" } },
                        [
                          _vm._v(" Playlist preview. "),
                          _c("small", { staticClass: "mt-1" }, [
                            _vm._v("Take a look of you future playlist "),
                          ]),
                        ]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-5 radio20",
                              attrs: { elevation: "0", height: "auto" },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "grey lighten-5 radio20" },
                                [
                                  _c("previewPlTable", {
                                    attrs: { hits: _vm.playlist },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "radio20 mr-4 px-6",
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        "aria-label": "Previous",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 1
                                        },
                                      },
                                    },
                                    [_vm._v(" Go back ")]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-6 radio20",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 3
                                        },
                                      },
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e6 > 3, step: "3" } },
                        [
                          _vm._v(" Information "),
                          _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              "Required information to create the playlist "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-5 radio20",
                              attrs: { height: "auto", elevation: "0" },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      label: "Name",
                                      hint: "Playlist name (required)",
                                      outlined: "",
                                      "persistent-hint": "",
                                      clearable: "",
                                      rounded: "",
                                      dense: "",
                                      color: "deep-purple darken-4",
                                      "item-color": "deep-purple darken-1",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append-outer",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                color:
                                                                  "deep-purple darken-4",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-information-variant "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              },
                                              [
                                                _vm._v(
                                                  " The playlist should have a name! "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.name,
                                      callback: function ($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name",
                                    },
                                  }),
                                  _c("v-textarea", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      outlined: "",
                                      rounded: "",
                                      label: "Description",
                                      hint: "Playlist description",
                                      "persistent-hint": "",
                                      clearable: "",
                                      color: "deep-purple darken-4",
                                      "item-color": "deep-purple darken-1",
                                      "allow-overflow": "",
                                    },
                                    model: {
                                      value: _vm.desc,
                                      callback: function ($$v) {
                                        _vm.desc = $$v
                                      },
                                      expression: "desc",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "radio20 mr-4 px-6",
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        "aria-label": "Previous",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 2
                                        },
                                      },
                                    },
                                    [_vm._v(" Go back ")]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "radio20 px-6",
                                      attrs: {
                                        disabled: _vm.name === "",
                                        color: "primary",
                                        "aria-label": "Next",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 4
                                        },
                                      },
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.showOptions && !_vm.saveOptions,
                                  expression: "!showOptions && !saveOptions",
                                },
                              ],
                              staticClass: "grey lighten-3 radio20 my-5",
                              attrs: { height: "auto", elevation: "0" },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "grey lighten-5 pa-5radio20" },
                                [
                                  _c("h2", [
                                    _vm._v(
                                      "After save playlist, what should we do?"
                                    ),
                                  ]),
                                  _c(
                                    "small",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", color: "red" } },
                                        [_vm._v("mdi-information-outline")]
                                      ),
                                      _vm._v(
                                        " Note: If you wanna change this action back, go to user configuration!"
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c("v-switch", {
                                    staticClass: "pb-0 mb-0",
                                    attrs: {
                                      color: "deep-purple darken-4",
                                      label:
                                        "Create playlist and keep adding music",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cleanPlayList = !_vm.keepAdding
                                      },
                                    },
                                    model: {
                                      value: _vm.keepAdding,
                                      callback: function ($$v) {
                                        _vm.keepAdding = $$v
                                      },
                                      expression: "keepAdding",
                                    },
                                  }),
                                  _c("v-switch", {
                                    staticClass: "py-0 my-0",
                                    attrs: {
                                      color: "deep-purple darken-4",
                                      label:
                                        "Clean playlist, and create a new one",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.keepAdding = !_vm.cleanPlayList
                                      },
                                    },
                                    model: {
                                      value: _vm.cleanPlayList,
                                      callback: function ($$v) {
                                        _vm.cleanPlayList = $$v
                                      },
                                      expression: "cleanPlayList",
                                    },
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "py-0 my-0",
                                    attrs: {
                                      color: "deep-purple darken-4",
                                      label:
                                        "Save selection for futures actions",
                                    },
                                    model: {
                                      value: _vm.saveOptions,
                                      callback: function ($$v) {
                                        _vm.saveOptions = $$v
                                      },
                                      expression: "saveOptions",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-stepper-step", { attrs: { step: "4" } }, [
                        _vm._v(" Playlist Resume "),
                        _c("small", { staticClass: "mt-1" }, [
                          _vm._v("Playlist Overview before create it "),
                        ]),
                      ]),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-5 radio20",
                              attrs: { height: "auto", elevation: "0" },
                            },
                            [
                              _c("v-card-title", [
                                _c(
                                  "span",
                                  { staticClass: "subtitle-2 deepPurple" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "primary" },
                                      },
                                      [_vm._v("mdi-information")]
                                    ),
                                    _vm._v("Playlist information related. "),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "mr-2" },
                                [
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _c("ul", { staticClass: "ulStyled" }, [
                                    _c(
                                      "li",
                                      { staticClass: "subtitle-2" },
                                      [
                                        _c("b", { staticClass: "mr-2" }, [
                                          _vm._v("Service:"),
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              color: _vm.activeService.color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.activeService.icon)
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          _vm._s(_vm.activeService.text) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("li", { staticClass: "subtitle-2" }, [
                                      _c("b", { staticClass: "mr-2" }, [
                                        _vm._v("Songs:"),
                                      ]),
                                      _vm._v(_vm._s(_vm.playlist.length) + " "),
                                    ]),
                                    _c("li", { staticClass: "subtitle-2" }, [
                                      _c("b", { staticClass: "mr-2" }, [
                                        _vm._v("Name:"),
                                      ]),
                                      _vm._v(" " + _vm._s(_vm.name) + " "),
                                    ]),
                                    _c("li", { staticClass: "subtitle-2" }, [
                                      _c("b", { staticClass: "mr-2" }, [
                                        _vm._v("Description:"),
                                      ]),
                                      _vm._v(_vm._s(_vm.desc) + " "),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "radio20 px-6",
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        "aria-label": "Previous",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 3
                                        },
                                      },
                                    },
                                    [_vm._v(" Go back ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "radio20 px-6",
                      attrs: { text: "", "aria-label": "Cancel" },
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.e6 === 4,
                          expression: "e6 === 4",
                        },
                      ],
                      staticClass: "radio20 px-6",
                      attrs: {
                        color: "primary",
                        "aria-label": "Create Playlist",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.createPlist()
                        },
                      },
                    },
                    [_vm._v(" Finalize ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialogs", {
        attrs: {
          "show-alert": _vm.showAlert,
          header: _vm.alert.header,
          body: _vm.alert.body,
          icon: _vm.alert.icon,
        },
        on: { "close-dialog": _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }