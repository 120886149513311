<template>
  <div :class="blockStyle">
    <div>
      <v-chip
        close
        :close-icon="
          select && editEmotionSelected
            ? 'mdi-comment-processing'
            : 'mdi-comment-edit'
        "
        class="ma-2"
        :color="select ? activeColor : baseColor"
        :text-color="select ? activeColorTextColor : baseColorTextColor"
        @click="editEmotion"
        @click:close="editEmotionSelected = true"
      >
        <v-avatar left>
          <v-icon>
            {{ select ? activeColorIcon : baseColorIcon }}
          </v-icon>
        </v-avatar>

        <span
          :class="select && !editEmotionSelected ? 'mr-7' : 'mr-0'"
          class="capitalize"
        >
          {{ name }}
          <v-badge
            v-if="select && !editEmotionSelected"
            bordered
            :color="select ? activeColor : baseColor"
            :content="sliderValue"
            class="ml-1 mt-3"
          >
          </v-badge>
        </span>
      </v-chip>
    </div>
    <div v-show="editEmotionSelected" class="slider-container">
      <div class="emotionName">
        <v-icon
          class="close-btn"
          :color="activeColor"
          @click="editEmotionSelected = false"
          >mdi-close-circle</v-icon
        >
        <span class="hidden-md-and-down capitalize">{{ name }} :</span>
      </div>
      <v-slider
        v-model="sliderValue"
        max="10"
        min="0"
        :color="activeColor"
        :track-color="baseColor"
        :thumb-color="colors"
        thumb-label="always"
        thumb-size="24"
        @change="updateValue"
      >
        <template #prepend>
          <v-icon :color="baseColor" @click="decreaseValue"> mdi-minus </v-icon>
        </template>

        <template #append>
          <v-icon :color="baseColor" class="mr-2" @click="increaseValue">
            mdi-plus </v-icon
          ><v-spacer></v-spacer>
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, default: "Happiness" },
    value: { type: Number, default: 0 },
    id: { type: Number, default: 1 },
    status: { type: Boolean, default: false },

    baseColor: { type: String, default: "deep-purple  lighten-4" },
    activeColor: { type: String, default: "deep-purple  darken-4" },

    baseColorTextColor: { type: String, default: "deep-purple  darken-4" },
    activeColorTextColor: { type: String, default: "white" },

    activeColorIcon: { type: String, default: "mdi-checkbox-marked-circle" },
    baseColorIcon: {
      type: String,
      default: "mdi-checkbox-marked-circle-outline",
    },
    songId: { type: Object, default: () => {} },
  },
  data() {
    return {
      editEmotionSelected: false,
      select: this.status,
      sliderValue: this.value,
    };
  },
  computed: {
    colors() {
      let color = "deep-purple  darken-2";
      if (this.sliderValue < 4) {
        color = "deep-purple lighten-1";
      } else if (this.sliderValue > 7) {
        color = "deep-purple  darken-4";
      }
      return color;
    },
    songsProperties() {
      return {
        songId: this.songId.globalInfo.id,
        songName: this.songId.globalInfo.title,
      };
    },
    blockStyle() {
      if (
        (this.select && this.editEmotionSelected) ||
        this.editEmotionSelected
      ) {
        return "emotion-container-block";
      }
      return "emotion-container-inline";
    },
  },
  watch: {
    sliderValue(newSliderValue) {
      if (newSliderValue > 0) {
        this.select = true;
      } else {
        this.select = false;
      }
    },
  },
  methods: {
    editEmotion() {
      if (!this.select) {
        this.editEmotionSelected = false;
        this.select = true;
        this.sliderValue = this.value;
      } else {
        this.editEmotionSelected = false;
        this.select = false;
        this.sliderValue = 0;
      }
      this.updateValue();
    },
    updateValue() {
      const emotion = {
        emotionValue: this.sliderValue,
        emotionName: this.name,
        emotionId: this.id,
        emotionStatus: this.select,
        ...this.songsProperties,
      };
      this.$emit("update-emotions", emotion);
    },
    decreaseValue() {
      this.sliderValue -= 1;
      this.updateValue();
    },
    increaseValue() {
      this.sliderValue += 1;
      this.updateValue();
    },
  },
};
</script>

<style lang="scss" scoped>
.emotion-container-inline {
  display: inline-block;
}
.emotion-container-block {
  display: block;
  background: #2800ff0a;
  padding: 6px 0 15px 0;
  border-radius: 20px;
  margin: 10px 10px 10px 0;
}
.slider-container {
  background: linear-gradient(0deg, #f5f5f5, #f2f2f2);
  box-shadow: 2px 4px 10px 3px #a4a4a463, 6px -20px 36px -10px #ffffff;
  padding: 5px 10px;
  position: relative;
  display: flex;
  border-radius: 20px;
  height: 40px;
  margin: 5px 5px;
}
.emotionName {
  font-size: 14px;
  margin-right: 10px;
  margin-top: 5px;
}
.close-btn {
  background: linear-gradient(180deg, #f5f5f5, #f2f2f2);
  box-shadow: 6px 3px 16px 0px #a4a4a44d, -7px -8px 16px 2px #ffffff;
  padding: 1px !important;
  border-radius: 100% !important;
}
</style>
