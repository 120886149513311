var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.getRefreshLayout, ref: "searchRef" },
    [
      _c(
        "v-row",
        { attrs: { justify: "space-around" } },
        [
          _c("v-menu", {
            staticClass: "primary",
            attrs: {
              "offset-y": "",
              transition: "slide-y-transition",
              bottom: "",
              origin: "top center",
              elevation: "0",
              "allow-overflow": "",
              "nudge-top": "26",
              rounded: "b-xl",
              "close-on-content-click": false,
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-text-field",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "searchInput",
                            attrs: {
                              placeholder: "Introduce a search topic",
                              "data-cypress": "search-input",
                              solo: "",
                              elevation: _vm.showMenu ? 0 : 5,
                              clearable: "",
                              autofocus: "",
                              flat: !_vm.showMenu,
                              "background-color": !_vm.showMenu
                                ? "grey lighten-3"
                                : "white",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            "aria-label": "Advancer Search",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showSearchAdvancer()
                                            },
                                          },
                                        },
                                        [
                                          _vm.layout !== "home"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    "data-google":
                                                      "searchAdvancerBtn",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-filter-variant-plus"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-fade-transition",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.searchTopic,
                                              expression: "searchTopic",
                                            },
                                          ],
                                          attrs: { "hide-on-leave": "" },
                                        },
                                        [
                                          _vm.getLoading
                                            ? _c("v-progress-circular", {
                                                attrs: {
                                                  size: "24",
                                                  color: "info",
                                                  "aria-progressbar":
                                                    "search loader",
                                                  indeterminate: "",
                                                },
                                              })
                                            : !_vm.searchTopic &&
                                              _vm.hasTopicsActive
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "deep-purple darken-4 px-2",
                                                  attrs: {
                                                    icon: "",
                                                    elevation: "0",
                                                    "data-google": "searchBtn",
                                                    "aria-label": "search",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getInfo()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        medium: "",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("mdi-magnify")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.searchTopic,
                              callback: function ($$v) {
                                _vm.searchTopic = $$v
                              },
                              expression: "searchTopic",
                            },
                          },
                          "v-text-field",
                          attrs,
                          false
                        ),
                        on
                      )
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "contentContainer",
                        style: { width: _vm.dynamicalWidth },
                      },
                      [
                        _vm.showInfoAlert
                          ? [_c("suggestion-menu-help")]
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "searchContainer" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "searchBy",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "deepPurpleBackground" },
                                  [
                                    _c(
                                      "v-subheader",
                                      { staticClass: "black--text" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "primaryText subtitle-1",
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  medium: "",
                                                  color: "primary",
                                                },
                                              },
                                              [_vm._v("mdi-magnify")]
                                            ),
                                            _vm._v(" Search by:"),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("v-divider", {
                                      staticClass: "mb-2 primary",
                                      staticStyle: { opacity: "0.22" },
                                    }),
                                    _vm._l(
                                      _vm.searchVisibleSelector,
                                      function (ele, key) {
                                        return _c(
                                          "div",
                                          {
                                            key: key,
                                            staticClass: "px-4 py-1",
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.validateType(
                                                      _vm.searchTopic,
                                                      ele
                                                    ),
                                                    expression:
                                                      "validateType(searchTopic, ele)",
                                                  },
                                                ],
                                                staticClass:
                                                  "ma-1 white--text suggestionsTopic",
                                                attrs: {
                                                  elevation: "10",
                                                  color: "#311b92",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeSelector(
                                                      ele
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pr-2" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "ma-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(ele.icons)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      _vm._s(ele.name) + " :"
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("i", [
                                                  _vm._v(
                                                    _vm._s(_vm.searchTopic)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "suggestions",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("search-suggestions", {
                                  staticClass: "deepPurpleBackground",
                                  attrs: { "search-topic": _vm.searchTopic },
                                  on: {
                                    "update-topics": _vm.updateSelectedTopics,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "advancerSearchContainer",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "deep-purple darken-4",
                                    attrs: {
                                      rounded: "",
                                      outlined: "",
                                      "aria-label": "advancer search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showSearchAdvancer()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b",
                                      { staticClass: "white--text subtitle-2" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { size: "24" },
                                          },
                                          [_vm._v("mdi-filter-variant-plus")]
                                        ),
                                        _vm._v("Use Advancer Search "),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.showInfoAlert,
                                        expression: "!showInfoAlert",
                                      },
                                    ],
                                    staticClass: "primary mx-1",
                                    attrs: { icon: "", small: "" },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "", color: "white" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showInfoAlert = true
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-help")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary ms-1",
                                    attrs: { icon: "", small: "" },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "", color: "white" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showMenu = false
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.showMenu,
              callback: function ($$v) {
                _vm.showMenu = $$v
              },
              expression: "showMenu",
            },
          }),
        ],
        1
      ),
      _vm.showTopics && _vm.hasTopicsActive
        ? _c(
            "v-row",
            { staticStyle: { "margin-top": "-40px" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex mt-3 mb-0 pb-0", attrs: { cols: "12" } },
                [
                  _c("topics", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTopicBar,
                        expression: "showTopicBar",
                      },
                    ],
                    on: { "re-search": _vm.getInfo },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showTopicBar,
                          expression: "!showTopicBar",
                        },
                      ],
                      staticClass: "deepPurple",
                    },
                    [
                      _c("b", [_vm._v("Search topics :")]),
                      _vm._v(" " + _vm._s(_vm.topicsActiveAmount) + " actives"),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "only-desktop grey lighten-4",
                      attrs: { icon: "", text: "", small: "", elevation: "0" },
                      on: {
                        click: function ($event) {
                          _vm.showTopicBar = !_vm.showTopicBar
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "primary" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.showTopicBar
                              ? "mdi-chevron-down"
                              : "mdi-chevron-up"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }