<template>
  <div class="">
    <v-subheader class="black--text"
      ><span class="primaryText subtitle-1">
        <v-icon color="primary" medium class="mr-1"
          >mdi-arrow-decision-auto</v-icon
        >
        Suggestions:</span
      >
    </v-subheader>
    <v-divider class="mb-2 primary" style="opacity: 0.22" />
    <div class="suggestionsElements">
      <div v-if="!searchTopic" class="pa-4">
        <v-icon class="mr-2" color="primary">mdi-information-outline </v-icon>
        <span>You should write any search topic before we can help you !</span>
      </div>
      <div v-if="!hasAnyResult" class="pb-4">
        <div v-if="showArtists">
          <v-subheader class="subtitle-2">
            <v-icon color="primary" small class="mr-1">mdi-account-music</v-icon
            >Artists:
          </v-subheader>
          <div class="mx-8">
            <v-chip
              v-for="(artist, key) in artists"
              :key="key"
              elevation="10"
              color="#311b92"
              class="ma-1 white--text suggestionsTopic"
              @click="updateTopics('artist', artist.name, artist.imageUrl)"
            >
              <v-avatar class="mx-2">
                <img :src="artist.imageUrl" :alt="artist.name" />
              </v-avatar>
              <span class="white--text">{{ artist.name }}</span>
            </v-chip>
          </div>
        </div>
        <div v-if="showTitles">
          <v-subheader class="subtitle-2"
            ><v-icon color="primary" small class="mr-1">mdi-format-title</v-icon
            >Song Titles</v-subheader
          >
          <div class="mx-8">
            <v-chip
              v-for="(title, key) in titles"
              :key="key"
              elevation="10"
              color="#311b92"
              class="ma-1 white--text suggestionsTopic"
              @click="updateTopics('title', title.title, title.imageUrl)"
              ><v-avatar class="mx-2">
                <img :src="title.imageUrl" :alt="title.title" />
              </v-avatar>
              <span class="white--text">{{ title.title }}</span>
            </v-chip>
          </div>
        </div>
      </div>
      <div v-if="hasAnyResult" class="pa-4">
        <v-icon class="mr-2" color="primary">mdi-information-outline </v-icon>
        <span>
          We can't find any match for this topic. Please, use the regular or
          advanced search to find what you are looking for.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SearchSuggestion",
  props: {
    searchTopic: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      artistItems: [],
      titleItems: [],
    };
  },
  computed: {
    titles() {
      const amount = this.showArtists ? 10 : 15;
      return this.sliceBy(amount, this.titleItems).map((item) => {
        return {
          title: item.title,
          imageUrl: item.imageUrl || "./img/micro.webp",
        };
      });
    },
    artists() {
      const amount = this.showTitles ? 10 : 15;
      return this.sliceBy(amount, this.artistItems).map((item) => {
        return {
          name: item.name,
          imageUrl: item.imageUrl || "./img/micro.webp",
        };
      });
    },
    showTitles() {
      return this.titleItems.length > 0;
    },
    showArtists() {
      return this.artistItems.length > 0;
    },
    hasAnyResult() {
      return !this.showArtists && !this.showTitles && this.searchTopic;
    },
  },
  watch: {
    async searchTopic(actual) {
      if (!actual) {
        this.artistItems = [];
        this.titleItems = [];
        return;
      }
      await this.autoSearchTitle();
      await this.autoSearchArtist();
    },
  },

  methods: {
    ...mapActions(["autoCompleteTitle", "autoCompleteArtist"]),
    autoSearchTitle() {
      this.autoCompleteTitle(this.searchTopic)
        .then((response) => {
          this.titleItems = response.data.items;
        })
        .catch(() => {});
    },
    autoSearchArtist() {
      this.autoCompleteArtist(this.searchTopic)
        .then((response) => {
          this.artistItems = response.data.items;
        })
        .catch(() => {});
    },
    sliceBy(amount, elements) {
      return elements.slice(0, amount);
    },
    updateTopics(type, value, imageUrl) {
      this.$emit("update-topics", { type, value, imageUrl });
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestionsTopic {
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
</style>
