import axios from "axios";
import LocalBehaviors from "./LocalBehavior";
import globalEnv from "./global.env";

const search = `${globalEnv().apiUrlBase}/search/`;
const recommended = `${globalEnv().baseUrlRecommendations}ai/songs/recommended`;
const songsById = `${globalEnv().apiUrlBase}/songs/by-ids/`;

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use(
  (response) => {
    const { url } = response.config;
    const authUrl = [search, recommended, songsById].includes(url);
    if (authUrl) {
      return LocalBehaviors.CreateLocalMemory(response);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;
