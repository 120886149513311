// web or dashboard
import { globalEnv } from "@/helpers";

const { baseLayout } = globalEnv();

export const Home = () =>
  import(/* webpackChunkName: "home" */ `@/views/${baseLayout}/Home`);

export const Login = () =>
  import(/* webpackChunkName: "login" */ `@/views/${baseLayout}/loginPage`);

export const About = () =>
  import(/* webpackChunkName: "about" */ `@/views/${baseLayout}/About`);

export const Search = () =>
  import(/* webpackChunkName: "search" */ `@/views/${baseLayout}/Search`);

export const Help = () =>
  import(/* webpackChunkName: "help" */ `@/views/${baseLayout}/Help`);

export const Contact = () =>
  import(/* webpackChunkName: "contact" */ `@/views/${baseLayout}/Contact`);

export const Policy = () =>
  import(/* webpackChunkName: "policy" */ `@/views/${baseLayout}/Policy`);

export const Recommended = () =>
  import(
    /* webpackChunkName: "recommended" */ `@/views/${baseLayout}/Recommended`
  );

export const TopTen = () =>
  import(/* webpackChunkName: "topTen" */ `@/views/${baseLayout}/TopTen`);

export const Suggestions = () =>
  import(/* webpackChunkName: "topTen" */ `@/views/${baseLayout}/Suggestions`);

export const Configurations = () =>
  import(
    /* webpackChunkName: "configurations" */ `@/views/Globals/configurations`
  );

export const NotFound = () =>
  import(/* webpackChunkName: "notFound" */ `@/views/Globals/notFound`);
