<template>
  <section>
    <v-speed-dial
      v-model="showBox"
      bottom="bottom"
      left
      direction="bottom"
      transition="slide-x-reverse-transition"
      class="rightFixed radio20"
    >
      <template #activator>
        <v-btn
          v-model="showBox"
          color="grey lighten-2"
          dark
          fab
          elevation="5"
          data-google="userLoginBtn"
          aria-label="userLoginBtn"
        >
          <v-icon v-if="showBox" color="deep-purple darken-4">mdi-close</v-icon>
          <v-icon
            v-else-if="!showBox && !userLogged"
            color="deep-purple darken-4"
            large
            >mdi-account-circle</v-icon
          >
          <img
            v-if="!showBox && userLogged"
            :src="getUserInfo.photoUrl ? getUserInfo.photoUrl : ''"
            class="user_img"
            alt="userImages"
          />
          <v-icon
            v-if="!showBox && userLogged"
            :color="platform.color"
            class="platformIcon"
            >mdi-{{ platform.icon }}</v-icon
          >
        </v-btn>
      </template>

      <v-card v-if="!userLogged" class="login_box">
        <v-card-title class="px-7 mt-7 no_pm login_header">
          <h2 class="h2">Welcome to Zunzun</h2>
        </v-card-title>
        <v-card-text class="login_subheader px-7">
          <h4>
            Use your preferred music platform to create your own play list.
          </h4>
        </v-card-text>
        <v-card-actions class="grey lighten-2 py-5 pr-5">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <v-btn
                block
                elevation="5"
                large
                data-google="loginSpotifyBtn"
                class="text-capitalize radio20 green py-6"
                aria-label="sing in spotify"
                @click="getCodeSpotify()"
              >
                <v-icon medium class="mx-3 hand white" color="green">
                  mdi-spotify</v-icon
                >
                <span class="loginText white--text"> Sign in with Spotify</span>
              </v-btn>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-btn
                block
                elevation="5"
                large
                data-google="loginYoutubeBtn"
                class="text-capitalize radio20 white py-6"
                aria-label="sing in youtube"
                @click="getCodeYoutube()"
              >
                <img
                  src="img/googleLogo.png"
                  alt="googleLogo"
                  class="hand"
                  width="32"
                  height="auto"
                />
                <span class="ml-3 loginText"> Sign in with Google</span>
              </v-btn>
            </v-col>
            <!-- <v-col cols="12" class="my-0 py-0">
              <v-btn
                block
                elevation="5"
                large
                disabled
                class="text-capitalize radio20 white"
                data-google="loginAppleBtn"
              >
                <v-icon medium class="mx-3 loginText white--text" color="red">
                  mdi-music</v-icon
                >
                Sign in with Apple
              </v-btn></v-col
            > -->
          </v-row>
        </v-card-actions>

        <v-card-actions>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12">
              <p class="caption">
                You can read about our cookies and privacy police
                <router-link to="/policy" data-google="privacyPolice">
                  <span>here</span></router-link
                >.
              </p>
            </v-col></v-row
          >
        </v-card-actions>
      </v-card>

      <v-card
        v-else
        class="login_box_internal grey lighten-3 radio20"
        elevation="5"
      >
        <v-card-text v-if="getUserInfo.displayName" class="ma-0 pa-0">
          <img
            :src="getUserInfo.photoUrl ? getUserInfo.photoUrl : ''"
            class="user_img_internal radio20"
            alt="userImages"
          />
        </v-card-text>
        <v-card-text class="ma-0 pa-0">
          <v-row>
            <v-col cols="12">
              <div class="headerColor ml-0 pl-0">
                {{ getUserInfo.displayName }}
              </div>
              <div class="loggedText ml-0 pl-0">
                Logged as <b>{{ platform.text }}</b> user
              </div>
            </v-col>
            <v-col cols="12">
              <p class="caption">
                You can know more about our cookies and privacy police
                <router-link to="/policy"> <span>here</span></router-link>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-0 my-4 pa-0">
          <v-btn
            class="deep-purple darken-4 ml-0 radio20"
            elevation="5"
            dark
            block
            aria-label="logOut"
            @click="logOut()"
          >
            <v-icon class="mx-1">mdi-logout-variant </v-icon>logout</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-speed-dial>
  </section>
</template>

<script>
import { login } from "@/mixins/login";

export default {
  name: "LoginFloating",
  mixins: [login],
  data() {
    return {
      showBox: false,
      openConfiguration: false,
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
.loginText {
  font-size: 12;
  font-family: "Roboto", sans-serif !important;
  text-transform: initial !important;
  font-weight: 600;
  &black {
    color: #fff;
  }
  &black {
    color: #212121;
  }
}
.user_img {
  height: 50px;
  width: 50px;
  margin: 15px;
  box-shadow: 8px 2px 11px rgba(32, 33, 36, 0.37);
  border-color: rgba(223, 225, 229, 0);
  border-radius: 100%;
}
.user_img_internal {
  height: auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 8px 2px 11px rgba(32, 33, 36, 0.37);
  border-color: rgba(223, 225, 229, 0);
  margin-bottom: 20px;
}
.headerColor {
  color: #311b92;
  font-size: 18px;
  font-weight: 600;
}
.no_pm {
  margin: 0;
  padding: 0;
}
.rightFixed {
  top: initial;
  position: fixed !important;
  bottom: 60px;
}
.login_box {
  background: white;
  box-shadow: 0 3px 8px rgba(25, 25, 25, 0.3);
  left: 25px;
  padding: 0px;
  top: initial;
  -webkit-transform: initial;
  transform: initial;
  z-index: 5;
  width: 450px;
  border-radius: 20px !important;
  position: fixed !important;
  bottom: 100px;
  cursor: default;
  text-align: center;
}
.login_box_internal {
  background: white;
  box-shadow: 0 3px 8px rgba(25, 25, 25, 0.3);
  left: 25px;
  padding: 20px;
  top: initial;
  -webkit-transform: initial;
  transform: initial;
  z-index: 5;
  width: 250px;
  border-radius: 20px;
  position: fixed !important;
  bottom: 100px;
  cursor: default;
}

.login_header {
  font-weight: 900;
  margin-bottom: 10px;
  color: #212121;
  font-family: "San-Francisco", "Open Sans", "sans-serif" !important;
  font-size: 2em !important;
  line-height: 2.6em !important;
  letter-spacing: -0.0083333333em !important;
  word-break: break-word;
}
.login_subheader {
  font-weight: 500;
  margin-bottom: 10px;
  font-family: "San-Francisco", "Open Sans", "sans-serif" !important;
  font-size: 1em !important;
  line-height: 1.5rem;
  letter-spacing: -0.0083333333em !important;
  word-break: break-word;
  h4 {
    color: #212121;
  }
}
.flex_box {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  justify-self: center;
  height: 75px;
}
.mx-3 {
  margin: 0 10px;
}
.hand {
  cursor: pointer;
  border-radius: 100%;
  background: #fff;
  padding: 5px;
}
.hand:hover {
  transform: scale(1.2);
  transition: 300ms;
  cursor: pointer;
}
.platformIcon {
  position: absolute !important;
  top: 2px;
  right: 3px;
}
.loggedText {
  font-size: 12px;
  color: #212121;
}
@media only screen and (max-width: 768px) {
  .login_box {
    width: calc(100% - 50px);
  }
}
</style>
