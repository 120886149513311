var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "grey lighten-5 radio20 pa-2",
    attrs: {
      headers: _vm.headers,
      items: _vm.hits,
      "item-key": "id",
      search: _vm.search,
      "items-per-page": _vm.itemsPerPage,
      "hide-default-header": "",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function (props) {
          return _vm._l(props.items, function (item, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticStyle: { "min-width": "35px" } }, [
                _vm._v(_vm._s(index + 1)),
              ]),
              _c("td", [
                _c("div", { staticClass: "actions" }, [
                  _c("img", {
                    staticClass: "squared_img",
                    attrs: { src: item.albumImages, alt: "albumImages" },
                  }),
                ]),
              ]),
              _c("td", { staticStyle: { "min-width": "15ch" } }, [
                _c("span", [_vm._v(_vm._s(item.name))]),
                _c("br"),
              ]),
              _c("td", { staticStyle: { width: "70px" } }, [
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c("playButton", { attrs: { tracks: item, size: "24" } }),
                    1 > 2
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "iconsApp",
                                              attrs: {
                                                icon: "",
                                                tile: "",
                                                "aria-label":
                                                  "Delete from playlist",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "#311b92" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteHit(item)
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-playlist-minus")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Delete from Playlist")])]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }