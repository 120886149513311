var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "bg" },
    [
      _c(
        "v-card-title",
        { staticClass: "subtitle-1 font-weight-regular justify-space-between" },
        [
          _c("span", [_vm._v(_vm._s(_vm.currentTitle))]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", elevation: "0", "aria-label": "close" },
              on: { click: _vm.closeSuggestedWindow },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "bg mx-0 px-0" },
        [
          _c(
            "v-window",
            {
              staticClass: "px-4",
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-window-item",
                { attrs: { value: 1 } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c("div", [
                          _c("img", {
                            staticClass: "suggest",
                            attrs: {
                              src: require("@/assets/images/suggest.jpg"),
                              alt: "suggest",
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12 d-flex justify-center align-center",
                            md: "6",
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "grey--text text--darken-1" },
                              [
                                _c("h1", { staticClass: "my-4" }, [
                                  _vm._v("Welcome to Zunzun.io"),
                                ]),
                                _c("p", [
                                  _c("b", [_vm._v("Thank you")]),
                                  _vm._v(
                                    " for help us to create a better application, we will use your input to generate betters play list. "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.getSuggestionsSongs, function (hit, key) {
                return _c(
                  "v-window-item",
                  { key: hit.id, attrs: { value: key + 2 } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "songsItems" },
                      [_c("songSuggested", { attrs: { hit: hit } })],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-window-item",
                { attrs: { value: _vm.songsAmountLength + 2 } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c("img", {
                              staticClass: "suggest",
                              attrs: {
                                src: require("@/assets/images/suggest.jpg"),
                                alt: "suggest",
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center align-center",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "grey--text text--darken-1" },
                                [
                                  _c("h1", { staticClass: "my-4" }, [
                                    _vm._v("Thanks for help us!"),
                                  ]),
                                  _c("p", [
                                    _c("b", [_vm._v("Note: ")]),
                                    _vm._v(
                                      ' You alway can update you profile using this form in "Suggestions" pages. Now go and enjoy our application. '
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-4" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.step === 1,
                text: "",
                rounded: "",
                "aria-label": "Back",
              },
              on: {
                click: function ($event) {
                  _vm.step--
                },
              },
            },
            [_vm._v(" Back ")]
          ),
          _c("v-spacer"),
          !_vm.loading
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.step) + "/ " + _vm._s(_vm.songsAmountLength + 2)
                ),
              ])
            : _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !(_vm.step === _vm.songsAmountLength + 2),
                  expression: "!(step === songsAmountLength + 2)",
                },
              ],
              attrs: {
                disabled: _vm.step === _vm.songsAmountLength + 2 || _vm.loading,
                color: "primary",
                depressed: "",
                rounded: "",
                "aria-label": "Next",
              },
              on: {
                click: function ($event) {
                  _vm.step++
                },
              },
            },
            [_vm._v(" Next ")]
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === _vm.songsAmountLength + 2,
                  expression: "step === songsAmountLength + 2",
                },
              ],
              attrs: {
                color: "primary",
                depressed: "",
                "aria-label": "Send information",
              },
              on: { click: _vm.sendSuggestion },
            },
            [_vm._v(" finalize ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }