var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { transition: "fade-transition" } },
    [
      _c(
        "v-row",
        { staticClass: "main mr-0 main_general" },
        [
          _c(
            "v-col",
            {
              attrs: { xl: "4", lg: "6", md: "6", sm: "12", col: "12" },
              scopedSlots: _vm._u([
                {
                  key: "placeholder",
                  fn: function () {
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "fill-height ma-0",
                          attrs: { align: "center", justify: "center" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "deep-purple darken-4",
                              "aria-progressbar-name": "search loader",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("img", {
                staticClass: "img_2 img",
                attrs: {
                  src: _vm.artistImage,
                  "data-google": "showRelatedHits",
                  "aspect-ratio": "1",
                  alt: "artistImage",
                  "lazy-src": "./img/micro.webp",
                },
              }),
              _c(
                "v-row",
                { staticClass: "playButtonContainer" },
                [
                  _c("playButton", {
                    attrs: {
                      tracks: _vm.hit.targetHitsRelated.spotify
                        ? _vm.hit.targetHitsRelated.spotify.hitInfo
                        : [],
                      size: String(_vm.iconSize),
                    },
                  }),
                  _c("div", { staticClass: "ma-5" }, [
                    _c("span", { staticClass: "songArtist" }, [
                      _vm._v(_vm._s(_vm.artistName)),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("span", { staticClass: "songTitle" }, [
                      _vm._v(_vm._s(_vm.songTitle)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { xl: "8", lg: "6", md: "6", sm: "12", col: "12" } },
            _vm._l(_vm.systemTags, function (tag, index) {
              return _c("emotions", {
                key: index,
                attrs: {
                  id: index,
                  name: tag,
                  status: false,
                  value: 5,
                  "song-id": _vm.hit,
                },
                on: { "update-emotions": _vm.updateEmotions },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }