import Axios from "./interceptors";
import globalEnv from "./global.env";

class AxiosConnection {
  /*  static urlBase() {
    return `${globalEnv().apiUrlBase}`;
  }

  static urlBaseAutocomplete() {
    return `${globalEnv().apiUrlAutocomplete}`;
  } */

  static axiosConf(accessToken) {
    const token = accessToken || localStorage.getItem("access_token");
    return {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
  }

  static async getById(apiUrl, id, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}/${id}`;
    const result = await Axios.get(url, AxiosConnection.axiosConf(), id);
    return result;
  }

  static async getByFilter(apiUrl, api = "apiUrlBase", filter) {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}?${filter}`;
    const result = await Axios.get(url, AxiosConnection.axiosConf());
    return result;
  }

  static async getAutocomplete(apiUrl, filter) {
    const { apiUrlAutocomplete } = globalEnv();
    const url = `${apiUrlAutocomplete + apiUrl}/${filter}`;
    const result = await Axios.get(url, AxiosConnection.axiosConf());
    return result;
  }

  static async getAll(apiUrl, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}`;
    const result = await Axios.get(url, AxiosConnection.axiosConf());
    return result;
  }

  static async delete(apiUrl, id, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}/${id}`;
    const result = await Axios.delete(url, AxiosConnection.axiosConf());
    return result;
  }

  static async put(apiUrl, id, object, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}?${id}`;
    const result = await Axios.put(url, object, AxiosConnection.axiosConf());
    return result;
  }

  static async post(apiUrl, object = null, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}/`;
    const result = await Axios.post(
      url,
      object,
      AxiosConnection.axiosConf()
    ).catch(async (error) => {
      const statusCode = error.response
        ? error.response.data.statusCode
        : error.response.status;
      if (statusCode === 500) {
        await AxiosConnection.getNewAccessToken().then(async (res) => {
          const conf = AxiosConnection.axiosConf(res.data.access_token);
          Axios.post(url, object, conf);
        });
        console.log("500", error);
      }
      if (statusCode === 401) {
        console.log("401", error);
      }
      if (statusCode === 400) {
        console.log("400", error);
      }
    });
    return result;
  }

  static async postQuery(apiUrl, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}`;
    const result = await Axios.post(url);
    return result;
  }

  static async patch(apiUrl, object, api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api] + apiUrl}/`;
    const result = await Axios.patch(url, object, AxiosConnection.axiosConf());
    return result;
  }

  /* direct to spotify */

  static async getNewAccessToken(api = "apiUrlBase") {
    const env = globalEnv();
    const url = `${env[api]}/refresh-token/spotify`;
    const body = { refresh_token: localStorage.getItem("refresh_token") };
    const result = await Axios.post(url, body, AxiosConnection.axiosConf());
    localStorage.setItem("access_token", result.data.access_token);
    return result;
  }

  static async spotifyGetById(apiUrl, id) {
    const url = `${apiUrl}/${id}`;
    const result = await Axios.get(url, AxiosConnection.axiosConf(), id).catch(
      async (error) => {
        if (error.response.data.statusCode === 500) {
          await AxiosConnection.getNewAccessToken().then((res) => {
            Axios.get(
              url,
              AxiosConnection.axiosConf(res.data.access_token),
              id
            );
          });
        }
      }
    );
    return result;
  }

  static async get(apiUrl, params) {
    if (params) {
      const url = apiUrl;
      const result = await Axios.get(
        url,
        AxiosConnection.axiosConf(params.accessToken)
      );
      return result;
    }
    return null;
  }
}
export default AxiosConnection;
