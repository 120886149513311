import { render, staticRenderFns } from "./configurations.vue?vue&type=template&id=8c453550&scoped=true&"
import script from "./configurations.vue?vue&type=script&lang=js&"
export * from "./configurations.vue?vue&type=script&lang=js&"
import style0 from "./configurations.vue?vue&type=style&index=0&id=8c453550&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c453550",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VIcon,VSpacer,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\Zunzun.io\\zunzunV2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8c453550')) {
      api.createRecord('8c453550', component.options)
    } else {
      api.reload('8c453550', component.options)
    }
    module.hot.accept("./configurations.vue?vue&type=template&id=8c453550&scoped=true&", function () {
      api.rerender('8c453550', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/user/configurations.vue"
export default component.exports