export default () => {
  return {
    apiUrlBase: process.env.VUE_APP_Galactus_ApiUrlBase,
    apiUrlAutocomplete: process.env.VUE_APP_Ultron_ApiUrlBaseAutocomplete,
    baseUrlAuthorize: process.env.VUE_APP_Spotify_BaseUrlAuthorize,
    baseUrlFlashApi: process.env.VUE_APP_Ultron_ApiUrlBaseFlashAPI,
    baseUrlRecommendations: process.env.VUE_APP_Recommendations,
    baseLayout: process.env.VUE_APP_Layout,

    clientId: process.env.VUE_APP_Spotify_ClientId,
    baseUrl: process.env.VUE_APP_Spotify_BaseUrlAuthorize,
    responseType: process.env.VUE_APP_Spotify_ResponseType,
    redirectUri: process.env.VUE_APP_Spotify_RedirectUri,
    scope: process.env.VUE_APP_Spotify_Scope,
    Token: process.env.VUE_APP_Spotify_Token,

    youtubeBase: process.env.VUE_APP_YOUTUBE_Base,
    youtubeScope: process.env.VUE_APP_YOUTUBE_Scope,
    youtubeIncludes: process.env.VUE_APP_YOUTUBE_IncludeGrantedScopes,
    youtubeResponseType: process.env.VUE_APP_YOUTUBE_ResponseType,
    youtubeAccessType: process.env.VUE_APP_YOUTUBE_AccessType,
    youtubeState: process.env.VUE_APP_YOUTUBE_State,
    youtubeRedirectUri: process.env.VUE_APP_YOUTUBE_RedirectUri,
    youtubeClientId: process.env.VUE_APP_YOUTUBE_ClientId,
  };
};
