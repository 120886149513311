import { render, staticRenderFns } from "./searchSuggestion.vue?vue&type=template&id=185ecc93&scoped=true&"
import script from "./searchSuggestion.vue?vue&type=script&lang=js&"
export * from "./searchSuggestion.vue?vue&type=script&lang=js&"
import style0 from "./searchSuggestion.vue?vue&type=style&index=0&id=185ecc93&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185ecc93",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAvatar,VChip,VDivider,VIcon,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\Zunzun.io\\zunzunV2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('185ecc93')) {
      api.createRecord('185ecc93', component.options)
    } else {
      api.reload('185ecc93', component.options)
    }
    module.hot.accept("./searchSuggestion.vue?vue&type=template&id=185ecc93&scoped=true&", function () {
      api.rerender('185ecc93', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/search/suggestions/searchSuggestion.vue"
export default component.exports