import { axiosConnection, localStorageHandler } from "@/helpers";

const billboard = {
  state: {
    billboardItems: [],
    apiUrl_search: "/search",
    apiUrl_pl: "/user-mgm/playlist/",
    autoCompleteTitleUrl: "by-title",
    autoCompleteArtistUrl: "by-artist",
    searchTopics: [],
    playList: [],
    refreshLayout: 0,
    playlistCreator: false,
    searchTotalItems: 0,
    loading: false,
    errors: {},
    activeHitsList: [],
    suggestionsSongs: [],
  },
  mutations: {
    setPlaylistCreator(state, data) {
      state.playlistCreator = data;
    },
    setRefreshLayout(state) {
      state.refreshLayout += 1;
    },
    setBillboardItems(state, result) {
      state.billboardItems.push(result);
    },
    setBillboardItemsAfterPl(state, result) {
      state.billboardItems = result;
    },
    resetBillboardItems(state) {
      state.billboardItems = [];
    },
    setSearchTopics(state, result) {
      state.searchTopics = result;
    },
    setPlayList(state, data) {
      state.playList.push(data);
      const uniq = state.playList.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.spotifyUriId === v.spotifyUriId &&
              t.youtubeUriId === v.youtubeUriId
          ) === i
      );
      state.playList = uniq;
    },
    setPlayListUpdate(state, data) {
      state.playList = data;
    },
    deleteFromPlayList(state, data) {
      state.playList = data;
      const uniq = state.playList.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.spotifyUriId === v.spotifyUriId &&
              t.youtubeUriId === v.youtubeUriId
          ) === i
      );
      state.playList = uniq;
    },
    setSearchTotalItems(state, data) {
      state.searchTotalItems = data;
    },
    setSuggestionsSongs(state, data) {
      state.suggestionsSongs = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setErrors(state, data) {
      state.errors = data;
    },
    resetPlayList(state, data) {
      state.playList = [];
    },
  },
  actions: {
    async postBillboard({ state, commit }, pages) {
      let search = {};
      let errorResponse = {};
      state.searchTopics.forEach((ele) => {
        if (ele.type === "Integer") {
          search[ele.apiTarget] = parseInt(ele.value, 10);
        } else if (ele.type === "String") {
          search[ele.apiTarget] = ele.value.trim();
        } else {
          search[ele.apiTarget] = ele.value;
        }
        return ele;
      });
      const itemsPerPage = pages.itemsPerPage > 50 ? 20 : pages.itemsPerPage;
      const init = {
        from: itemsPerPage * (pages.actualPage - 1),
        size: itemsPerPage,
        search: search,
        sort: [
          {
            asc: "position",
          },
        ],
      };
      let result = axiosConnection.post(state.apiUrl_search, init);
      await result
        .then((resp) => {
          if (resp.data.items.length > 0) {
            commit("setBillboardItems", {
              page: pages.actualPage,
              items: resp.data.items,
            });
          }
          commit("setSearchTotalItems", resp.data.total);
        })
        .catch((error) => {
          errorResponse = {
            type: "error",
            content: error,
          };
          commit("setErrors", errorResponse);
        })
        .finally(() => {
          commit("setLoading", false);
          if (state.searchTotalItems > 0) {
            errorResponse = {
              type: "success",
              content: "Success",
            };
            commit("setErrors", errorResponse);
          } else {
            errorResponse = {
              type: "error",
              content: "error",
            };
            commit("setErrors", errorResponse);
          }
        });
      return result;
    },
    async postBillboardInit({ state, commit }, pages) {
      let search = {};
      let errorResponse = {};
      state.searchTopics.forEach((ele) => {
        if (ele.type === "Integer") {
          search[ele.apiTarget] = parseInt(ele.value, 10);
        } else if (ele.type === "String") {
          search[ele.apiTarget] = ele.value.trim();
        } else {
          search[ele.apiTarget] = ele.value;
        }
        return ele;
      });
      const itemsPerPage = pages.itemsPerPage > 50 ? 20 : pages.itemsPerPage;
      const init = {
        from: itemsPerPage * (pages.actualPage - 1),
        size: itemsPerPage,
        search: search,
        sort: [
          {
            asc: "position",
          },
        ],
      };
      let result = axiosConnection.post(state.apiUrl_search, init);
      await result
        .then((resp) => {
          commit("setBillboardItems", {
            page: pages.actualPage,
            items: resp.data.items,
          });
          commit("setSearchTotalItems", resp.data.total);
        })
        .catch((error) => {
          errorResponse = {
            type: "error",
            content: error,
          };
          commit("setErrors", errorResponse);
        })
        .finally(() => {
          commit("setLoading", false);
          if (state.searchTotalItems > 0) {
            errorResponse = {
              type: "success",
              content: "Success",
            };
            commit("setErrors", errorResponse);
          } else {
            errorResponse = {
              type: "error",
              content: "error",
            };
            commit("setErrors", errorResponse);
          }
        });
      return result;
    },
    async postCreatePlayList({ state, commit }, { list, target }) {
      const result = await axiosConnection.post(state.apiUrl_pl + target, list);
      return result;
    },
    async autoCompleteTitle({ state, commit }, data) {
      const result = await axiosConnection.getAutocomplete(
        state.autoCompleteTitleUrl,
        data
      );
      return result;
    },
    async autoCompleteArtist({ state, commit }, data) {
      const result = await axiosConnection.getAutocomplete(
        state.autoCompleteArtistUrl,
        data
      );
      return result;
    },
    resetSearchTotalItems({ commit }) {
      const init = {
        from: 0,
        size: 60,
        total: 1,
      };
      commit("setSearchTotalItems", init);
    },
    async fetchSuggestedPlayList({ commit }, data) {
      const cleanData = data.filter((ele) => ele !== undefined && ele !== null);
      let result = axiosConnection.post("/songs/by-ids", cleanData);

      await result.then((resp) => {
        if(resp){
        commit("setSuggestionsSongs", resp.data.items);}
       }).catch((error) => console.log(error))
      return result;
    },
  },
  getters: {
    getRefreshLayout(state) {
      return state.refreshLayout;
    },
    getBillboardItems(state) {
      return state.billboardItems;
    },
    getSearchTopics(state) {
      return state.searchTopics;
    },
    getPlayList(state) {
      return state.playList;
    },
    getPlaylistCreator(state) {
      return state.playlistCreator;
    },
    getSearchTotalItems(state) {
      return state.searchTotalItems;
    },
    getLoading(state) {
      return state.loading;
    },
    getErrors(state) {
      return state.errors;
    },
    getSuggestionsSongs(state) {
      return state.suggestionsSongs;
    },
  },
};
export default billboard;
