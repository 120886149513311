<template>
  <v-card>
    <v-card-title class="text-h5"> User Profile </v-card-title>
    <v-card-text>
      <v-tabs vertical>
        <v-tab class="d-flex justify-left">
          <v-icon left> mdi-account </v-icon>
          User Information
        </v-tab>
        <v-tab class="justify-left">
          <v-icon left> mdi-account </v-icon>
          Play List
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>Some Info</p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        text
        aria-label="Disagree"
        @click="dialog = false"
      >
        Disagree
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        aria-label="Agree"
        @click="dialog = false"
      >
        Agree
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Configuration",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style scoped>
.cardSize {
  min-height: 600px;
}
</style>
