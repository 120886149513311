import { mapGetters, mapMutations } from "vuex";
// import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { globalEnv, localStorageHandler, axiosConnection } from "@/helpers";

// eslint-disable-next-line import/prefer-default-export
export const login = {
  computed: {
    ...mapGetters([
      "getSearchTopics",
      "getBillboardItems",
      "getPlayList",
      "getItemsPerPage",
      "getActualPage",
      "getSearchTotalItems",
      "getUserInfo",
    ]),
    platform() {
      const platform = localStorageHandler.getLocalStore("platform");
      if (platform === "spotify") {
        return { color: "green", icon: "spotify", text: "Spotify" };
      }
      if (platform === "google") {
        return { color: "red", icon: "youtube", text: "Youtube Music" };
      }
      return { color: "", icon: "", text: "" };
    },
    userLogged() {
      const userLogged = localStorageHandler.getLocalStore("userLogged");
      return userLogged === "true";
    },
  },
  mounted() {
    let user = localStorageHandler.getJsonLocalStore("user");

    if (this.userLogged) {
      this.setUser(user);
    } else if (!user || Object.keys(user).length === 0) {
      user = {
        displayName: `anonymous-user-${uuidv4()}`,
      };
      localStorageHandler.setJsonLocalStore("user", user);
    }
    this.setUser(user);
  },
  methods: {
    ...mapMutations(["setUser", "setBillboardItems", "setSearchTopics"]),
    createLocalSaves(platform) {
      localStorageHandler.setJsonLocalStore(
        "getSearchTopics",
        this.getSearchTopics
      );
      localStorageHandler.setJsonLocalStore(
        "getBillboardItems",
        this.getBillboardItems
      );
      localStorageHandler.setJsonLocalStore("getPlayList", this.getPlayList);
      localStorageHandler.setLocalStore(
        "redirtAfterCallbak",
        // eslint-disable-next-line no-restricted-globals
        new URL(location.href)
      );
      localStorageHandler.setLocalStore("platform", platform);
      localStorageHandler.setLocalStore("itemsPerPage", this.getItemsPerPage);
      localStorageHandler.setLocalStore("actualPage", this.getActualPage);
      localStorageHandler.setLocalStore(
        "fromUrl",
        this.$router.currentRoute.name
      );
      localStorageHandler.setLocalStore(
        "searchTotalItems",
        this.getSearchTotalItems
      );
    },
    getCodeSpotify() {
      this.createLocalSaves("spotify");
      const { clientId, baseUrl, responseType, redirectUri, scope } =
        globalEnv();
      window.location.href = `${baseUrl}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&state=some-test-state`;
    },
    getCodeYoutube() {
      this.createLocalSaves("google");
      const {
        youtubeBase,
        youtubeScope,
        youtubeIncludes,
        youtubeResponseType,
        youtubeAccessType,
        youtubeState,
        youtubeRedirectUri,
        youtubeClientId,
      } = globalEnv();
      const url = `${youtubeBase}?scope=${youtubeScope}&include_granted_scopes=${youtubeIncludes}&response_type=${youtubeResponseType}&access_type=${youtubeAccessType}&state=${youtubeState}&redirect_uri=${youtubeRedirectUri}&client_id=${youtubeClientId}`;
      window.location.href = url;
    },
    getCodeApple() {},
    async getToken() {
      const Platform = localStorageHandler.getLocalStore("platform");
      const apiUrl = `/authorize/${Platform}`;
      const dataObject = {
        code: localStorageHandler.getLocalStore("code"),
        redirectUri: globalEnv().redirectUri,
      };
      try {
        const result = await axiosConnection.post(apiUrl, dataObject);
        if (result) {
          localStorageHandler.setLocalStore(
            "access_token",
            result.data.access_token
          );
          localStorageHandler.setLocalStore(
            "refresh_token",
            result.data.refresh_token
          );
          localStorageHandler.setLocalStore(
            "token_type",
            result.data.token_type
          );
          return result;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      return dataObject;
    },
    async logOut() {
      localStorageHandler.setLocalStore("access_token", "");
      localStorageHandler.setLocalStore("refresh_token", "");
      localStorageHandler.setLocalStore("token_type", "");
      localStorageHandler.setLocalStore("userLogged", false);
      localStorageHandler.setJsonLocalStore("user", {});
      localStorageHandler.setLocalStore("code", "");
      localStorageHandler.setLocalStore("savedLogOut", true);
      localStorageHandler.setLocalStore("itemsPerPage", 1);
      localStorageHandler.setLocalStore("actualPage", 20);
      localStorageHandler.setLocalStore("searchTotalItems", 0);
      localStorageHandler.setLocalStore("platform", null);
      localStorageHandler.setLocalStore("fromUrl", "");
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
  },
};
