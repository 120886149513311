import { mapGetters, mapMutations } from "vuex";

const playListBehavior = {
  computed: {
    ...mapGetters([
      "getPlayList",
      "getBillboardItems",
      "getActualPage",
      "getItemsPerPage",
      "getSongWithTagName",
    ]),
  },
  methods: {
    ...mapMutations([
      "setPlayList",
      "deleteFromPlayList",
      "setPlayListUpdate",
      "setRefreshLayout",
    ]),
    addToPlaylist(hit) {
      if (this.isAddedToPlaylist(hit.globalInfo.id)) {
        this.deleteHitFromPlaylist(hit.globalInfo.id);
      } else {
        const hitFormatted = this.generateHitFormattedGlobal(hit);
        this.setPlayList(hitFormatted);
      }
    },
    addToPlaylistInternal(element) {
      const { hit, key } = element;
      if (this.isAddedToPlaylist(hit.globalInfo.id)) {
        this.deleteHitFromPlaylist(hit.globalInfo.id);
      } else {
        const hitFormatted = this.generateHitFormattedGlobal(
          hit.targetHitsRelated[key],
          key
        );
        this.setPlayList(hitFormatted);
      }
    },
    addMultipleHits(status = true) {
      const pageItems = this.getBillboardItems.find(
        (ele) => ele.page === this.getActualPage
      );
      if (status) {
        pageItems.items
          .map((ele) => this.generateHitFormattedGlobal(ele))
          .map((ele) => this.setPlayList(ele));
      } else {
        const idList = pageItems.items.map((ele) => this.getId(ele));
        const List = this.getPlayList.filter((ele) => !idList.includes(ele.id));
        this.setPlayListUpdate(List);
      }
    },
    addToPlayListFromAlbum(hit) {
      if (this.isAddedToPlaylist(hit.globalInfo.id)) {
        this.deleteHitFromPlaylist(hit.globalInfo.id);
      } else {
        const hitFormatted = this.generateHitFormattedGlobal(hit);
        this.setPlayList(hitFormatted);
      }
    },
    isAddedToPlayList() {},
    isAddedToPlaylist(hit) {
      const activeHit = this.getPlayList.find((ele) => ele.id === hit);
      return !!activeHit;
    },
    deleteHitFromPlaylist(hit) {
      const activeHit = this.getPlayList.filter((ele) => ele.id !== hit);
      this.deleteFromPlayList(activeHit);
      return true;
    },
    getTags(hitId) {
      return this.getSongWithTagName
        .filter(({ id }) => id === hitId)
        .map(({ tag }) => tag);
    },
    /* ----------------------- Generators -----------------------  */
    generateHitFormattedGlobal(hit) {
      const { globalInfo } = hit;
      const { spotify } = hit.targetHitsRelated || null;
      const { youtube } = hit.targetHitsRelated || null;
      const { thumbnails } = spotify || youtube || null;
      const image = this.getThumbnail(thumbnails);

      const playListHitFormatted = {
        id: globalInfo.id,
        albumImages: image,
        name: spotify ? spotify.hitInfo.name : globalInfo.title,
        spotifyUriId: spotify ? spotify.hitInfo.spotifyUriId : false,
        youtubeUriId: youtube ? youtube.hitInfo.youtubeUriId : false,
        explicit: spotify ? spotify.hitInfo.explicit : null,
        previewUrl: spotify ? spotify.hitInfo.previewUrl : null,
        tags: this.getTags(globalInfo.id),
      };
      return playListHitFormatted;
    },
    getThumbnail(thumbnail) {
      if (thumbnail.default && thumbnail.default.url) {
        return thumbnail.default.url;
      }
      if (thumbnail.medium && thumbnail.medium.url) {
        return thumbnail.medium.url;
      }
      if (thumbnail.height && thumbnail.height.url) {
        return thumbnail.height.url;
      }
      return "http://";
    },
    getId(hit) {
      const { globalInfo } = hit;
      return globalInfo.id;
    },
  },
};
export default playListBehavior;
/*

*/
