import objectMapper from "object-mapper";

class mapper {
  static generalInfoMapper(src) {
    const map = {
      id: "globalInfo.id",
      title: "globalInfo.title",
      artist: "globalInfo.artist",
      duration: "globalInfo.duration",
      artistImage: "artistImage",
      origin: [
        {
          key: "sourceInfo",
          billboard: {
            key: "billboard",
            debutDate: "debutDate",
            label: "label",
            position: "position",
            producer: "labelPublishNumber",
          },
        },
      ],
    };
    const dest = objectMapper(src, map);
    return dest;
  }

  static spotifyMapper(src) {
    const map = {
      albumInfo: {
        albumApiLink: "albumApiLink",
        albumId: "albumId",
        albumName: "albumName",
        albumUriId: "albumUriId",
        albumWebLink: "albumWebLink",
        apiLink: "apiLink",
      },
      hitInfo: {
        duration: "duration",
        explicit: "explicit",
        publishTime: "publishTime",
        publishedAt: "publishedAt",
        spotifyUriId: "uriId",
        name: "name",
        previewUrl: "previewUrl",
      },
      thumbnails: "albumImgs",
    };

    const dest = objectMapper(src, map);
    return dest;
  }

  static youtubeMapper(src) {
    const map = {};
    const dest = objectMapper(src, map);
    return dest;
  }
}
export default mapper;
