<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="12">
          <v-alert v-show="!isValidPositionRange" type="warning" class="my-3">
            <span class="alertTest">Position range is wrong!</span>
          </v-alert>
          <v-alert v-show="!isValidRange" type="warning" class="my-3">
            <span class="alertTest">Date range is wrong</span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-if="!showTitleFilter"
            v-model="title"
            outlined
            cache-items
            allow-overflow
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            chips
            label="Title"
            hint="Song Title"
            persistent-hint
            append-icon="mdi-format-title"
            item-text="title"
            item-value="title"
            rounded
            dense
            @change="createTopicObject()"
          >
            <template #append-outer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    left
                    color="deep-purple darken-4"
                    data-google="titleAutoFilterBtn"
                    v-on="on"
                    @click="showTitleFilter = !showTitleFilter"
                  >
                    mdi-filter-plus
                  </v-icon>
                </template>
                Change to autocomplete filter.
              </v-tooltip>
            </template>
          </v-text-field>
          <v-autocomplete
            v-else
            v-model="title"
            :items="titleItems"
            label="Title"
            hint="Song Title - autocomplete"
            persistent-hint
            append-icon="mdi-format-title"
            rounded
            dense
            :loading="isTitleLoading"
            :search-input.sync="autoSearchTitle"
            cache-items
            allow-overflow
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            chips
            outlined
            item-text="title"
            item-value="title"
            no-filter
            @change="createTopicObject()"
          >
            <template #append-outer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    left
                    color="deep-purple darken-4"
                    v-on="on"
                    @click="showTitleFilter = !showTitleFilter"
                  >
                    mdi-filter-minus
                  </v-icon>
                </template>
                Write you own search parameter.
              </v-tooltip>
            </template>
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                <v-avatar left>
                  <v-img :src="data.item.imageUrl" on-error=""></v-img>
                </v-avatar>
                {{ formattedData(data.item.title, 10) }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content
                  v-text="formattedData(data.item, 10)"
                ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.imageUrl" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title
                    v-html="formattedData(data.item.title, 15)"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-combobox
            v-show="!showArtistFilter"
            v-model="artist"
            outlined
            rounded
            dense
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-account-music"
            chips
            item-text="name"
            item-value="name"
            hint="Artist"
            label="Artist"
            multiple
            persistent-hint
            @change="createTopicObject()"
          >
            <template #append-outer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    left
                    color="deep-purple darken-4"
                    data-google="artistAutoFilterBtn"
                    v-on="on"
                    @click="showArtistFilter = !showArtistFilter"
                  >
                    mdi-filter-plus
                  </v-icon>
                </template>
                Change to autocomplete filter.
              </v-tooltip>
            </template>
            <template #selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                color="deep-purple darken-4"
                item-color="deep-purple darken-1"
                append-icon="mdi-account-music"
                class="white--text ma-1"
              >
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-autocomplete
            v-show="showArtistFilter"
            v-model="artist"
            label="Artist"
            outlined
            :items="artistItems"
            :loading="isArtistLoading"
            :search-input.sync="autoSearchArtist"
            cache-items
            rounded
            dense
            multiple
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-account-music"
            chips
            item-text="name"
            item-value="name"
            hint="Artist - autocomplete"
            persistent-hint
            @change="createTopicObject()"
          >
            <template #append-outer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    left
                    color="deep-purple darken-4"
                    v-on="on"
                    @click="showArtistFilter = !showArtistFilter"
                  >
                    mdi-filter-minus
                  </v-icon>
                </template>
                Write you own search parameter.
              </v-tooltip>
            </template>
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                <v-avatar left>
                  <v-img :src="data.item.imageUrl"></v-img>
                </v-avatar>
                {{ formattedData(data.item.name, 10) }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.imageUrl" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title
                    v-html="formattedData(data.item.name, 15)"
                  ></v-list-item-title>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-subtitle
                    v-html="data.item.artist"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template> </template
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-if="localMemory.producer.length > 0 && showLabelFilter"
            v-model="label"
            label="Producer"
            outlined
            :items="localMemory.producer"
            rounded
            dense
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-home-variant-outline"
            chips
            hint="Song Producer - autocomplete"
            persistent-hint
            @change="createTopicObject()"
          >
            <template #append-outer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    left
                    color="deep-purple darken-4"
                    data-google="producerAutoFilterBtn"
                    v-on="on"
                    @click="showLabelFilter = !showLabelFilter"
                  >
                    mdi-filter-minus
                  </v-icon>
                </template>
                Write you own search parameter.
              </v-tooltip>
            </template>
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                <v-icon left color="white">mdi-home-variant-outline</v-icon>
                {{ formattedData(data.item, 10) }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-avatar class="mx-0 px-0">
                  <v-icon left color="deep-purple darken-4">
                    mdi-home-variant-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content
                  v-text="formattedData(data.item, 15)"
                ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-icon left color="white"> mdi-home-variant-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title v-html="data.item"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="label"
            label="Producer"
            outlined
            rounded
            dense
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-home-variant-outline"
            chips
            hint="Song Producer"
            persistent-hint
            @change="createTopicObject()"
          >
            <template #append-outer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    left
                    color="deep-purple darken-4"
                    v-on="on"
                    @click="showLabelFilter = !showLabelFilter"
                  >
                    mdi-filter-plus
                  </v-icon>
                </template>
                Change to autocomplete filter
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-if="localMemory.genres.length > 0"
            v-model="genres"
            :items="localMemory.genres"
            label="Genres"
            outlined
            rounded
            dense
            chips
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-music"
            hint="Genres"
            persistent-hint
            @change="createTopicObject()"
          >
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                <v-icon left color="white"> mdi-music </v-icon>
                {{ formattedData(data.item, 10) }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-avatar>
                  <v-icon left color="deep-purple darken-4"> mdi-music </v-icon>
                </v-list-item-avatar>
                <v-list-item-content
                  v-text="formattedData(data.item, 15)"
                ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-icon left color="white"> mdi-music </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title v-html="data.item"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="genres"
            label="Genres"
            outlined
            rounded
            dense
            chips
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-music"
            hint="Genres"
            persistent-hint
            @change="createTopicObject()"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="origin"
            :items="sources"
            label="Awards"
            append-icon="mdi-semantic-web"
            rounded
            dense
            disabled
            allow-overflow
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            outlined
            chips
            item-text="name"
            hint="Awards or Streamer Service"
            item-value="name"
            persistent-hint
            @change="createTopicObject()"
          >
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                <v-icon left color="white">
                  {{ data.item.icon }}
                </v-icon>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-icon left :color="data.item.color">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-subtitle
                    v-html="data.item.artist"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="target"
            :items="targets"
            label="Targets"
            outlined
            rounded
            dense
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            hint="Stream platform"
            append-icon="mdi-bullseye-arrow"
            chips
            item-text="name"
            item-value="api"
            persistent-hint
            @change="createTopicObject()"
          >
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                <v-icon left color="white">
                  {{ data.item.icon }}
                </v-icon>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-icon left :color="data.item.color">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="lowestPosition"
            label="From"
            hint="Lowest songs position"
            persistent-hint
            append-icon="mdi-numeric-1-circle"
            rounded
            dense
            chips
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            outlined
            :rules="[rules.number, rules.max, rules.min]"
            @change="createTopicObject()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="highestPosition"
            label="To"
            hint="Highest song Position"
            persistent-hint
            rounded
            dense
            chips
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            append-icon="mdi-numeric-9-circle"
            outlined
            :rules="[rules.number, rules.max, rules.min]"
            @change="createTopicObject()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-combobox
            v-model="itemsPerPage"
            label="Items per page"
            :items="[5, 10, 15, 20, 30]"
            :rules="[rules.maxItemPerPage]"
            outlined
            rounded
            dense
            clearable
            color="deep-purple darken-4"
            item-color="deep-purple darken-1"
            hint="Amount of items per page"
            persistent-hint
            append-icon="mdi-bullseye-arrow "
            chips
            @change="
              itemsPerPage ? setItemsPerPage(itemsPerPage) : setItemsPerPage(20)
            "
          >
            <template #selection="data">
              <v-chip color="deep-purple darken-4" class="mt-2 white--text">
                {{ data.item }}
              </v-chip>
            </template>
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-list-item-title v-html="data.item"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-overflow
            rounded
            dense
            chips
            outline
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="From:"
                outlined
                rounded
                dense
                chips
                clearable
                color="deep-purple darken-4"
                item-color="deep-purple darken-1"
                class="mt-0 pt-0"
                hint="Date range start"
                persistent-hint
                append-icon="mdi-calendar"
                v-bind="attrs"
                @blur="startDate = parseDate(dateFormatted)"
                v-on="on"
                @change="createTopicObject()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              outline
              show-week
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-overflow
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="To:"
                hint="Date Range End"
                persistent-hint
                outlined
                dense
                clearable
                chips
                rounded
                color="deep-purple darken-4"
                item-color="deep-purple darken-1"
                append-icon="mdi-calendar"
                v-bind="attrs"
                class="mt-0 pt-0"
                @blur="endDate = parseDate(computedDateFormatted)"
                v-on="on"
                @change="createTopicObject()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-4 mb-2" style="text-align: center">
          <v-divider class="mb-5"></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="deep-purple darken-4 mx-2"
            rounded
            elevation="15"
            data-google="applyFilterBtn"
            aria-label="Apply filter"
            @click="save"
          >
            <v-icon color="white" class="mr-1">mdi-filter-variant-plus</v-icon>
            <span class="white--text text-capitalize"> Apply Filters</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { isAfter, isEqual } from "date-fns";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { localStorageHandler } from "@/helpers";

export default {
  data: () => ({
    startDate: null,
    endDate: null,
    dateFormatted: null,
    computedDateFormatted: null,
    menu1: false,
    menu2: false,
    sources: [
      {
        api: "billboard",
        name: "billboard",
        icon: "mdi-music",
        color: "black",
      },
    ],
    targets: [
      { api: "spotify", name: "Spotify", icon: "mdi-spotify", color: "green" },
      {
        api: "youtube",
        name: "Youtube",
        icon: "mdi-youtube-subscription",
        color: "#e2143f",
      },
      {
        api: "apple music",
        name: "Apple music",
        icon: "mdi-music",
        color: "#e2143f",
      },
    ],
    target: "",
    origin: "billboard",
    lowestPosition: "",
    highestPosition: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v >= 1 || "Min 1 position",
      max: (v) => v <= 100 || "Max 100 position",
      number: (v) => /^[0-9]+$/.test(v) || "Position should be a number",
      maxItemPerPage: (v) => v <= 50 || "Max 50 items per page",
    },
    genres: "",
    label: "", // producers
    showLabelFilter: false,
    itemsPerPage: 20,

    /* titles */
    title: "",
    titleItems: [],
    isTitleLoading: false,
    autoSearchTitle: null,
    showTitleFilter: false,

    /* Artist */
    artist: [],
    artistItems: [],
    isArtistLoading: false,
    autoSearchArtist: null,
    showArtistFilter: false,
  }),
  computed: {
    ...mapGetters(["getSearchTopics", "getItemsPerPage"]),
    isValidRange() {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      if (!isEqual(end, start)) return isAfter(end, start);
      return true;
    },
    isValidPositionRange() {
      if (
        parseInt(this.lowestPosition, 10) >= 0 &&
        parseInt(this.highestPosition, 10) >= 0
      )
        return (
          parseInt(this.lowestPosition, 10) < parseInt(this.highestPosition, 10)
        );
      return true;
    },
    localMemory() {
      return localStorageHandler.getJsonLocalStore("localMemory");
    },
    positionValidation() {
      return this.lowestPosition < this.highestPosition;
    },
  },
  watch: {
    startDate() {
      this.dateFormatted = this.formatDate(this.startDate);
    },
    endDate() {
      this.computedDateFormatted = this.formatDate(this.endDate);
    },
    autoSearchTitle(value) {
      this.isTitleLoading = true;
      this.autoCompleteTitle(value)
        .then((response) => {
          this.titleItems = response.data.items;
        })
        .catch(() => {})
        .finally((this.isTitleLoading = false));
    },
    autoSearchArtist(value) {
      this.isArtistLoading = true;
      this.autoCompleteArtist(value)
        .then((response) => {
          this.artistItems = response.data.items;
        })
        .catch(() => {})
        .finally((this.isArtistLoading = false));
    },
  },
  mounted() {
    this.getSearchTopics.forEach((ele) => {
      if (ele.apiTarget === "artist") {
        this[ele.apiTarget] = ele.value.split(",");
      } else {
        this[ele.apiTarget] = ele.value;
      }
    });
    this.itemsPerPage = this.getItemsPerPage;
  },
  methods: {
    ...mapMutations(["setSearchTopics", "setItemsPerPage"]),
    ...mapActions(["autoCompleteTitle", "autoCompleteArtist"]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    close() {
      this.cleanForm();
      this.$emit("close");
    },
    createTopicObject() {
      const artist = this.artist.join(",");
      this.setSearchTopics({});
      const filters = [
        {
          id: 0,
          name: "Title",
          apiTarget: "title",
          type: "String",
          visible: true,
          value: this.title || "",
          icons: "mdi-format-title",
        },
        {
          id: 1,
          name: "Artist",
          apiTarget: "artist",
          type: "String",
          visible: true,
          value: artist || "",
          valuesArray: this.artist,
          icons: "mdi-account-music",
        },
        {
          id: 2,
          name: "Genres",
          apiTarget: "genres",
          type: "String",
          visible: true,
          value: this.genres || "",
          icons: "mdi-music",
        },
        {
          id: 3,
          name: "Producer by",
          apiTarget: "label",
          type: "String",
          visible: true,
          value: this.label || "",
          icons: "mdi-home-variant-outline",
        },
        {
          id: 4,
          name: "From Position",
          apiTarget: "lowestPosition",
          type: "Integer",
          value:
            this.lowestPosition && this.numberValidation(this.lowestPosition)
              ? parseInt(this.lowestPosition, 10)
              : 1,
          icons: "mdi-numeric-1-circle",
        },
        {
          id: 5,
          name: "To Position",
          apiTarget: "highestPosition",
          type: "Integer",
          value:
            this.highestPosition && this.numberValidation(this.highestPosition)
              ? parseInt(this.highestPosition, 10)
              : 100,
          icons: "mdi-numeric-9-circle",
        },
        {
          id: 6,
          name: "endDate",
          apiTarget: "endDate",
          type: "String",
          value: this.endDate || null,
          icons: "mdi-calendar",
        },
        {
          id: 7,
          name: "startDate",
          apiTarget: "startDate",
          type: "String",
          value: this.startDate || null,
          icons: "mdi-calendar",
        },
        {
          id: 8,
          name: "Target",
          apiTarget: "target",
          type: "String",
          value: this.target || "",
          icons: "mdi-bullseye-arrow",
        },
        {
          id: 9,
          name: "Source Origin",
          apiTarget: "origin",
          type: "String",
          value: this.origin || "",
          icons: "mdi-semantic-web",
        },
      ];
      this.setSearchTopics(
        filters.filter((e) => e.value !== "" && e.value !== null)
      );
    },
    save() {
      this.createTopicObject();
      this.$emit("fetch-hits");
      this.cleanForm();
    },
    cleanForm() {
      this.title = "";
      this.artist = [];
      this.genres = "";
      this.label = "";
      this.lowestPosition = 1;
      this.highestPosition = 100;
      this.target = "";
      /* this.origin = ""; */
    },
    numberValidation(enrol) {
      const z1 = /^[0-9]+$/;
      if (z1.test(enrol)) {
        return true;
      }
      return false;
    },
    formattedData(data, amount) {
      if (data.length > amount) {
        const text = data.substr(0, amount);
        return `${text}....`;
      }
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.alertTest {
  font-size: 15px;
}
</style>
