<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card color="deep-purple darken-4" rounded>
      <v-card-text class="py-10 textWhite">
        <div class="mb-8 center">
          <v-icon color="white" x-large>{{ icon }}</v-icon>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <h2 class="whiteText" v-html="header"></h2>
        <!-- eslint-disable vue/no-v-html -->
        <p class="whiteText mt-3" v-html="body"></p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          outlined
          rounded
          lg
          elevation="1"
          class="alertBtn"
          aria-label="Close"
          @click="close()"
          ><span>Close</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showAlert: { type: Boolean, default: false },
    header: { type: String, default: "Data is not found!" },
    body: {
      type: String,
      default:
        " Unfortunately at the moment we don't have that information. <br /> But, in the meantime you can take a look at our most popular searches.",
    },
    icon: { type: String, default: "mdi-information" },
  },
  data: () => ({
    show: false,
  }),
  watch: {
    showAlert() {
      this.show = this.showAlert;
    },
  },
  mounted() {
    this.show = this.showAlert;
  },
  methods: {
    close() {
      this.$emit("close-dialog", false);
    },
  },
};
</script>

<style>
.alertBtn {
  background: white;
  color: #311b92 !important;
  font-weight: 700 !important;
  text-transform: capitalize;
}
</style>
