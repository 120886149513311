// import {axiosConnection} from "@/helpers";

const general = {
  state: {
    layoutDisplay: true,
    audioObject: {},
    reloadPage: 0,
    actualPage: 1,
    itemsPerPage: 20,
  },
  mutations: {
    setLayoutDisplay(state, data) {
      state.layoutDisplay = data;
    },
    setItemsPerPage(state, data) {
      state.itemsPerPage = data !== 0 || data > 50 ? data : 20;
      state.actualPage = 1;
    },
    setAudioObject(state, data) {
      state.audioObject = data;
    },
    setReload(state, data) {
      state.reloadPage = data;
    },
    setActualPage(state, data) {
      state.actualPage = data;
    },

  },
  actions: {
    changeAudioObject({ commit }, data) {
      commit("setAudioObject", data);
    },
    changeActualPage({ commit }, data) {
      commit("setActualPage", data);
    },
    changeReload({ commit }) {
      const data = Math.floor(Math.random() * 10000 + 1);
      commit("setReload", data);
    },
  },
  getters: {
    getLayoutDisplay(state) {
      return state.layoutDisplay;
    },
    getAudioObject(state) {
      return state.audioObject;
    },
    getReload(state) {
      return state.reloadPage;
    },
    getActualPage(state) {
      return state.actualPage;
    },
    getItemsPerPage(state) {
      return state.itemsPerPage;
    },

  },
};
export default general;
