<template>
  <section transition="fade-transition">
    <v-row class="main mr-0 main_general">
      <v-col xl="4" lg="6" md="6" sm="12" col="12">
        <img
          :src="artistImage"
          data-google="showRelatedHits"
          class="img_2 img"
          aspect-ratio="1"
          alt="artistImage"
          lazy-src="./img/micro.webp"
        />
        <template #placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="deep-purple darken-4"
              aria-progressbar-name="search loader"
            ></v-progress-circular>
          </v-row>
        </template>

        <v-row class="playButtonContainer">
          <playButton
            :tracks="
              hit.targetHitsRelated.spotify
                ? hit.targetHitsRelated.spotify.hitInfo
                : []
            "
            :size="String(iconSize)"
          />
          <div class="ma-5">
            <span class="songArtist">{{ artistName }}</span> <br />
            <span class="songTitle">{{ songTitle }}</span>
          </div>
        </v-row>
      </v-col>
      <v-col xl="8" lg="6" md="6" sm="12" col="12">
        <emotions
          v-for="(tag, index) in systemTags"
          :id="index"
          :key="index"
          :name="tag"
          :status="false"
          :value="5"
          :song-id="hit"
          @update-emotions="updateEmotions"
        ></emotions>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import playButton from "@/components/collections/audio/playButton";
import playListBehavior from "@/mixins/playListBehavior";
import emotions from "./emotions";

export default {
  name: "SongSuggested",
  components: {
    playButton,
    emotions,
  },
  mixins: [playListBehavior],
  props: {
    hit: {
      type: Object,
      default: () => {},
    },
    album: { type: Boolean, default: false },
    others: { type: Boolean, default: false },
    albumId: { type: Number, default: 0 },
  },
  data: () => ({
    iconSize: 40,
  }),
  computed: {
    ...mapGetters([
      "getEmotionsRegisterBySongID",
      "getEmotionsRegister",
      "getTags",
    ]),

    artistImage() {
      return this.hit.artistImage[0].url || "./img/micro.webp";
    },
    songTitle() {
      return this.hit.globalInfo.title
        ? this.hit.globalInfo.title.slice(0, 50)
        : " - ";
    },
    artistName() {
      return this.hit.globalInfo.artist
        ? this.hit.globalInfo.artist.slice(0, 30)
        : " - ";
    },
    systemTags() {
      return this.getTags || [];
    },
  },
  methods: {
    ...mapActions(["updateEmotionsRegister"]),
    changeOthers() {
      this.$emit("changeOthers", this.others);
    },
    changeAlbum(id) {
      this.$emit("changeAlbum", id);
    },
    addToPlaylist(hit) {
      this.$emit("addToPlaylist", hit);
    },
    addToPlaylistInternal(hit, active, key) {
      this.$emit("addToPlaylistInternal", { hit, active, key });
    },
    isAddedToPlayList(id) {
      this.$emit("isAddedToPlayList", id);
    },
    updateEmotions(emotion) {
      const {
        songId,
        songName,
        emotionValue,
        emotionName,
        emotionId,
        emotionStatus,
      } = emotion;
      const songEmotions = {
        id: songId,
        name: songName,
        emotions: [
          {
            value: emotionValue,
            name: emotionName,
            id: emotionId,
            status: emotionStatus,
          },
        ],
      };

      const songsWithEmotions = this.getEmotionsRegisterBySongID(songId);
      if (songsWithEmotions) {
        const emotionIndex = songsWithEmotions.emotions.findIndex(
          (ele) => ele.id === emotionId
        );

        const currentEmotion = songEmotions.emotions[0];
        if (emotionStatus) {
          if (emotionIndex !== -1) {
            songsWithEmotions.emotions[emotionIndex] = currentEmotion;
          } else {
            songsWithEmotions.emotions.push(currentEmotion);
          }
        } else {
          songsWithEmotions.emotions.splice(emotionIndex, 1);
        }

        const result = this.getEmotionsRegister.map((ele) => {
          if (ele.id === songsWithEmotions.songId) {
            return songsWithEmotions;
          }
          return ele;
        });

        this.updateEmotionsRegister(result);
      } else {
        this.updateEmotionsRegister([
          ...this.getEmotionsRegister,
          ...[songEmotions],
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #311b92;
}
.text-h3 {
  color: #311b92;
  font-weight: 900;
  font-size: 3.5rem !important;
}
.bg {
  background: #f5f5f5;
}
.center {
  text-align: center;
  justify-content: center;
  justify-items: center;
}
.text_down {
  font-size: 13px;
  font-weight: 600;
  color: #311b9244;
}

.actions {
  min-height: auto;
  background: #1853b5;
  border-radius: 0 20px 0 20px;
  box-icon {
    margin: 10px;
    font-size: 15px;
    border-radius: 100px;
  }
  box-icon:hover {
    transform: scale(1.2);
    transition: 300ms;
    cursor: pointer;
  }
}
.title {
  margin: 15px;
  text-align: justify;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #212121;
}

.playButtonContainer {
  border-radius: 0 0 20px 20px;
  border-color: #eee;
  background: linear-gradient(145deg, #dddddd, #ffffff);
  box-shadow: 14px 14px 28px #a4a4a463, -14px -14px 28px #ffffff;
  padding: 20px 15px;
  margin: 1px;
  margin-top: -21px;
  display: flex;
  flex-direction: row;
  flex-wrap: inherit;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;

  .songArtist {
    font-size: 1rem;
    font-weight: 900;
  }
  .songTitle {
    font-size: 0.9rem;
  }
}
.main {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 640px) {
    padding: 5px !important;
  }
  .img {
    height: 100px;
    width: 100px;
    border-radius: 20px;
    border-color: #eee;
    background: linear-gradient(145deg, #dddddd, #ffffff);
    box-shadow: 14px 14px 28px #a4a4a463, -14px -14px 28px #ffffff;
    padding: 1px;
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      height: auto;
      width: 100%;
      max-height: 250px;
    }
    @media only screen and (max-width: 640px) {
      height: auto;
      width: 100%;
      max-height: 350px;
      max-width: 350px;
    }
  }
  .img_1 {
    height: 100px;
    width: 100px;
    @media only screen and (max-width: 640px) {
      height: auto;
      width: 100%;
    }
  }
  .img_2 {
    height: auto;
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    padding: 0;
  }
  .contentCD {
    padding: 15px 30px;
    position: relative;
    background: #d12222ba;
    width: auto;
    border-radius: 0px 0px 20px 20px;
  }
  .content {
    text-align: justify;
    margin: 5px 30px;
  }
  .actions {
    text-align: left;
    background: transparent;
    border-radius: 30px;
  }
}

.main_summary {
  .img {
    width: 150px;
    height: 150px;
    border-radius: 30px 0 0 30px;
    margin-bottom: -4px;
    border-color: #f5f5f5;
    background: linear-gradient(145deg, #dddddd, #ffffff);
    box-shadow: 14px 14px 28px #a4a4a463, -14px -14px 28px #ffffff;
    @media only screen and (max-width: 1023px) {
      height: auto;
      width: 100%;
    }
  }
}
.media {
  text-align: left;

  .audio {
    width: 280px;
    overflow: hidden;
    outline: none;
  }
}

.img {
  cursor: pointer;
}

.others {
  margin: 20px;
  border-radius: 30px;
  background: linear-gradient(180deg, #f5f5f5, #f2f2f2);
  box-shadow: 7px 7px 10px #a4a4a463, -7px -7px 10px #ffffff;
}

.upTo {
  margin-bottom: -100px;
  position: absolute;
  background: #fff;
  border-radius: 100%;
}
.buttons {
  background: #e6e6e6;
  width: auto;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  padding: 25px;
}
.text_ {
  margin-bottom: 16px;
  font-size: 17px;
  line-height: 28px;
}
.flex_centered {
  display: flex;
  justify-content: center;
  justify-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .imgRestore {
    grid-area: imgRestore;
  }
  .positionRestore {
    grid-area: positionRestore;
  }
  .textRestore {
    grid-area: textRestore;
  }
  .actions {
    grid-area: actions;
  }
  .main_general {
    display: grid;
    grid-template-columns: 20% 20% 30% 30%;
    grid-template-rows: auto;
    grid-template-areas: "imgRestore positionRestore textRestore actions";
  }
}

@media only screen and (max-width: 768px) {
  .restoreL {
    padding-left: 0px !important ;
  }
  .actions {
    padding-bottom: 30px;
  }
  .textRestore {
    display: flex;
    align-items: left;
    align-content: left;
    justify-content: left;
    justify-content: left;
    text-align: left;
    margin-left: 35px;
    padding: 0;
    width: 80%;
  }
  .positionRestore {
    display: flex;
    align-items: left;
    align-content: left;
    justify-content: left;
    justify-content: left;
    text-align: left;
    margin-bottom: -15px;
    margin-left: 35px;
    padding: 0;
  }
  .main_general {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      "imgRestore"
      "positionRestore"
      "textRestore"
      "actions";
  }
}
@media only screen and (max-width: 640px) {
  .imgRestore {
    padding-left: 20px !important;
  }
}
</style>
