<template>
  <v-data-table
    :headers="headers"
    :items="hits"
    item-key="id"
    :search="search"
    :items-per-page="itemsPerPage"
    hide-default-header
    class="grey lighten-5 radio20 pa-2"
  >
    <template #body="props">
      <tr v-for="(item, index) in props.items" :key="index">
        <td style="min-width: 35px">{{ index + 1 }}</td>
        <td>
          <div class="actions">
            <img
              :src="item.albumImages"
              class="squared_img"
              alt="albumImages"
            />
          </div>
        </td>
        <td style="min-width: 15ch">
          <span>{{ item.name }}</span
          ><br />
        </td>
        <td style="width: 70px">
          <div class="actions">
            <playButton :tracks="item" :size="'24'"></playButton>
            <v-tooltip v-if="1 > 2" top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  tile
                  class="iconsApp"
                  v-bind="attrs"
                  aria-label="Delete from playlist"
                  v-on="on"
                >
                  <v-icon color="#311b92" @click="deleteHit(item)"
                    >mdi-playlist-minus</v-icon
                  >
                </v-btn>
              </template>
              <span>Delete from Playlist</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import playButton from "@/components/collections/audio/playButton";

export default {
  components: {
    playButton,
  },
  props: {
    hits: { type: Array, required: true },
  },

  data: () => ({
    search: null,
    itemsPerPage: 5,
    headers: [
      {
        text: "image",
        value: "albumImages",
        filterable: false,
        sortable: false,
      },
      {
        text: "name",
        align: "start",
        value: "name",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        filterable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(["getPlayList"]),
  },
  methods: {
    ...mapMutations(["deleteFromPlayList"]),
    deleteHit(hit) {
      const activeHit = this.getPlayList.filter((ele) => ele.id !== hit.id);
      this.deleteFromPlayList(activeHit);
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  margin-top: 10px;
}
.actions {
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  min-height: auto;
  box-icon {
    margin: 10px;
    font-size: 15px;
    border-radius: 100px;
  }
  box-icon:hover {
    transform: scale(1.2);
    transition: 300ms;
    cursor: pointer;
  }
}
.title_content {
  text-align: left;
  font-size: 13px;
  color: #212121;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  padding-top: 25px;
  padding-left: 15px;
}

.main {
  display: flex;
  .content {
    text-align: justify;
    margin: 5px 30px;
  }
}

.main_summary {
  img {
    width: 100px;
    height: auto;
    border-radius: 30px 0 0 30px;
    margin-bottom: -4px;
  }
}
.media {
  display: none;
  text-align: left;
  .audio {
    width: 180px;
    overflow: hidden;
    outline: none;
  }
}
.squared_img,
.round_img {
  border-radius: 100%;
  padding: 20px;
  height: 48px;
  width: 48px;
  margin: 5px 10px;
  box-shadow: 1px 2px 10px rgba(32, 33, 36, 0.37);
  background: 8px 2px 11px rgb(255, 255, 255);
  border-color: rgba(223, 225, 229, 0);
  padding: 2px;
}
.squared_img {
  border-radius: 5px !important;
}

.index_pl {
  background: #eeeeee;
}
@media only screen and (max-width: 768px) {
  .mobile_padding {
    padding: 0px 15px !important;
  }
}
</style>
