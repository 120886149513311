var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "my-3 px-6",
                      class: _vm.btnTextColor,
                      attrs: {
                        color: _vm.color,
                        dark: "",
                        rounded: "",
                        "data-google": _vm.dataGoogle,
                        "aria-label": "Login",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.login()
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  !_vm.img
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { color: _vm.colorIcon, size: _vm.size },
                        },
                        [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                      )
                    : _c("img", {
                        staticClass: "mr-3",
                        attrs: {
                          src: _vm.imgUrl,
                          alt: _vm.btnText,
                          width: "20",
                          height: "auto",
                        },
                      }),
                  _vm._v(" " + _vm._s(_vm.btnText)),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" " + _vm._s(_vm.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }