import { axiosConnection,localStorageHandler } from "@/helpers";

const suggestions = {
  state: {
    apiUrl_search: "/search",
    suggestions: {},
    songsItems: {},
    newHits: [],
    emotionsRegister: [],
    api: "baseUrlRecommendations",
    apiUrl: "ai/tags",
    tags: [],
    samples: [],
    recommendationClientId:
      localStorageHandler.getLocalStore("recommendationClientId") || null,
  },
  mutations: {
    setSongsItems(state, result) {
      state.songsItems = result;
    },
    setNewHits(state, result) {
      state.newHits = result;
    },
    resetSongsItems(state) {
      state.songsItems = {};
    },
    setEmotionsRegister(state, data) {
      state.emotionsRegister = data;
    },

    setTags(state, data) {
      state.tags = data;
    },
    setSamples(state, data) {
      state.samples = data;
    },
    setRecommendationClientId(state, data) {
      state.recommendationClientId = data;
    },
  },
  actions: {
    async fetchSuggestedSongs({ state, commit }, { from, size, extraData }) {
      const init = {
        from,
        size,
        search: {
          highestPosition: 50,
          ...extraData,
        },
        sort: [
          {
            asc: "position",
          },
        ],
      };
      let result = axiosConnection.post(state.apiUrl_search, init);
      await result
        .then((resp) => {
          commit("setSongsItems", resp.data.items);
        })
        .catch(() => {})
        .finally(() => {});
      return result;
    },
    async fetchNewHits({ state, commit }, { from, size, extraData }) {
      const init = {
        from,
        size,
        search: {
          highestPosition: 50,
          ...extraData,
        },
        sort: [
          {
            asc: "position",
          },
        ],
      };
      let result = axiosConnection.post(state.apiUrl_search, init);
      await result
        .then((resp) => {
          commit("setNewHits", resp.data.items);
        })
        .catch(() => {})
        .finally(() => {});
      return result;
    },
    updateEmotionsRegister({ commit }, emotion) {
      const cleanEmotion = emotion.filter(
        ({ emotions }) => emotions.length > 0
      );
      commit("setEmotionsRegister", cleanEmotion);
    },
    resetEmotionsRegister({ commit }) {
      commit("setEmotionsRegister", []);
    },
    sendSuggestionsGenerated({ commit, state }, suggestions) {
      const emotionsList = suggestions.map(({ id, emotions }) => {
        const tags = emotions.map(({ name, value }) => {
          return { name, valuation: value };
        });
        return {
          tags,
          songId: id,
        };
      });
      if (!state.recommendationClientId) {
        return axiosConnection
          .post("songs/valuations", emotionsList, state.api)
          .then((resp) => {
            localStorageHandler.setLocalStore(
              "recommendationClientId",
              resp.data.recommendationClientId
            );
            commit(
              "setRecommendationClientId",
              resp.data.recommendationClientId
            );
          })
          .catch(() => {});
      } else {
        const id = `recommendationClientId=${state.recommendationClientId}`;
        return axiosConnection
          .put("songs/valuations", id, emotionsList, state.api)
          .catch(() => {});
      }
    },
    async fetchTags(
      { state, commit },
      recommendationClientId = state.recommendationClientId
    ) {
      let query = { apiUrl: "ai/tags", api: state.api };
      if (recommendationClientId) {
        query = {
          ...query,
          filter: `recommendationClientId=${recommendationClientId}`,
        };
      }
      const result = axiosConnection.getByFilter(...Object.values(query));
      await result
        .then((resp) => {
          commit("setTags", resp.data);
        })
        .catch(() => {})
        .finally(() => {});
      return result;
    },
    async fetchAllTags(
      { state, commit },
      recommendationClientId = state.recommendationClientId
    ) {
      let query = { apiUrl: "ai/tags", api: state.api };
      const result = axiosConnection.getByFilter(...Object.values(query));
      await result
        .then((resp) => {
          commit("setTags", resp.data);
        })
        .catch(() => {})
        .finally(() => {});
      return result;
    },
    async fetchSamples({ state, commit }, size = 10) {
      const init = "amount=" + size;
      let result = axiosConnection.getByFilter("ai/samples", state.api, init);
      await result
        .then((resp) => {
          commit("setSamples", resp.data.items);
        })
        .catch(() => {})
        .finally(() => {});
      return result;
    },
    async fetchSamplesByTag({ state, commit }, tagList) {
      let result = axiosConnection.postQuery(
        "songs/recommended?" + tagList,
        state.api
      );
      await result
        .then((resp) => {
          commit("setSongsItems", resp.data);
        })
        .catch(() => {})
        .finally(() => {});
      return result;
    },
  },
  getters: {
    getSongsItems(state) {
      return state.songsItems;
    },
    getNewHits(state) {
      return state.newHits;
    },
    getEmotionsRegister(state) {
      return state.emotionsRegister;
    },
    getEmotionsRegisterBySongID: (state) => (songId) => {
      const currentEmotion = state.emotionsRegister.find(
        (ele) => ele.id === songId
      );
      if (currentEmotion) return currentEmotion;
      else return null;
    },
    getTags(state) {
      return state.tags;
    },
    getSample(state) {
      return state.samples;
    },
    getRecommendationClientId(state) {
      return state.recommendationClientId;
    },
    getSongWithTagName(state) {
      const tagsName = Object.keys(state.songsItems);
       if (tagsName.length <= 0) {
        return [];
      }
      let result = [];
      tagsName.forEach((tag) => {
        result.push(
          ...state.songsItems[tag].map((ele) => ({ id: ele, tag: tag }))
        );
      });
      return result;
    },
  },
};
export default suggestions;

