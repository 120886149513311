var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("h2", [_vm._v("404")]),
      _c("h3", [_vm._v("Opps! Page not found.")]),
      _c("p", [
        _vm._v(" The pages you were loocking for doesn't exit. "),
        _c("br"),
        _vm._v(
          " You may have mistyped the address or the page may have moved. "
        ),
      ]),
      _c("a", [_vm._v(" back to "), _c("b", [_vm._v("Home")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }