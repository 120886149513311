var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard",
    { attrs: { content: "" } },
    [
      _c(
        "v-row",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [_c("h1", [_vm._v("Configurations")]), _c("configurations")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }