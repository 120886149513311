var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "deep-purple darken-4", rounded: "" } },
        [
          _c("v-card-text", { staticClass: "py-10 textWhite" }, [
            _c(
              "div",
              { staticClass: "mb-8 center" },
              [
                _c("v-icon", { attrs: { color: "white", "x-large": "" } }, [
                  _vm._v(_vm._s(_vm.icon)),
                ]),
              ],
              1
            ),
            _c("h2", {
              staticClass: "whiteText",
              domProps: { innerHTML: _vm._s(_vm.header) },
            }),
            _c("p", {
              staticClass: "whiteText mt-3",
              domProps: { innerHTML: _vm._s(_vm.body) },
            }),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "alertBtn",
                  attrs: {
                    text: "",
                    outlined: "",
                    rounded: "",
                    lg: "",
                    elevation: "1",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("span", [_vm._v("Close")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }