/* eslint-disable */
<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        :color="color"
        dark
        rounded
        v-bind="attrs"
        class="my-3 px-6"
        :class="btnTextColor"
        :data-google="dataGoogle"
        aria-label="Login"
        v-on="on"
        @click="login()"
      >
        <v-icon v-if="!img" :color="colorIcon" :size="size" class="mr-2">
          {{ icon }}
        </v-icon>
        <img
          v-else
          :src="imgUrl"
          :alt="btnText"
          class="mr-3"
          width="20"
          height="auto"
        />
        {{ btnText }}</v-btn
      ></template
    >
    {{ message }}
  </v-tooltip>
</template>

<script>
import { login } from "@/mixins/login";

export default {
  name: "VLogin",
  mixins: [login],
  props: {
    icons: {
      type: String,
      default: "spotify",
    },
    color: {
      type: String,
      default: "green",
    },
    colorIcon: {
      type: String,
      default: "white",
    },
    message: {
      type: String,
      default: "Spotify login",
    },
    btnText: {
      type: String,
      default: "login",
    },
    btnTextColor: {
      type: String,
      default: "black",
    },
    service: {
      type: String,
      default: "spotify",
    },
    size: {
      type: Number,
      default: 24,
    },
    img: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      type: String,
      default: "img/googleLogo.png",
    },
  },
  computed: {
    dataGoogle() {
      const capitalStr = this.icons.replace(/^\w/, (c) => c.toUpperCase());
      return `login-${capitalStr}-PlayListBtn`;
    },
    icon() {
      return `mdi-${this.icons}`;
    },
  },
  methods: {
    login() {
      if (this.service === "spotify") {
        this.getCodeSpotify();
      }
      if (this.service === "google") {
        this.getCodeYoutube();
      }
      if (this.service === "appleMusic") {
        this.getCodeApple();
      }
    },
  },
};
</script>
