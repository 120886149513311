<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="800" class="bg" persistent>
      <v-card>
        <windows
          :generate-pull="dialog"
          @close-suggested-window="closeWindow"
        ></windows
      ></v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import windows from "@/domains/suggestions/components/windowsCarousel";
import { cookiesHelper } from "@/helpers";

export default {
  name: "WindowsCarousel",
  components: { windows },
  props: {
    showSuggestionsSystem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.showSuggestionsSystem,
      expirationTime: 150,
    };
  },
  computed: {
    ...mapGetters(["getRecommendationClientId"]),
  },
  watch: {
    showSuggestionsSystem(val) {
      this.dialog = val;
    },
    getRecommendationClientId(actual, old) {
      if (actual !== old && old === null) {
        this.createCookies();
      } else {
        this.updateCookies();
      }
    },
  },
  created() {
    const showDialogCookies = cookiesHelper.getCookie("showSuggestionDialog");
    const showSuggestionDialogInStart = cookiesHelper.getCookie(
      "showSuggestionDialogInStart"
    );
    if (showSuggestionDialogInStart !== "true") {
      if (
        showDialogCookies === null &&
        this.getRecommendationClientId === null
      ) {
        this.dialog = true;
        cookiesHelper.setCookie(
          "showSuggestionDialogInStart",
          true,
          this.expirationTime
        );
      }
    }
  },
  methods: {
    ...mapMutations(["setSuggestionsSongs"]),
    closeWindow() {
      this.setSuggestionsSongs([]);
      this.dialog = false;
      this.$emit("update:showSuggestionsSystem", false);
    },
    createCookies() {
      cookiesHelper.setCookie(
        "showSuggestionDialog",
        true,
        this.expirationTime
      );
    },
    updateCookies() {
      cookiesHelper.setCookie(
        "showSuggestionDialog",
        false,
        this.expirationTime
      );
    },
  },
};
</script>
