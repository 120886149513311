import dayjs from "dayjs";

class TimeConverter {
  static milSecToMinutesAndSeconds(milSec) {
    const minutes = Math.floor(milSec / 60000);
    const seconds = ((milSec % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  static getDaysRange(days = 5) {
    const startDate = this.createDate(days, false);
    const endDate = this.createDate(0, true);
    const rangeDays = {
      startDate,
      endDate,
    };
    return rangeDays;
  }

  static createDate(days, add) {
    const date = dayjs();
    let formatDay = "";
    if (add) {
      formatDay = date.add(days, "day").format("YYYY-MM-DD");
    } else {
      formatDay = date.subtract(days, "day").format("YYYY-MM-DD");
    }
    return formatDay;
  }
}
export default TimeConverter;
