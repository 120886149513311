import axios from "axios";
import localStorageHandler from "./localStorageHandler";
import globalEnv from "./global.env";

class flashApi {
  static fetchHitsTargetData() {}

  static createPlatformObjectsNoLogin(element) {
    const localMemory = localStorageHandler.getJsonLocalStore("localMemory");
    if (!element.target.spotify) {
      localMemory.importSpotify.push(element.id);
    }
    if (!element.target.youtube) {
      localMemory.importYoutube.push(element.id);
    }
    localStorageHandler.setJsonLocalStore("localMemory", localMemory);
  }

  static afterLogin() {
    const localMemory = localStorageHandler.getJsonLocalStore("localMemory");
    const platform = localStorageHandler.getLocalStore("platform");
    if (platform === "spotify") {
      localMemory.importSpotify.forEach((element) => {
        this.get(element, "spotify");
      });
    }
    localMemory.importYoutube.forEach((element) => {
      this.get(element, "youtube");
    });
    return true;
  }

  static cleanLocalStore() {
    const localMemory = localStorageHandler.getJsonLocalStore("localMemory");
    localMemory.importYoutube = [];
    localMemory.importSpotify = [];
    localStorageHandler.setJsonLocalStore("localMemory", localMemory);
  }

  static createPlatformObjects(element) {
    const platform = localStorageHandler.getLocalStore("platform");
    if (platform === "spotify") {
      if (!element.target.spotify) {
        this.get(element.id, "spotify");
      }
    }
    if (!element.target.youtube) {
      this.get(element.id, "youtube");
    }
  }

  static async get(id, platform) {
    const url = `${globalEnv().baseUrlFlashApi}song/${platform}/${id}`;
    await axios.get(url, this.axiosConf()).catch((error) => console.log(error));
    return true;
  }

  static axiosConf() {
    const token = localStorage.getItem("access_token");
    return {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
  }
}

export default flashApi;
